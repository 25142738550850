import React, { useEffect, useRef, useState } from 'react';
import { interpolatePath } from 'd3-interpolate-path';

function ChartLoadingPlaceholder({ chart, isLoading }) {
	const dataPoints = 8;
	const width = 800;
	const xScale = width / (dataPoints - 1);

	const generateRandomLine = () => {
		let line = [];
		for (let i = 0; i < dataPoints; i++) {
			let value = Math.floor(Math.random() * 150 + 25);
			line.push(value);
		}
		return line;
	};

	const [randomData, setRandomData] = useState(generateRandomLine());

	const generatePath = (data) => {
		return data
			.map((height, index) => `${index * xScale},${300 - height}`)
			.join(' L ');
	};

	const pathRef = useRef(null);

	const horizontalLines = [
		450 / 4,
		450 / 2,
		(3 * 450) / 4,
	];

	useEffect(() => {
		const updateInterval = setInterval(() => {
			if (pathRef.current) {
				const newPath = generatePath(generateRandomLine());
				const interpolator = interpolatePath(pathRef.current.getAttribute('d'), `M${newPath}`);
				const duration = 500;

				const start = performance.now();

				const animate = (now) => {
					const progress = Math.min(1, (now - start) / duration);

					if (progress < 1) {
						if (pathRef.current) {
							pathRef.current.setAttribute('d', interpolator(progress));
						}
						requestAnimationFrame(animate);
					} else {
						if (pathRef.current) {
							pathRef.current.setAttribute('d', interpolator(1));
						}
					}
				};

				requestAnimationFrame(animate);
			}
		}, 500);

		return () => {
			clearInterval(updateInterval);
		};
	}, []);

	return (
		<div className="grow relative w-full h-[200px]">
			{isLoading && <svg className="absolute w-[calc(100%_-_(20px_*_2))] h-full inset-x-0 m-auto animate-pulse" viewBox="0 0 800 450">
				{horizontalLines.map((y, index) => (
					<path
						key={`horizontal-line-${index}`}
						className="stroke-current text-slate-200 stroke-2"
						d={`M0,${y} L800,${y}`}
						fill="none"
					/>
				))}
				<path
					className="stroke-current text-slate-200 stroke-[4px]"
					d={`M0,${450 - 1} L800,${450 - 1}`}
					fill="none"
				/>
				<path
					className="stroke-current text-slate-200 stroke-[4px]"
					d={`M20,0 L20,${450 + 25}`}
					fill="none"
				/>
				<path
					ref={pathRef}
					className="stroke-current text-slate-200 stroke-[6px]"
					d={`M${generatePath(randomData)}`}
					fill="none"
				/>
			</svg>}
			<div className="transition duration-300 ease-out" style={{ width: '100%', height: '100%', opacity: isLoading ? '0' : '1' }}>
				{chart}
			</div>
		</div>
	);
}

export default ChartLoadingPlaceholder;
