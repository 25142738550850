import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux'
import { Navigate } from "react-router-dom";
import { fetchProject } from './actions';

export default function withProject(ProjectComponent) {
  const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      currentProjectId: state.project.currentId,
      projectsById: state.project.byId,
    };
  };

  const ProjectWrapped = (props) => {
    let dispatch = useDispatch();

    const { currentProjectId, projectsById, history, auth, match } = props;

    useEffect(() => {
      if (currentProjectId && projectsById && projectsById[currentProjectId] && auth) {
        let project = projectsById[currentProjectId];

        if (!project.createdAt) {
          dispatch(fetchProject(auth.user.token, project.projectId));
        }
      }
    }, []);

    if (currentProjectId && projectsById && projectsById[currentProjectId]) {
      let project = projectsById[currentProjectId];

      return (
          <ProjectComponent
              history={history}
              auth={auth}
              match={match}
              project={project}
          />
      );
    } else {
      return <Navigate to={{ pathname: '/select_project' }} />;
    }
  };

  return connect(mapStateToProps)(ProjectWrapped);
}

