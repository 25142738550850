import resolveConfig from 'tailwindcss/resolveConfig';
import React from 'react';

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig('./src/css/tailwind.config.js')
}

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) => Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);

export const formatThousands = (value) => Intl.NumberFormat('en-US', {
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);

/**
 * Gets user data and returns fancy formatted user badges, can return single badge or multiple
 * @param  {Object.<{user: Object, project: (Object||undefined)}>} data data must have user object, project is optional
 * @param  {string} returnType type of data to be returned. `badge`, `badges`, `status` or `statuses`
 * @return {Array.<React.ReactElement>} returns array of React elements with key attribute
 **/
export const getUserStatus = (data, returnType) => {
  const { user, project } = data;

  const badge = (bgColor, textColor, label) => (
      <div className={`text-xs inline-flex font-medium ${bgColor} ${textColor} rounded-full text-center px-2.5 py-1`}>{label}</div>
  );

  if (!user)
    return null;

  const statuses = [
    {
      condition: project && project.creatorUserId === user.userId,
      label: 'Project owner',
      bgColor: 'bg-indigo-100',
      textColor: 'text-indigo-600',
    },
    {
      condition: user.paymentsCount === 0 && user.subscriptionsCount === 0,
      label: 'Lead',
      bgColor: 'bg-blue-100',
      textColor: 'text-blue-600',
    },
    {
      condition: user.activePaymentsCount > 0 || user.activeSubscriptionsCount > 0,
      label: 'Customer',
      bgColor: 'bg-emerald-100',
      textColor: 'text-emerald-600',
    },
    {
      condition: user.activePaymentsCount === 0 && user.activeSubscriptionsCount === 0 && (user.paymentsCount + user.subscriptionsCount > 0),
      label: 'Churned',
      bgColor: 'bg-slate-700',
      textColor: 'text-slate-100',
    },
    {
      condition: user.affiliateId,
      label: 'Affiliate partner',
      bgColor: 'bg-sky-100',
      textColor: 'text-sky-600',
    },
    {
      condition: user.isTeamMember,
      label: 'Team member',
      bgColor: 'bg-indigo-100',
      textColor: 'text-indigo-600',
    },
    {
      condition: user.isBanned,
      label: 'Banned',
      bgColor: 'bg-rose-100',
      textColor: 'text-rose-600',
    },
    {
      condition: user.isWhitelisted,
      label: 'Whitelisted',
      bgColor: 'bg-slate-100',
      textColor: 'text-slate-500',
    },
  ];

  if (returnType === 'statuses') {
    for (const status of statuses) {
      if (status.condition) {
        return status.label;
      }
    }
    return null;
  }

  const result = statuses.reduce((acc, { condition, bgColor, textColor, label }, index) => {
    if (condition) {
      const newBadge = badge(bgColor, textColor, label);
      acc.push(React.cloneElement(newBadge, { key: `user-badge-${index}` }));
    }
    return acc;
  }, []);
  if (returnType === 'badge') return result[0];

  return result;
};


/**
 * throttles given function once every `wait` milliseconds
 * @param {Function} func function to debounce
 * @param {number} wait number of milliseconds to wait
 * @returns {Function} debounced function.
 */
export const debounce = (func, wait) => {
  let timeout;

  return function (...args) {
    const context = this;

    const later = () => {
      timeout = null;
      func.apply(context, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const sanitizeSeed = (str) => {
  return encodeURI(str.replace(/[^a-z0-9]/gi,''));
}
