import React, { Component } from 'react';

class BurgerIcon extends Component {
  render() {
    return (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
             className="icon-menu px-2 py-2 w-12 h-12 fill-current">
          <path className="secondary" fillRule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
        </svg>
      </div>
    );
  }
}

export default BurgerIcon;