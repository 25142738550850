import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import Transition from '../utils/Transition';
import { fetchAPI } from '../api';
import { getUserStatus, debounce } from '../utils/Utils';
import { Loader } from '../Loader';

function ModalSearch({
  id,
  searchId,
  modalOpen,
  setModalOpen,
  placeholder = "Search Anything…",
}) {
  const modalContent = useRef(null);
  const searchInput = useRef(null);

  const { auth, project } = useOutletContext();
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState('');

  const fetchData = useCallback(async controller => {
    try {
      setLoading(true);

      const response = await fetchAPI("/project/users", auth.user.token, {
        projectId: project.projectId,
        filters: [
          {
            "key": "firstName",
            "condition": { "contains": searchInputValue },
          },
          {
            "key": "lastName",
            "condition": { "contains": searchInputValue },
          },
          {
            "key": "email",
            "condition": { "contains": searchInputValue },
          },
          {
            "key": "telegramUserId",
            "condition": { "contains": searchInputValue },
          },
        ],
        filtersMode: 'any',
      }, { signal: controller.signal });

      if (!response.ok) {
        throw new Error(`This is an HTTP error: The status is ${response.status}`);
      }
      let actualData = await response.json();
      setUsers(actualData.rows);
      setError(null);
    } catch (err) {
      if (err.name !== "AbortError") {
        setError(err.message);
      }
    } finally {
      if (!controller.signal.aborted) {
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    const controller = new AbortController();

    if (searchInputValue.length > 0) {
      fetchData(controller);
    } else {
      setUsers([]);
    }

    return () => {
      controller.abort();
    };
  }, [searchInputValue]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modalOpen || modalContent.current.contains(target)) return
      setModalOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    modalOpen && searchInput.current.focus();
  }, [modalOpen]);

  const searchItems = debounce((searchValue) => {
    setSearchInputValue(searchValue);
  }, 800);

  const memoizedGetUserStatus = useMemo(() => getUserStatus, []);

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity mg-0"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id={id}
        className="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div ref={modalContent} className="bg-white max-w-2xl w-full max-h-full rounded shadow-lg">
          {/* Search form */}
          <form className="border-b border-slate-200">
            <div className="relative">
              <label htmlFor={searchId} className="sr-only">Search</label>
              <input id={searchId} className="w-full border-0 focus:ring-transparent placeholder-slate-400 appearance-none py-3 pl-10 pr-4" type="search" placeholder={placeholder} ref={searchInput} onChange={((e) => searchItems(e.target.value))} />
              <button className="absolute inset-0 right-auto group" type="submit" aria-label="Search">
                <svg className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-4 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                  <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                </svg>
              </button>
            </div>
          </form>
          <Transition
              className="overflow-hidden"
              role="dialog"
              aria-modal="true"
              show={users.length > 0}
              enter="transition ease-in-out duration-200"
              enterStart="opacity-0 translate-y-4"
              enterEnd="opacity-100 translate-y-0"
              leave="transition ease-in-out duration-200"
              leaveStart="opacity-100"
              leaveEnd="opacity-0"
          >
            <div className={`${users.length > 0 ? 'py-4 px-2' : ''} overflow-auto max-h-96`}>
              <div className="mb-3 last:mb-0">
                <ul className="text-sm">
                  {users.map((user) => (
                    <li key={`search-item-${user.userId}`}>
                      <Link
                          className="flex items-center justify-between p-2 text-slate-800 hover:text-white hover:bg-indigo-500 rounded group"
                          to={`/user/${user.userId}`}
                          onClick={() => setModalOpen(!modalOpen)}
                      >
                        <div className="font-medium">{user.fullName}</div>
                        {memoizedGetUserStatus({ user }, 'badge')}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Transition>
          {!isLoading && !error && users.length === 0 && searchInputValue.length > 0 && (
            <div className="text-slate-500 text-center sm:text-left px-4 py-3">No records</div>
          )}
          {!isLoading && error && (
            <div className="text-slate-500 text-center sm:text-left">{error}</div>
          )}
          {isLoading && (
            <div className="text-blue-600 w-[50px] h-[50px] my-3 mx-auto">
              <Loader />
            </div>
          )}
        </div>
      </Transition>
    </>
  );
}

export default ModalSearch;
