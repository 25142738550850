import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {fetchProject, setCurrentProjectId} from "../actions";
import {useNavigate, useSearchParams} from 'react-router-dom';

// Simple redirection page
// Accepts ?projectId query param
// Loads project if necessary and redirects to "/"

export default function GoToProject() {
  const [searchParams, updateSearchParams] = useSearchParams();
  let projectId = searchParams.get("projectId");
  if (!projectId) throw new Error("projectId not set");

  let navigate = useNavigate();
  let dispatch = useDispatch();
  let auth = useSelector(state => state.auth);
  let byId = useSelector(state => state.project.byId);
  let project = useSelector(state => state.project.byId ? state.project.byId[projectId] : undefined);

  useEffect(() => {
    if (byId[projectId]) {
      dispatch(setCurrentProjectId(projectId, null));
      navigate("/");
    } else {
      dispatch(fetchProject(auth.user.token, projectId));
    }
  }, [project]);

  // useEffect(() => {
  //   dispatch(fetchMyProjects(auth.user.token));
  //   // Safe to add dispatch to the dependencies array
  // }, [dispatch]);
  //
  // useEffect(() => {
  //   let projectId = searchParams.get("projectId");
  //   if (myIds?.list && myIds?.list.indexOf(projectId) > -1) {
  //     dispatch(setCurrentProjectId(projectId, null));
  //     navigate("/");
  //   }
  // }, [myIds])
  //

  // Logic:
  // 1. If project with such id is loaded, setCurrentProjectId and redirect.
  // 2. If project isn't loaded yet, fetch myProjects and wait

  return null;
}

