import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../utils/Transition';
import { sanitizeSeed } from '../utils/Utils';

function DropdownProfile({
  align,
  user,
  project
}) {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative inline-flex max-w-[190px] xxs:max-w-none">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group w-full focus:outline-0"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <img className="w-8 sm:w-10 h-8 sm:h-10 rounded-full" src={`https://api.dicebear.com/7.x/initials/svg?scale=70&seed=${sanitizeSeed(project.name)}`} width="32" height="32" alt="User" />
        <div className="flex items-center truncate">
          <span className="md:max-w-[250px] truncate ml-2 sm:ml-4 text-sm sm:text-base font-medium group-hover:text-slate-800">{project.name}</span>
          <svg className={`${dropdownOpen ? 'rotate-180' : ''} ml-1`} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L6 6L11 1" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
          </svg>
        </div>
      </button>

      <Transition
        className={`origin-top-right -z-10 absolute min-w-44 bg-white shadow-[0px_8px_36px_rgba(11,38,63,0.08)] -top-4 sm:-top-6 pt-[63px] sm:pt-20 -left-[13px] sm:-left-[1.1rem] -right-3 sm:-right-4`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
          className="pb-3 sm:pb-4 px-3 sm:px-4"
        >
          <div className="pt-3 border-t border-t-brand-green/[.1]">
            <div className="mb-4 sm:mb-5">
              <div className="flex flex-row">
                <img className="w-8 sm:w-10 h-8 sm:h-10 rounded-full mr-2 sm:mr-4" src={user?.photoUrl} width="32" height="32" alt="User" />
                <div className="">
                  <div className="font-medium text-sm sm:text-base mb-1">{user.firstName} {user?.lastName}</div>
                  <div className="inline-block text-xs sm:text-sm md:text-base font-medium px-2 py-[3px] bg-light-blue rounded-full">{ project.creatorUserId === user.userId ? "Project owner" : "Team member" }</div>
                </div>
              </div>
            </div>
            <ul>
              <li className="mb-2">
                <Link
                    className="py-1 block text-sm sm:text-xl font-semibold hover:text-brand-green/[.5] transition"
                    to="/select_project"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  Switch project
                </Link>
              </li>
              <li className="mb-2">
                <Link
                    className="py-1 block text-sm sm:text-xl font-semibold hover:text-brand-green/[.5] transition"
                    to="/logout"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  Sign Out
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default DropdownProfile;
