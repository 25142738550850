import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition';

function DropdownSimple({ className, options, defaultValue, onChangeValue, dateFormatInfo }) {

  // const options = [
  //   {
  //     id: 0,
  //     period: 'Today'
  //   },
  //   {
  //     id: 1,
  //     period: 'Last 7 Days'
  //   },
  //   {
  //     id: 2,
  //     period: 'Last Month'
  //   },
  //   {
  //     id: 3,
  //     period: 'Last 12 Months'
  //   },
  //   {
  //     id: 4,
  //     period: 'All Time'
  //   }
  // ];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(defaultValue);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    onChangeValue(selected);
  }, [selected]);

  return (
    <div className={`${className} relative inline-flex`}>
      <button
        ref={trigger}
        className="btn justify-between md:min-w-44 bg-white border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600"
        aria-label="Select date range"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="flex items-center">
          <span>{options[selected].title}</span>
        </span>
        <svg className="shrink-0 ml-1 fill-current text-slate-400" width="11" height="7" viewBox="0 0 11 7">
          <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className="z-10 absolute top-full left-0 w-full bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 min-w-[380px]"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          className="font-medium text-sm text-slate-600"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {
            options.map(option => {
              return (
                <button
                  key={option.id}
                  tabIndex="0"
                  className={`flex items-center w-full hover:bg-slate-50 py-1 px-3 cursor-pointer ${option.id === selected && 'text-indigo-500'}`}
                  onClick={() => { setSelected(option.id); setDropdownOpen(false); }}
                >
                  <svg className={`shrink-0 mr-2 fill-current text-indigo-500 ${option.id !== selected && 'invisible'}`} width="12" height="9" viewBox="0 0 12 9">
                    <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                  </svg>
                  <span className="flex justify-between w-full text-left">
                    <span className={`${option.date ? 'flex items-center w-[38%]' : ''}`}>{option.title}</span>
                    {option.date && <div className="flex py-1 px-3 w-[62%] text-slate-500">{dateFormatInfo(option.date)}</div>}
                  </span>
                </button>
              )
            })
          }
        </div>
      </Transition>
    </div>
  );
}

export default DropdownSimple;
