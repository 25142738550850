import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import DropdownComparedTo from './DropdownComparedTo';
import Datepicker from './Datepicker';
import Transition from '../utils/Transition';
import moment from 'moment';

const DropdownScrollGroup = React.memo(forwardRef(({ periodOptions, comparedToOptions, unitOptions, onPeriodChange, onComparedToChange, onUnitChange, onFilterChange, period, comparedToVisible, comparedToDatepickerVisible, comparedCustom, setComparedCustom }, _ref) => {
  const paymentOptions = [
    { key: "stripeLive", title: <span>Stripe</span> },
    { key: "PayPalLive", title: <span>PayPal</span> },
    { key: "skrillLive", title: <span>Skrill</span> },
    { key: "paystackLive", title: <span>Paystack</span> },
    { key: "razorpayLive", title: <span>Razorpay</span> },
    { key: "free trial", title: <span>Free trial (no-CC)</span> },
    { key: "coinpayments", title: <span>CoinPayments</span> },
    { key: "access code (single-use)", title: <span>Access Code (single-use)</span> },
    { key: "access code (multi-use)", title: <span>Access Code (multi-use)</span> },
    { key: "stripeTest", title: <span>Stripe <span className="text-xs font-medium bg-amber-100 text-amber-600 rounded-full text-center px-2.5 py-1">Test</span></span> },
    { key: "PayPalTest", title: <span>PayPal <span className="text-xs font-medium bg-amber-100 text-amber-600 rounded-full text-center px-2.5 py-1">Test</span></span> },
    { key: "skrillTest", title: <span>Skrill <span className="text-xs font-medium bg-amber-100 text-amber-600 rounded-full text-center px-2.5 py-1">Test</span></span> },
    { key: "paystackTest", title: <span>Paystack <span className="text-xs font-medium bg-amber-100 text-amber-600 rounded-full text-center px-2.5 py-1">Test</span></span> },
    { key: "razorpayTest", title: <span>Razorpay <span className="text-xs font-medium bg-amber-100 text-amber-600 rounded-full text-center px-2.5 py-1">Test</span></span> },
  ]

  const [paymentOptionFilters, setPaymentOptionFilters] = useState(Object.fromEntries(paymentOptions.map(p => [p.key, true])));

  const containerRef = useRef(null);

  // simple dropdown
  const [dropdownSimpleOpen, setDropdownSimpleOpen] = useState(false);
  const [selectedSimple, setSelectedSimple] = useState(1);

  const triggerSimple = useRef(null);
  const dropdownSimple = useRef(null);

  // compared dropdown
  const [dropdownComparedOpen, setDropdownComparedOpen] = useState(false);
  const [selectedCompared, setSelectedCompared] = useState(0);

  const triggerCompared = useRef(null);
  const dropdownCompared = useRef(null);

  // unit dropdown
  const [dropdownUnitOpen, setDropdownUnitOpen] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(0);

  const triggerUnit = useRef(null);
  const dropdownUnit = useRef(null);

  // filter dropdown
  const [dropdownFilterOpen, setDropdownFilterOpen] = useState(false);

  const triggerFilter = useRef(null);
  const dropdownFilter = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandlerSimple = ({ target }) => {
      if (!dropdownSimple.current) return;
      if (!dropdownSimpleOpen || dropdownSimple.current.contains(target) || triggerSimple.current.contains(target)) return;
      setDropdownSimpleOpen(false)
    };
    const clickHandlerCompared = ({ target }) => {
      if (!dropdownCompared.current) return;
      if (!dropdownComparedOpen || dropdownCompared.current.contains(target) || triggerCompared.current.contains(target)) return;
      setDropdownComparedOpen(false);
    };
    const clickHandlerUnit = ({ target }) => {
      if (!dropdownUnit.current) return;
      if (!dropdownUnitOpen || dropdownUnit.current.contains(target) || triggerUnit.current.contains(target)) return;
      setDropdownUnitOpen(false);
    };
    const clickHandlerFilter = ({ target }) => {
      if (!dropdownFilter.current) return;
      if (!dropdownFilterOpen || dropdownFilter.current.contains(target) || triggerFilter.current.contains(target)) return;
      setDropdownFilterOpen(false);
    };
    document.addEventListener('click', clickHandlerSimple);
    document.addEventListener('click', clickHandlerCompared);
    document.addEventListener('click', clickHandlerUnit);
    document.addEventListener('click', clickHandlerFilter);
    return () => {
      document.removeEventListener('click', clickHandlerSimple);
      document.removeEventListener('click', clickHandlerCompared);
      document.removeEventListener('click', clickHandlerUnit);
      document.removeEventListener('click', clickHandlerFilter);
    };
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownSimpleOpen || keyCode !== 27) return;
      setDropdownSimpleOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    onPeriodChange(periodOptions[selectedSimple]);
  }, [selectedSimple]);

  useEffect(() => {
    onComparedToChange(comparedToOptions[selectedCompared])
  }, [selectedCompared]);

  useEffect(() => {
    onUnitChange(unitOptions[selectedUnit]);
  }, [selectedUnit]);

  useEffect(() => {
    const visible = unitOptions.filter(option => option.visible);

    if (visible.length) {
      setSelectedUnit(visible[0].id);
    }
  }, [unitOptions]);

  // useEffect(() => {
  //   setSelectedUnit(0);
  // }, [period]);

  useImperativeHandle(_ref, () => ({
    getSelectedPeriod: () => {
      return periodOptions[selectedSimple];
    },
    getSelectedCompared: () => {
      return comparedToOptions[selectedCompared];
    },
    getSelectedUnit: () => {
      return unitOptions[selectedUnit];
    },
  }));

  const closeDropdowns = () => {
    setDropdownSimpleOpen(false);
    setDropdownComparedOpen(false);
    setDropdownUnitOpen(false);
    setDropdownFilterOpen(false);
  }

  const getDropdownPosition = (ref, align) => {
    const isMobile = window.innerWidth < 768;
    let left;
    let right;
    let top;
    let width = 200;

    if (containerRef.current && ref.current) {
      top = Math.abs(containerRef.current.getBoundingClientRect().y - ref.current.getBoundingClientRect().y + ref.current.clientHeight);
      width = ref.current.offsetWidth;

      if (align && align === 'right') {
        right = Math.abs(containerRef.current.getBoundingClientRect().right - ref.current.getBoundingClientRect().right)
      } else {
        left = Math.abs(containerRef.current.getBoundingClientRect().left - ref.current.getBoundingClientRect().left);
      }

      if (isMobile) {
        return {
          top,
        }
      }

      return {
        left,
        right,
        top,
        width,
      }
    }
    return {};
  }

  const applyFilter = () => {
    setDropdownFilterOpen(false);
    onFilterChange(JSON.stringify(paymentOptionFilters));
  }

  const resetFilter = () => {
    const defaultFilters = Object.fromEntries(paymentOptions.map(p => [p.key, true]));
    setPaymentOptionFilters(defaultFilters);
    setDropdownFilterOpen(false);
    onFilterChange(JSON.stringify(defaultFilters));
  }

  return (
      <div className="relative w-full">
        <div ref={containerRef} className={"flex flex-row items-start whitespace-nowrap overflow-x-auto pb-3 mb-5"}>
        {/*<div ref={containerRef} className={"flex flex-row items-start whitespace-nowrap"}>*/}
          <div className="relative inline-flex">
            <button
                ref={triggerSimple}
                className="btn justify-between md:min-w-44 bg-white border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600"
                aria-label="Select date range"
                aria-haspopup="true"
                onClick={() => { closeDropdowns(); setDropdownSimpleOpen(!dropdownSimpleOpen) }}
                aria-expanded={dropdownSimpleOpen}
            >
              <span className="flex items-center">
                <span>{periodOptions[selectedSimple].title}</span>
              </span>
              <svg className="shrink-0 ml-1 fill-current text-slate-400" width="11" height="7" viewBox="0 0 11 7">
                <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
              </svg>
            </button>
          </div>
          {/*<Datepicker defaultDate={[new Date(moment().subtract(4, 'weeks').format('YYYY-MM-DD')), new Date()]} />*/}
          {comparedToVisible && periodOptions[selectedSimple].id !== 8 && (
            <>
              <div className="px-3 text-sm min-w-fit max-w-[20px] self-center">compared to</div>
              <div className="relative inline-flex">
                <button
                    ref={triggerCompared}
                    className="btn justify-between md:min-w-44 bg-white border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600"
                    aria-label="Select date range"
                    aria-haspopup="true"
                    onClick={() => { closeDropdowns(); setDropdownComparedOpen(!dropdownComparedOpen) }}
                    aria-expanded={dropdownComparedOpen}
                >
                <span className="flex items-center">
                  <span>{comparedToOptions[selectedCompared].title}</span>
                </span>
                  <svg className="shrink-0 ml-1 fill-current text-slate-400" width="11" height="7" viewBox="0 0 11 7">
                    <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
                  </svg>
                </button>
              </div>
            </>
          )}
          {/*{comparedToDatepickerVisible && <Datepicker value={[ comparedCustom.start, comparedCustom.end ]} maxDate={period.end} onChange={(selectedDates, dateStr, instance) => {*/}
          {/*  // console.log(selectedDates, dateStr, instance.config)*/}
          {/*  // const daysDiff = moment(period.end).diff(period.start, 'days');*/}
          {/*  // console.log('daysDiff', daysDiff);*/}

          {/*  if (selectedDates.length === 1) {*/}
          {/*    // let daysLater = new Date(selectedDates[0].getTime() + 86400000 * daysDiff)*/}
          {/*    // console.log('daysLater', daysLater);*/}
          {/*    // console.log('moment(selectedDates[0])', moment(selectedDates[0]).format('DD MMM YYYY'));*/}
          {/*    // console.log('moment(daysLater)', moment(daysLater).format('DD MMM YYYY'));*/}
          {/*    // console.log('period.end', moment(period.end).format('DD MMM YYYY'));*/}
          {/*    // console.log('days summ', moment(selectedDates[0]).add(daysDiff, 'days').format('DD MMM YYYY'));*/}
          {/*    // console.log('days diff', moment(selectedDates[0]).add(daysDiff, 'days').diff(period.end));*/}

          {/*    // console.log('instance', instance.config);*/}
          {/*    // instance.set('disable', [() => true]);*/}

          {/*    // instance.set('enable', [*/}
          {/*    //     moment(selectedDates[0]).add(7, 'days').valueOf(),*/}
          {/*    // ]);*/}
          {/*    // console.log('12312312312', moment(selectedDates[0]).add(7, 'days').format('DD MMM YYYY'));*/}
          {/*    // instance.set('enable', [*/}
          {/*    //   moment(selectedDates[0]).subtract(7, 'days').valueOf(),*/}
          {/*    //   moment(selectedDates[0]).add(7, 'days').valueOf(),*/}
          {/*    // ]);*/}
          {/*    // instance.config.enable = [*/}
          {/*    //   moment(selectedDates[0]).add(7, 'days'),*/}
          {/*    // ];*/}

          {/*    // if (moment(selectedDates[0]).add(daysDiff, 'days').diff(period.end) < 0) {*/}
          {/*    //   instance.config.minDate = moment(selectedDates[0]).valueOf()*/}
          {/*    //   instance.config.maxDate = moment(daysLater).valueOf();*/}
          {/*    // } else {*/}
          {/*    //   // set period.end as last day - daysDiff*/}
          {/*    // }*/}
          {/*  } else if (selectedDates.length === 2) {*/}
          {/*    // instance.config.minDate = null;*/}
          {/*    // instance.config.maxDate = period.end;*/}
          {/*  }*/}
          {/*  // if (selectedDates[0] && selectedDates[1]) {*/}
          {/*  //   setComparedCustom({ start: moment(selectedDates[0]).valueOf(), end: moment(selectedDates[1]).valueOf() });*/}
          {/*  // }*/}
          {/*}} />}*/}

          <div className="mr-1 ml-1 relative inline-flex">
            <button
                ref={triggerUnit}
                className="btn justify-between bg-white border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600"
                aria-label="Select date range"
                aria-haspopup="true"
                onClick={() => { closeDropdowns(); setDropdownUnitOpen(!dropdownUnitOpen) }}
                aria-expanded={dropdownUnitOpen}
            >
                <span className="flex items-center">
                  <span>{unitOptions[selectedUnit].title}</span>
                </span>
              <svg className="shrink-0 ml-1 fill-current text-slate-400" width="11" height="7" viewBox="0 0 11 7">
                <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
              </svg>
            </button>
          </div>
          <div className="flex-1"></div>
          <div className="relative inline-flex">
            <button
                ref={triggerFilter}
                className="btn bg-white border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600 relative ml-1"
                aria-haspopup="true"
                onClick={() => { closeDropdowns(); setDropdownFilterOpen(!dropdownFilterOpen) }}
                aria-expanded={dropdownFilterOpen}
            >
              <span className="sr-only">Filter</span><wbr />
              <svg className="w-4 h-4 fill-current" viewBox="0 0 16 16">
                <path d="M9 15H7a1 1 0 010-2h2a1 1 0 010 2zM11 11H5a1 1 0 010-2h6a1 1 0 010 2zM13 7H3a1 1 0 010-2h10a1 1 0 010 2zM15 3H1a1 1 0 010-2h14a1 1 0 010 2z" />
              </svg>
              {Object.entries(paymentOptionFilters).filter((payment) => !payment[1]).length > 0 && <div className="absolute top-1.5 right-2 w-2 h-2 bg-rose-500 border-[1px] border-white rounded-full"></div>}
            </button>
          </div>
        </div>
        {/* Simple Dropdown Content */}
        <Transition
            show={dropdownSimpleOpen}
            tag="div"
            className="z-10 absolute top-full left-0 w-full bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 max-w-[200px]"
            enter="transition ease-out duration-100 transform"
            enterStart="opacity-0 -translate-y-2"
            enterEnd="opacity-100 translate-y-0"
            leave="transition ease-out duration-100"
            leaveStart="opacity-100"
            leaveEnd="opacity-0"
            style={{ ...getDropdownPosition(triggerSimple) }}
        >
          <div
              ref={dropdownSimple}
              className="font-medium text-sm text-slate-600"
              onFocus={() => setDropdownSimpleOpen(true)}
              onBlur={() => setDropdownSimpleOpen(false)}
          >
            {periodOptions.map(option => {
                return (
                    <button
                        key={option.id}
                        tabIndex="0"
                        className={`flex items-center w-full hover:bg-slate-50 py-1 px-3 cursor-pointer ${option.id === selectedSimple ? 'text-indigo-500' : ''}`}
                        onClick={() => { setSelectedSimple(option.id); setDropdownSimpleOpen(false); }}
                    >
                      <svg className={`shrink-0 mr-2 fill-current text-indigo-500 ${option.id !== selectedSimple ? 'invisible' : ''}`} width="12" height="9" viewBox="0 0 12 9">
                        <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                      </svg>
                      <span>{option.title}</span>
                    </button>
                )
              })}
          </div>
        </Transition>

        {/* Compared Dropdown Content */}
        <Transition
            show={dropdownComparedOpen}
            tag="div"
            className="z-10 absolute top-full left-0 w-full bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 max-w-[250px] xs:min-w-[390px] xs:max-w-[390px]"
            enter="transition ease-out duration-100 transform"
            enterStart="opacity-0 -translate-y-2"
            enterEnd="opacity-100 translate-y-0"
            leave="transition ease-out duration-100"
            leaveStart="opacity-100"
            leaveEnd="opacity-0"
            style={{ ...getDropdownPosition(triggerCompared) }}
        >
          <div
              ref={dropdownCompared}
              className="font-medium text-sm text-slate-600"
              onFocus={() => setDropdownComparedOpen(true)}
              onBlur={() => setDropdownComparedOpen(false)}
          >
            {comparedToOptions.map(option => {
                return (
                    <button
                        key={option.id}
                        tabIndex="0"
                        className={`flex items-center w-full xs:min-h-[38px] hover:bg-slate-50 py-1 px-3 cursor-pointer ${option.id === selectedCompared ? 'text-indigo-500' : ''}`}
                        onClick={() => { setSelectedCompared(option.id); setDropdownComparedOpen(false); }}
                    >
                      <svg className={`shrink-0 mr-2 mt-[7px] xs:mt-0 self-start xs:self-center fill-current text-indigo-500 ${option.id !== selectedCompared ? 'invisible' : ''}`} width="12" height="9" viewBox="0 0 12 9">
                        <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                      </svg>
                      <span className="flex justify-between w-full flex-col xs:flex-row text-left">
                      <span className={`${option.date ? 'flex items-center w-full xs:w-[38%]' : ''}`}>{option.title}</span>
                        {option.date && option.date.start && option.date.end && option.title !== 'No comparison' && <div className="flex py-1 xs:px-3 w-full xs:w-[62%] text-slate-500">
                          {option.title !== 'Custom' && `${moment(option.date.start).format(moment().year() === moment(option.date.start).year() ? 'DD MMM' : 'DD MMM YYYY')} – ${moment(option.date.end).format(moment().year() === moment(option.date.end).year() ? 'DD MMM' : 'DD MMM YYYY')}`}
                        </div>}
                      </span>
                    </button>
                )
              })}
          </div>
        </Transition>

        {/* Unit Dropdown Content */}
        <Transition
            show={dropdownUnitOpen}
            tag="div"
            className="z-10 absolute top-full left-0 w-full bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 min-w-[120px] max-w-[150px]"
            enter="transition ease-out duration-100 transform"
            enterStart="opacity-0 -translate-y-2"
            enterEnd="opacity-100 translate-y-0"
            leave="transition ease-out duration-100"
            leaveStart="opacity-100"
            leaveEnd="opacity-0"
            style={{ ...getDropdownPosition(triggerUnit) }}
        >
          <div
              ref={dropdownUnit}
              className="font-medium text-sm text-slate-600"
              onFocus={() => setDropdownUnitOpen(true)}
              onBlur={() => setDropdownUnitOpen(false)}
          >
            {unitOptions.map(option => {
              if (option.visible) {
                return (
                    <button
                        key={option.id}
                        tabIndex="0"
                        className={`flex items-center w-full hover:bg-slate-50 py-1 px-3 cursor-pointer ${option.id === selectedUnit ? 'text-indigo-500' : ''}`}
                        onClick={() => {
                          setSelectedUnit(option.id);
                          setDropdownUnitOpen(false);
                        }}
                    >
                      <svg className={`shrink-0 mr-2 fill-current text-indigo-500 ${option.id !== selectedUnit ? 'invisible' : ''}`}
                           width="12"
                           height="9"
                           viewBox="0 0 12 9">
                        <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                      </svg>
                      <span>{option.title}</span>
                    </button>
                )
              }
              return null;
            })}
          </div>
        </Transition>

        {/* Filter Dropdown Content */}
        <Transition
            show={dropdownFilterOpen}
            tag="div"
            className={`origin-top-right z-10 absolute top-full min-w-56 bg-white border border-slate-200 pt-1.5 rounded shadow-lg overflow-hidden mt-1 right-0`}
            enter="transition ease-out duration-200 transform"
            enterStart="opacity-0 -translate-y-2"
            enterEnd="opacity-100 translate-y-0"
            leave="transition ease-out duration-200"
            leaveStart="opacity-100"
            leaveEnd="opacity-0"
            style={{ ...getDropdownPosition(triggerFilter, 'right') }}
        >
          <div ref={dropdownFilter}>
            <div className="text-xs font-semibold uppercase pt-1.5 pb-2 px-4">Payment options</div>
            <ul className="mb-4">
              {paymentOptions.map((po, index) => {
                return (
                    <li key={`dropdown-payment-option-${index}`} className="py-1 px-3">
                      <label className="flex items-center cursor-pointer select-none">
                        <input type="checkbox" className="form-checkbox" name={po.input} checked={paymentOptionFilters[po.key]} onChange={() => setPaymentOptionFilters(state => {return {...state, [po.key]: !state[po.key]}})} />
                        <span className="text-sm font-medium ml-2">{po.title}</span>
                      </label>
                    </li>
                );
              }
              )}
            </ul>
            <div className="py-2 px-3 border-t border-slate-200 bg-slate-50">
              <ul className="flex items-center justify-between">
                <li>
                  <button className="btn-xs bg-white border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600" onClick={resetFilter} onBlur={() => setDropdownFilterOpen(false)}>Reset</button>
                </li>
                <li>
                  <button className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white" onClick={applyFilter} onBlur={() => setDropdownFilterOpen(false)}>Apply</button>
                </li>
              </ul>
            </div>
          </div>
        </Transition>
      </div>
  );
}));

export default DropdownScrollGroup;
