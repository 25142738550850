import { combineReducers } from 'redux'
import { USER_LOGOUT } from '../actions/index';
import { auth } from './auth';
import { billing } from './billing'
import { resource } from './resource'
import { plan } from './plan'
import { project } from './project'

const appReducer = combineReducers({
  auth,
  billing,
  resource,
  project,
  plan
});

const rootReducer = (state, action) => {

  if (action.type === USER_LOGOUT) {
    localStorage.removeItem('reduxPersist:offline');
    localStorage.removeItem('reduxPersist:auth');
    localStorage.removeItem('reduxPersist:billing');
    localStorage.removeItem('reduxPersist:resource');
    localStorage.removeItem('reduxPersist:plan');
    localStorage.removeItem('reduxPersist:project');
    state = undefined
  }

  return appReducer(state, action)
};

export default rootReducer


/*

Store structure

{
  project: {
    myIds: {
      isFetching: false,
      isFailed: false,
      list: ["id", ...]
    },
    byId: {
      "id": {
        isFetching: false,
        isFailed: false,
        project: {
          name: "",
          ...
        }
      }
    },
    currentId: "id",
    usersById: {
      "id": {
        isFetching: false,
        isFailed: false,
        list: ["id", ...]
      }
    }
  },

  projectUser: {
    byId: {
      "id": {
        isFetching: false,
        isFailed: false,
        projectUser: {
          firstName: "",
          lastName: "",
          ...
        }
      }
    },

  }


}





 */
