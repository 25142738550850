import React from 'react';
import moment from "moment";
import BaseDynamicApiTable from "./BaseDynamicAPITable";
import { useNavigate } from "react-router-dom";
import { getUserStatus } from '../../utils/Utils';

export default function LeadsTable({ pageSize, filters, delayLoading }) {

    let navigate = useNavigate();

    return (
        <BaseDynamicApiTable
            apiMethod="/project/users"
            pageSize={pageSize}
            filters={filters}
            delayLoading={delayLoading}
            dataModel={{
                columns: [
                    {
                        header: "Name",
                        align: "left",
                        render: (row) => <span className="font-medium">{row.fullName}</span>
                    }, {
                        header: "Email",
                        align: "left",
                        render: (row) => row.email ? row.email : "—"
                    }, {
                        header: "Created",
                        align: "right",
                        render: (row) => moment(row.createdAt).fromNow()
                    }
                ]
            }}
            rowLinkTo={(row) => '/user/' + row.userId}
            rowLinkState={(row) => row}
        />
    );

}
