import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../utils/Transition';

function DropdownHelp({
  align
}) {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className={`w-8 h-8 flex items-center justify-center bg-inst-warm-grey text-brand-green transition duration-150 rounded-full ml-1 sm:ml-3 ${dropdownOpen ? '' : ''}`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="sr-only">Need help?</span>
        <svg className="w-4 h-4.5" width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.62072 10.6463V10.4709C3.62517 9.65665 3.70083 9.00887 3.84769 8.52753C3.99901 8.0462 4.21263 7.65933 4.48856 7.36693C4.7645 7.07003 5.10051 6.79787 5.4966 6.55045C5.76808 6.37951 6.01063 6.19283 6.22426 5.9904C6.44233 5.78347 6.61368 5.55404 6.73829 5.30213C6.86291 5.04572 6.92521 4.76006 6.92521 4.44517C6.92521 4.08979 6.84288 3.78165 6.67821 3.52074C6.51354 3.25982 6.29102 3.05739 6.01064 2.91344C5.7347 2.76949 5.42539 2.69752 5.08271 2.69752C4.76672 2.69752 4.46631 2.76724 4.18148 2.90669C3.9011 3.04165 3.66745 3.24858 3.48053 3.52748C3.29806 3.80189 3.19569 4.15052 3.17344 4.57338H0.796875C0.819128 3.71867 1.02385 3.00341 1.41104 2.42761C1.80269 1.8518 2.31895 1.41995 2.95982 1.13205C3.60514 0.844146 4.31722 0.700195 5.09606 0.700195C5.9461 0.700195 6.69379 0.850894 7.33911 1.15229C7.98888 1.45369 8.49401 1.88329 8.8545 2.4411C9.21945 2.99442 9.40192 3.65119 9.40192 4.41143C9.40192 4.92426 9.31958 5.3831 9.15491 5.78796C8.9947 6.19283 8.76549 6.5527 8.46731 6.8676C8.16913 7.18249 7.81531 7.46364 7.40587 7.71106C7.04538 7.93598 6.74942 8.1699 6.51799 8.41282C6.29102 8.65574 6.1219 8.94139 6.01064 9.26978C5.90382 9.59367 5.84819 9.99403 5.84374 10.4709V10.6463H3.62072ZM4.7823 14.8569C4.38175 14.8569 4.03684 14.7129 3.74756 14.425C3.45827 14.1371 3.31363 13.7862 3.31363 13.3724C3.31363 12.9675 3.45827 12.6211 3.74756 12.3332C4.03684 12.0453 4.38175 11.9014 4.7823 11.9014C5.17839 11.9014 5.52108 12.0453 5.81036 12.3332C6.1041 12.6211 6.25096 12.9675 6.25096 13.3724C6.25096 13.6468 6.18198 13.8965 6.04401 14.1214C5.9105 14.3463 5.73248 14.5262 5.50995 14.6612C5.29188 14.7916 5.04933 14.8569 4.7823 14.8569Z" fill="currentColor"/>
        </svg>
      </button>

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-[128px] md:min-w-[172px] bg-white shadow-[0px_8px_36px_rgba(11,38,63,0.08)] overflow-hidden mt-1 ${align === 'right' ? 'left-0 right-0 md:left-auto' : 'left-0'}`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <ul className="flex flex-col gap-1 py-3 md:py-5 px-3 md:px-4">
            <li>
              <a
                className="font-semibold text-sm md:text-xl text-brand-green hover:text-brand-green/[.5] flex items-center py-1 transition"
                href="https://help.invitemember.com"
                target="_blank"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <svg className="mr-2 md:mr-1.5" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="currentColor" strokeWidth="1.8" strokeLinejoin="round" />
                  <path d="M13.7067 8.41016C13.7067 11.0091 11.5998 13.116 9.0008 13.116C6.40182 13.116 4.29492 11.0091 4.29492 8.41016" stroke="currentColor" strokeWidth="1.8" strokeLinejoin="round" />
                </svg>
                <span>Help Center</span>
              </a>
            </li>
            <li>
              <a
                className="font-semibold text-sm md:text-xl text-brand-green hover:text-brand-green/[.5] flex items-center py-1 transition"
                href="https://t.me/InviteMemberSupportBot"
                target="_blank"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <svg className="mr-2 md:mr-1.5 translate-y-0.5" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.66667 1C2.2 1 1 2.23322 1 3.74048V16.5294L5.08889 13.0125C5.88889 12.3273 6.91111 11.9619 7.93333 11.9619H14.3333C15.8 11.9619 17 10.7287 17 9.22145V3.74048C17 2.23322 15.8 1 14.3333 1H3.66667Z" stroke="currentColor" strokeWidth="1.8" strokeLinejoin="round" />
                </svg>
                <span>Contact us</span>
              </a>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  )
}

export default DropdownHelp;
