export const USER_LOGOUT = 'USER_LOGOUT';

export function logout() {
    return {
        type: USER_LOGOUT
    }
}

export const LOGIN_TELEGRAM_REQUEST = 'LOGIN_TELEGRAM_REQUEST';
export const LOGIN_TELEGRAM_COMMIT = 'LOGIN_TELEGRAM_COMMIT';
export const LOGIN_TELEGRAM_ROLLBACK = 'LOGIN_TELEGRAM_ROLLBACK';

export function loginTelegram(telegramUser) {
    return {
        type: LOGIN_TELEGRAM_REQUEST,
        meta: {
            offline: {
                effect: {
                    url: `${process.env.REACT_APP_API_URL}/auth/login`,
                    body: JSON.stringify(telegramUser),
                    headers: { "Content-Type": "text/plain" },
                    method: "POST",
                },
                commit: {type: LOGIN_TELEGRAM_COMMIT},
                rollback: {type: LOGIN_TELEGRAM_ROLLBACK}
            }
        }
    }
}

export const COMPLETE_SIGNUP_REQUEST = "COMPLETE_SIGNUP_REQUEST";
export const COMPLETE_SIGNUP_COMMIT = "COMPLETE_SIGNUP_COMMIT";
export const COMPLETE_SIGNUP_ROLLBACK = "COMPLETE_SIGNUP_ROLLBACK";

export function completeSignUp(formData, token) {
    return {
        type: COMPLETE_SIGNUP_REQUEST,
        meta: {
            offline: {
                effect: {
                    url: `${process.env.REACT_APP_API_URL}/auth/register`,
                    json: formData,
                    method: "POST",
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                },
                commit: {type: COMPLETE_SIGNUP_COMMIT},
                rollback: {type: COMPLETE_SIGNUP_ROLLBACK}
            }
        }
    }
}

export const SUBSCRIPTION_STATUS_REQUEST = 'SUBSCRIPTION_STATUS_REQUEST';
export const SUBSCRIPTION_STATUS_COMMIT = 'SUBSCRIPTION_STATUS_COMMIT';
export const SUBSCRIPTION_STATUS_ROLLBACK = 'SUBSCRIPTION_STATUS_ROLLBACK';

export function fetchSubscriptionStatus(token) {
    return {
        type: SUBSCRIPTION_STATUS_REQUEST,
        meta: {
            offline: {
                effect: {
                    url: `${process.env.REACT_APP_API_URL}/billing/status`,
                    method: "POST",
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                },
                commit: {type: SUBSCRIPTION_STATUS_COMMIT},
                rollback: {type: SUBSCRIPTION_STATUS_ROLLBACK}
            }
        }
    }
}

export const PENDING_TELEGRAM_CHANNELS_REQUEST = 'PENDING_TELEGRAM_CHANNELS_REQUEST';
export const PENDING_TELEGRAM_CHANNELS_COMMIT = 'PENDING_TELEGRAM_CHANNELS_COMMIT';
export const PENDING_TELEGRAM_CHANNELS_ROLLBACK = 'PENDING_TELEGRAM_CHANNELS_ROLLBACK';

export function fetchPendingTelegramChannels(token) {
    return {
        type: PENDING_TELEGRAM_CHANNELS_REQUEST,
        meta: {
            offline: {
                effect: {
                    url: `${process.env.REACT_APP_API_URL}/resources/pending_channels`,
                    method: "POST",
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                },
                commit: {type: PENDING_TELEGRAM_CHANNELS_COMMIT},
                rollback: {type: PENDING_TELEGRAM_CHANNELS_ROLLBACK}
            }
        }
    }
}


export const ADD_PENDING_PLAN = "ADD_PENDING_PLAN";

export function addPendingPlan(price, currency, period) {
    return {
        type: ADD_PENDING_PLAN,
        price,
        currency,
        period
    }
}


export const CLEAR_PENDING_PLAN = "CLEAR_PENDING_PLAN";

export function clearPendingPlan() {
    return {
        type: CLEAR_PENDING_PLAN,
    }
}

export const FETCH_PROJECT_REQUEST = 'FETCH_PROJECT_REQUEST';
export const FETCH_PROJECT_COMMIT = 'FETCH_PROJECT_COMMIT';
export const FETCH_PROJECT_ROLLBACK = 'FETCH_PROJECT_ROLLBACK';

export function fetchProject(accessToken, projectId) {
    return {
        type: FETCH_PROJECT_REQUEST,
        meta: {
            offline: {
                effect: {
                    url: `${process.env.REACT_APP_API_URL}/project`,
                    method: "POST",
                    body: JSON.stringify({ accessToken, projectId }),
                    headers: { "Content-Type": "text/plain" }
                },
                commit: {type: FETCH_PROJECT_COMMIT},
                rollback: {type: FETCH_PROJECT_ROLLBACK}
            }
        }
    }
}

export const FETCH_MY_PROJECTS_REQUEST = 'FETCH_MY_PROJECTS_REQUEST';
export const FETCH_MY_PROJECTS_COMMIT = 'FETCH_MY_PROJECTS_COMMIT';
export const FETCH_MY_PROJECTS_ROLLBACK = 'FETCH_MY_PROJECTS_ROLLBACK';

export function fetchMyProjects(accessToken) {
    return {
        type: FETCH_MY_PROJECTS_REQUEST,
        meta: {
            offline: {
                effect: {
                    url: `${process.env.REACT_APP_API_URL}/project/my`,
                    method: "POST",
                    body: JSON.stringify({ accessToken }),
                    headers: { "Content-Type": "text/plain" }
                },
                commit: {type: FETCH_MY_PROJECTS_COMMIT},
                rollback: {type: FETCH_MY_PROJECTS_ROLLBACK}
            }
        }
    }
}

export const SET_CURRENT_PROJECT_ID = "SET_CURRENT_PROJECT_ID";

export function setCurrentProjectId(projectId) {
    return {
        type: SET_CURRENT_PROJECT_ID,
        projectId
    }
}

export const SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT";

export function setCurrentProject(projectId, project) {
    return {
        type: SET_CURRENT_PROJECT,
        projectId,
        project
    }
}

export const CLEAR_PROJECT_USERS = 'CLEAR_PROJECT_USERS';

export function clearProjectUsers(projectId) {
    return {
        type: CLEAR_PROJECT_USERS,
        meta: {
            projectId: projectId
        }
    }
}

export const SET_PROJECT_USERS_PAGINATION_FILTER = 'SET_PROJECT_USERS_PAGINATION_FILTER';

export function setProjectUsersPaginationFilter(projectId, pageSize, pageNumber) {
    return {
        type: SET_PROJECT_USERS_PAGINATION_FILTER,
        meta: {
            projectId: projectId,
            pageSize: pageSize,
            pageNumber: pageNumber
        }
    }
}

export const FETCH_PROJECT_USERS_REQUEST = 'FETCH_PROJECT_USERS_REQUEST';
export const FETCH_PROJECT_USERS_COMMIT = 'FETCH_PROJECT_USERS_COMMIT';
export const FETCH_PROJECT_USERS_ROLLBACK = 'FETCH_PROJECT_USERS_ROLLBACK';

export function fetchProjectUsers(token, projectId, startKey) {
    return {
        type: FETCH_PROJECT_USERS_REQUEST,
        meta: {
            projectId: projectId,
            startKey: startKey,
            offline: {
                effect: {
                    url: `${process.env.REACT_APP_API_URL}/project/users`,
                    method: "POST",
                    json: {
                        projectId: projectId,
                        startKey: startKey,
                        limit: 1
                    },
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                },
                commit: {type: FETCH_PROJECT_USERS_COMMIT, meta: {projectId, startKey}},
                rollback: {type: FETCH_PROJECT_USERS_ROLLBACK, meta: {projectId, startKey}}
            }
        }
    }
}


export const FETCH_PROJECT_PAYMENTS_REQUEST = 'FETCH_PROJECT_PAYMENTS_REQUEST';
export const FETCH_PROJECT_PAYMENTS_COMMIT = 'FETCH_PROJECT_PAYMENTS_COMMIT';
export const FETCH_PROJECT_PAYMENTS_ROLLBACK = 'FETCH_PROJECT_PAYMENTS_ROLLBACK';

export function fetchProjectPayments(token, projectId) {
    return {
        type: FETCH_PROJECT_PAYMENTS_REQUEST,
        meta: {
            projectId: projectId,
            offline: {
                effect: {
                    url: `${process.env.REACT_APP_API_URL}/project/payments`,
                    method: "POST",
                    json: {
                        projectId: projectId
                    },
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                },
                commit: {type: FETCH_PROJECT_PAYMENTS_COMMIT, meta: {projectId}},
                rollback: {type: FETCH_PROJECT_PAYMENTS_ROLLBACK, meta: {projectId}}
            }
        }
    }
}

export const FETCH_PROJECT_SUBSCRIPTIONS_REQUEST = 'FETCH_PROJECT_SUBSCRIPTIONS_REQUEST';
export const FETCH_PROJECT_SUBSCRIPTIONS_COMMIT = 'FETCH_PROJECT_SUBSCRIPTIONS_COMMIT';
export const FETCH_PROJECT_SUBSCRIPTIONS_ROLLBACK = 'FETCH_PROJECT_SUBSCRIPTIONS_ROLLBACK';

export function fetchProjectSubscriptions(token, projectId) {
    return {
        type: FETCH_PROJECT_SUBSCRIPTIONS_REQUEST,
        meta: {
            projectId: projectId,
            offline: {
                effect: {
                    url: `${process.env.REACT_APP_API_URL}/project/subscriptions`,
                    method: "POST",
                    json: {
                        projectId: projectId
                    },
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                },
                commit: {type: FETCH_PROJECT_SUBSCRIPTIONS_COMMIT, meta: {projectId}},
                rollback: {type: FETCH_PROJECT_SUBSCRIPTIONS_ROLLBACK, meta: {projectId}}
            }
        }
    }
}

export const FETCH_PROJECT_PLANS_REQUEST = 'FETCH_PROJECT_PLANS_REQUEST';
export const FETCH_PROJECT_PLANS_COMMIT = 'FETCH_PROJECT_PLANS_COMMIT';
export const FETCH_PROJECT_PLANS_ROLLBACK = 'FETCH_PROJECT_PLANS_ROLLBACK';

export function fetchProjectPlans(token, projectId) {
    return {
        type: FETCH_PROJECT_PLANS_REQUEST,
        meta: {
            offline: {
                effect: {
                    url: `${process.env.REACT_APP_API_URL}/project/plans`,
                    method: "POST",
                    json: {
                        projectId: projectId
                    },
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                },
                commit: {type: FETCH_PROJECT_PLANS_COMMIT, meta: {projectId}},
                rollback: {type: FETCH_PROJECT_PLANS_ROLLBACK, meta: {projectId}}
            }
        }
    }
}

export const FETCH_PROJECT_RESOURCES_REQUEST = 'FETCH_PROJECT_RESOURCES_REQUEST';
export const FETCH_PROJECT_RESOURCES_COMMIT = 'FETCH_PROJECT_RESOURCES_COMMIT';
export const FETCH_PROJECT_RESOURCES_ROLLBACK = 'FETCH_PROJECT_RESOURCES_ROLLBACK';

export function fetchProjectResources(token, projectId) {
    return {
        type: FETCH_PROJECT_RESOURCES_REQUEST,
        meta: {
            projectId: projectId,
            offline: {
                effect: {
                    url: `${process.env.REACT_APP_API_URL}/project/resources`,
                    method: "POST",
                    json: {
                        projectId: projectId
                    },
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                },
                commit: {type: FETCH_PROJECT_RESOURCES_COMMIT, meta: {projectId}},
                rollback: {type: FETCH_PROJECT_RESOURCES_ROLLBACK, meta: {projectId}}
            }
        }
    }
}
