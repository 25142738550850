import React, {Component} from 'react';
import ButtonTertiary from "./ButtonTertiary";
import Badge from "./Badge";
const moment = require("moment");

class IMPlanCard extends Component {
  render() {

    let benefits = this.props.benefits.map((benefit, index) =>
      <li className="flex items-center" key={index} >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className="w-4 fill-current text-gray-700 mr-2">
          <path
            d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/>
        </svg>
        {benefit}
      </li>
    );

    console.log(benefits);

    let statusColorsMap = {
      active: "green",
      trialing: "green",
      past_due: "yellow",
      canceled: "gray",
      unpaid: "gray"
    };

    let statusColor = statusColorsMap[this.props.status] ? statusColorsMap[this.props.status] : "gray";

    let statusDescriptionsMap = {
      active: "active",
      trialing: "free trial",
      past_due: "payment failed",
      canceled: "canceled",
      unpaid: "canceled"
    };

    let statusDescription = statusDescriptionsMap[this.props.status] ? statusDescriptionsMap[this.props.status] : this.props.status;

    if (this.props.cancelAtPeriodEnd) { // special case
      statusColor = "gray";
      statusDescription = "canceling on " + moment(this.props.currentPeriodEnd).format("Do MMM");
    }

    let showCancelButton = ["active", "trialing", "past_due"].indexOf(this.props.status) > -1;
    let showFirstChargeDate = "trialing" === this.props.status;
    let showRenewalDate = "active" === this.props.status && !this.props.cancelAtPeriodEnd;
    let showUpdateDetailsWarning = "past_due" === this.props.status;

    showCancelButton = false;

    // showFirstChargeDate = false;
    // showRenewalDate = false;
    // showUpdateDetailsWarning = true;

    return (
      <div className="bg-white p-4 md:max-w-md md:shadow-lg rounded rounded-lg">
        <div className="flex items-baseline">
          {/*<div className="flex items-end flex-grow">*/}
          <h4 className="flex-grow"><span className="text-3xl">${this.props.amount}</span><span className="text-base text-gray-600">/{this.props.period}</span></h4>
          {/*</div>*/}
          <Badge color={statusColor} className={"ml-3"}>{statusDescription}</Badge>
          { showCancelButton && <ButtonTertiary>Cancel</ButtonTertiary> }
        </div>

        <h5 className="text-gray-600 text-xl">{this.props.name}</h5>

        <div className="mt-4">
          { showFirstChargeDate ? (
            <p className="text-green-700">💳 You will be charged for the first time on { moment(this.props.currentPeriodEnd).format("MMMM, Do") }.</p>
          ) : ""}
          { showRenewalDate ? (
            <p className="text-green-700">💳 Your subscription will be renewed automatically on { moment(this.props.currentPeriodEnd).format("MMMM, Do") }.</p>
          ) : ""}
          { showUpdateDetailsWarning ? (
            <p className="text-yellow-700">⚠️ Your last payment has failed. <a href="https://invitemember.com/billing/update" className="text-blue-700 hover:underline">Update billing info</a> </p>
          ) : ""}
        </div>

        <ul className="mt-4">
          {benefits}
        </ul>

      </div>
    );
  }
}

export default IMPlanCard;