import React, {Component} from 'react';

class ButtonTertiary extends Component {
  render() {
    return (
      <button className={"bg-transparent hover:underline text-blue-700  py-2 px-4 rounded " + this.props.className}>
        {this.props.children}
      </button>
    );
  }
}

export default ButtonTertiary;