const humanizer = require('humanize-duration').humanizer();

export const lifetime = 86400 * 365 * 1000 // 1000 years
export const minute = 60;
export const hour = 60 * minute;
export const day = 60 * 60 * 24;
export const week = 7 * day;
export const month = 30 * day;
export const year = 365 * day;

export const humanizeDuration = (secs) => {
    if (secs === lifetime) return "lifetime";
    let daySecs = 24 * 60 * 60;
    let opts = {};
    return humanizer(secs, {
        ...opts,
        round: true,
        unitMeasures: {
            y: 365 * daySecs,
            mo: 30 * daySecs,
            w: 7 * daySecs,
            d: daySecs,
            h: 3600,
            m: 60,
            s: 1
        }
    });
}