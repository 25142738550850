import React, {useEffect, useState} from 'react';
import { useParams, Navigate, NavLink } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'
import {loginTelegram} from "../actions";
import TelegramLoginButton from "../Bits/TelegramLoginButton";
import StaticLayout from '../Layouts/StaticLayout';

/* Logic:

1. If non-seamless login AND already logged in, redirect to /
2. If non-seamless login AND not logged in, show Telegram Login Widget
3. If seamless login AND already logged in with SAME userId, redirect to /
4. If seamless login AND not logged in OR logged in with DIFFERENT userId, execute login and AFTER that redirect to /

 */

function Login(props) {

    const login = (telegramUser) => {
        setLoginAttemptMade(true);
        dispatch(loginTelegram(telegramUser));
    }

    const dispatch = useDispatch();
    const [searchParams, updateSearchParams] = useSearchParams();

    const auth = useSelector((state) => state.auth)
    const [ loginAttemptMade, setLoginAttemptMade ] = useState(false);

    let isLoggedIn = !!auth?.user, userIdLoggedIn = auth?.user?.telegramUserId;
    let userIdToLogin = searchParams.has("id") ? parseInt(searchParams.get("id")) : 0;
    let isSeamlessLogin = searchParams.has("id") && searchParams.has("hash");
    let loggedInAlready = isLoggedIn && (!isSeamlessLogin || (isSeamlessLogin && userIdLoggedIn === userIdToLogin));

    // return;

    useEffect(() => {
        if (isSeamlessLogin && !loginAttemptMade) {
            login({
                id: searchParams.get("id"),
                first_name: searchParams.get("first_name"),
                last_name: searchParams.get("last_name"),
                username: searchParams.get("username"),
                photo_url: searchParams.get("photo_url"),
                auth_date: searchParams.get("auth_date"),
                hash: searchParams.get("hash")
            });
        }
    });

    if (loggedInAlready) {
        if (searchParams.has("projectId")) {
            return <Navigate to={`/goto_project?projectId=${searchParams.get("projectId")}`}/>
        } else {
            return <Navigate to={`/`}/>
        }
    } else {
        if (isSeamlessLogin) {
            return null; // login will be dispatched almost immediately, no need for UI
        } else {
            return (
                <StaticLayout logoPosition={"left"} logoWhite size={'medium'} layoutCenter layoutFixed>
                    <div className="basis-0 grow shrink">
                        {loginAttemptMade ? (
                            <div className="flex items-center justify-center py-1">
                                <svg className="animate-rotation w-10 h-10" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
                                    <path d="M12 2C11.4467 2 10.9039 2.04494 10.375 2.13136" stroke="white" strokeWidth="3" strokeLinecap="round"/>
                                    <path d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2" stroke="#8A9698" strokeWidth="3" strokeLinecap="round"/>
                                </svg>
                            </div>
                        ) : (
                            <>
                                <h1 className="text-[40px] md:text-[5.5rem] font-semibold text-brand-green mt-2 mb-[22px] md:mb-8 font-founders tracking-normal leading-none md:text-center max-w-[29rem] md:max-w-[35rem] mx-auto">
                                    Join InviteMember
                                </h1>
                                <div className="max-w-[29rem] mx-auto">
                                    <div className="border border-brand-green/[0.2] rounded-[16px] w-full px-6 py-[17px] md:px-10 md:py-6.5 flex items-center flex-col justify-center mb-6 md:mb-8 min-h-[76px]">
                                        <TelegramLoginButton dataOnauth={ login } botName={process.env.REACT_APP_BOT_USERNAME} />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </StaticLayout>
            );
        }
    }

}

export default Login;
