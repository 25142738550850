import React, {Component} from 'react';

class Badge extends Component {
  render() {

    let colors;

    if (this.props.color === "red") {
      colors = "bg-red-200 text-red-700";
    } else if (this.props.color === "blue") {
      colors = "bg-blue-200 text-blue-700";
    } else if (this.props.color === "emerald") {
      colors = "bg-emerald-100 text-emerald-600";
    } else if (this.props.color === "amber") {
      colors = "bg-amber-100 text-amber-600";
    } else {
      colors = "bg-gray-200 text-gray-700";
    }

    return (
      <div className={this.props.className + " text-xs inline-flex font-medium rounded-full text-center px-2.5 py-1 " + colors}>
        {this.props.children}
      </div>
    );
  }
}

export default Badge;