import React from 'react';

export default function PayCancel() {
  return (
    <div className="bg-brand-white w-full h-[100vh] flex">
        <style>
            {`
                .path {
                    stroke-dasharray: 1000;
                    stroke-dashoffset: 0;
                }
                .path.circle {
                    -webkit-animation: dash 0.9s ease-in-out;
                    animation: dash 0.9s ease-in-out;
                }
                .path.line {
                    stroke-dashoffset: 1000;
                    -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
                    animation: dash 0.9s 0.35s ease-in-out forwards;
                }
                .path.check {
                    stroke-dashoffset: -100;
                    -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
                    animation: dash-check 0.9s 0.35s ease-in-out forwards;
                }
                @-webkit-keyframes dash {
                    0% {
                        stroke-dashoffset: 1000;
                    }
                    100% {
                        stroke-dashoffset: 0;
                    }
                }
                @keyframes dash {
                    0% {
                        stroke-dashoffset: 1000;
                    }
                    100% {
                        stroke-dashoffset: 0;
                    }
                }
                @-webkit-keyframes dash-check {
                    0% {
                        stroke-dashoffset: -100;
                    }
                    100% {
                        stroke-dashoffset: 900;
                    }
                }
                @keyframes dash-check {
                    0% {
                        stroke-dashoffset: -100;
                    }
                    100% {
                        stroke-dashoffset: 900;
                    }
                }
            `}
        </style>
      <div className="absolute top-1/2 left-1/2 text-center text-brand-green" style={{ transform: 'translate(-50%, -50%)' }}>
          <svg className="w-28 block mx-auto mt-10" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle"
                      fill="none"
                      stroke="#2D3748"
                      strokeWidth="6"
                      strokeMiterlimit="10"
                      cx="65.1"
                      cy="65.1"
                      r="62.1"></circle>
              <line className="path line"
                    fill="none"
                    stroke="#2D3748"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="34.4"
                    y1="37.9"
                    x2="95.8"
                    y2="92.3"></line>
              <line className="path line"
                    fill="none"
                    stroke="#2D3748"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="95.8"
                    y1="38"
                    x2="34.4"
                    y2="92.2"></line>
          </svg>
          <h1 className="text-5xl md:text-[5.5rem] font-bold mt-4 mb-4 font-founders">Not Okay :-(</h1>
          <p className="text-sm md:text-xl">Please return to the bot and try again.</p>
      </div>
    </div>
  );
}

