export const fetchAPI = async(path, token, params, options = {}) => {
    return await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: "POST",
        // headers: {"authorization": `Bearer ${token}`},
        body: JSON.stringify({
            accessToken: token,
            ...params
        }),
        ...options,
    });
}
