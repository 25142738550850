import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as ReactLogoMiniWhite } from '../img/logo-mini-white.svg';

import SidebarLinkGroup from './SidebarLinkGroup';

function Sidebar({
   sidebarOpen,
   setSidebarOpen
}) {

  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  const menuTree = [
    {
      name: "Dashboard",
      icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${(pathname === '/' || pathname.includes('dashboard')) ? 'fill-current' : ''}`}>
              <rect x="0.9" y="0.9" width="7.29091" height="7.29091" rx="1.1" stroke="currentColor" strokeWidth="1.8"/>
              <rect x="0.9" y="11.8082" width="7.29091" height="7.29091" rx="1.1" stroke="currentColor" strokeWidth="1.8"/>
              <rect x="11.8082" y="0.9" width="7.29091" height="7.29091" rx="1.1" stroke="currentColor" strokeWidth="1.8"/>
              <rect x="11.8082" y="11.8082" width="7.29091" height="7.29091" rx="1.1" stroke="currentColor" strokeWidth="1.8"/>
            </svg>,
      href: '/',
      activeCondition: pathname === '/',
    },
    {
      name: "Users",
      icon: <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${(pathname.includes('users') || pathname.includes('user')) ? 'fill-current' : ''}`}>
              <path d="M11.0186 11.0171C11.0186 10.9575 11.0007 10.898 10.9709 10.8444C10.9411 10.7908 10.8935 10.7491 10.8399 10.7252C9.33907 9.98078 7.68339 9.5758 6.00985 9.54602C4.3244 9.54602 2.66873 9.95696 1.1679 10.7252C1.1143 10.755 1.07261 10.7967 1.04283 10.8444C1.01305 10.892 0.995185 10.9516 1.00114 11.0111V15.8054C1.00114 15.8054 1.00114 15.8233 1.00114 15.8292C1.00114 15.8352 1.0071 15.8471 1.01305 15.8531C1.01901 15.859 1.02496 15.865 1.03688 15.865C1.04283 15.865 1.05474 15.865 1.0607 15.865H10.953C10.953 15.865 10.9709 15.865 10.9769 15.865C10.9828 15.865 10.9947 15.859 11.0007 15.8531C11.0067 15.8471 11.0126 15.8412 11.0126 15.8292C11.0126 15.8233 11.0126 15.8114 11.0126 15.8054V11.0171H11.0186Z" stroke="currentColor" strokeWidth="1.8"/>
              <path d="M6.0115 6.28863C7.47192 6.28863 8.65581 5.10473 8.65581 3.64431C8.65581 2.1839 7.47192 1 6.0115 1C4.55109 1 3.36719 2.1839 3.36719 3.64431C3.36719 5.10473 4.55109 6.28863 6.0115 6.28863Z" stroke="currentColor" strokeWidth="1.8"/>
              <path d="M20.999 11.0171C20.999 10.9575 20.9812 10.898 20.9514 10.8444C20.9216 10.7908 20.874 10.7491 20.8204 10.7252C19.3195 9.98078 17.6639 9.5758 15.9903 9.54602C14.3049 9.54602 12.6492 9.95696 11.1484 10.7252C11.0948 10.755 11.0531 10.7967 11.0233 10.8444C10.9935 10.892 10.9757 10.9516 10.9816 11.0111V15.8054C10.9816 15.8054 10.9816 15.8233 10.9816 15.8292C10.9816 15.8352 10.9876 15.8471 10.9935 15.8531C10.9995 15.859 11.0054 15.865 11.0173 15.865C11.0233 15.865 11.0352 15.865 11.0412 15.865H20.9335C20.9335 15.865 20.9514 15.865 20.9573 15.865C20.9633 15.865 20.9752 15.859 20.9812 15.8531C20.9871 15.8471 20.9931 15.8412 20.9931 15.8292C20.9931 15.8233 20.9931 15.8114 20.9931 15.8054V11.0171H20.999Z" stroke="currentColor" strokeWidth="1.8"/>
              <path d="M15.992 6.28863C17.4524 6.28863 18.6363 5.10473 18.6363 3.64431C18.6363 2.1839 17.4524 1 15.992 1C14.5316 1 13.3477 2.1839 13.3477 3.64431C13.3477 5.10473 14.5316 6.28863 15.992 6.28863Z" stroke="currentColor" strokeWidth="1.8"/>
            </svg>,
      activeCondition: pathname.includes('users') || pathname.includes('user'),
      subMenu: [
        {
          name: 'All Users',
          href: '/users',
        },
        {
          name: 'Leads',
          href: '/users/leads',
        },
        {
          name: 'Customers',
          href: '/users/customers',
        },
        {
          name: 'Churned',
          href: '/users/churned',
        },
      ],
    },
    {
      name: "Sales",
      icon: <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${(pathname.includes('sales')) ? 'fill-current' : ''}`}>
              <circle cx="10.0909" cy="11.9091" r="9.09091" fill="white" stroke="currentColor" strokeWidth="1.8"/>
              <path d="M20.9991 10.0909C20.9991 5.07014 16.929 1 11.9082 1V10.0909H20.9991Z" fill={`${pathname.includes('sales') ? 'currentColor' : 'white'}`} stroke="currentColor" strokeWidth="1.8"/>
            </svg>,
      activeCondition: pathname.includes('sales'),
      subMenu: [
        {
          name: 'One-time payments',
          href: '/sales/payments',
        },
        {
          name: 'Subscriptions',
          href: '/sales/subscriptions',
        },
        {
          name: 'Recurring payments',
          href: '/sales/recurringPayments',
        },
      ],
    }
  ];

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-white border-r border-r-warm-grey text-brand-green py-[18px] px-2 transition-all duration-200 ease-in-out ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-64'
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 px-3 sm:px-5">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-brand-green"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg className="w-6 h-6 fill-current" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.86258 10.0054H20.4516" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M12.6813 17.1724L13.8758 15.8903L7.54153 10.0053L13.8758 4.12039L12.6813 2.8382L4.96619 10.0053L12.6813 17.1724Z" fill="currentColor"/>
              <path d="M1 0V20" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
            </svg>
          </button>
          {/* Logo */}
          <NavLink end="true" to="/" className="block w-14 h-8 lg:w-[77px] lg:h-[44px]">
            <ReactLogoMiniWhite className="w-full h-full invisible sidebar-expanded:visible text-brand-green transition duration-300 ease-out" />
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <ul className="mt-5">
              {/* Dashboard */}
              {menuTree.map((menuItem, index) => (
                <SidebarLinkGroup key={`menu-item-${index}`} activecondition={menuItem.activeCondition}>
                  {(handleClick, open) => {
                    return (
                      <>
                        <NavLink
                          to={menuItem.href || '#0'}
                          end={!menuItem.subMenu}
                          className={`block sm:px-5 sidebar-expanded:px-3.5 sm:sidebar-expanded:px-6 py-2 sm:py-3 text-brand-green rounded-xl group ${menuItem.activeCondition ? 'sidebar-expanded:bg-warm-grey/[.16]' : ''} truncate transition duration-150`}
                          onClick={(e) => {
                            if (menuItem.subMenu) {
                              e.preventDefault();
                              sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                            }
                          }}
                        >
                          <div className="flex items-center justify-between">
                            <div className={`flex items-center ${menuItem.activeCondition ? '' : 'group-hover:opacity-50'} transition duration-200`}>
                              {menuItem.icon}
                              <span className="text-base sm:text-xl font-semibold ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
                                {menuItem.name}
                              </span>
                            </div>
                            {/* Icon */}
                            {menuItem.subMenu && (
                              <div className="flex shrink-0 ml-2">
                                <svg className={`w-3 h-3 shrink-0 ml-1 fill-current ${open ? 'rotate-180' : ''}`} viewBox="0 0 12 12">
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z"/>
                                </svg>
                              </div>
                            )}
                          </div>
                        </NavLink>
                        {menuItem.subMenu && (
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-4 sm:pl-6 mt-4 ${!open ? 'hidden' : ''}`}>
                              {menuItem.subMenu.map((subMenuItem, index) => (
                                <li key={`submenu-item-${index}`} className="mb-1 last:mb-0">
                                  <NavLink
                                      end
                                      to={subMenuItem.href}
                                      className={({isActive}) => `block text-sm sm:text-base mb-4 transition duration-200 truncate ` + (isActive ? 'font-bold' : 'hover:text-brand-green/[.5]')}
                                  >
                                    <span className="lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      {subMenuItem.name}
                                    </span>
                                  </NavLink>
                                </li>
                              ))}
                          </ul>
                          </div>
                        )}
                      </>
                    );
                  }}
                </SidebarLinkGroup>
              ))}
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-center sidebar-expanded:ml-auto mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                <path className="text-brand-green" d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z" />
                <path className="text-brand-green" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
