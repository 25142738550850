import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux'
import {loginTelegram} from "../actions";
import OnboardingLayout from "../Layouts/OnboardingLayout";
import TelegramLoginButton from "../Bits/TelegramLoginButton";
import qs from "query-string";

class LoginPage extends Component {
  constructor(){
    super();
    this.handleTelegramResponse = this.handleTelegramResponse.bind(this);
    this.isSeamlessLoginAttempt = false;
  }

  render() {
    if (this.isSeamlessLoginAttempt) {
      return (
        <div>Logging you in...</div>
      );
    } else {
      return (
        <OnboardingLayout>
          <TelegramLoginButton dataOnauth={this.handleTelegramResponse} botName={process.env.REACT_APP_BOT_USERNAME} />
        </OnboardingLayout>
      );
    }
  }

  handleTelegramResponse(telegramUser) {
    this.props.loginTelegram(telegramUser);
  }

  checkIfSeamlessLogin() {
    let params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (params.id && params.hash) {
      this.isSeamlessLoginAttempt = true;
      this.props.loginTelegram(params);
    }
  }

  checkIfRedirectNeeded() {
    if (this.props.auth.user) {
      if (!this.props.auth.user.signupCompleted) {
        this.props.history.push("/complete_signup");
      } else {
        this.props.history.push("/");
      }
    }
  }

  componentWillMount() {
    this.checkIfRedirectNeeded();
    this.checkIfSeamlessLogin();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkIfRedirectNeeded();
    // this.checkIfSeamlessLogin();
  }

}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
};

const mapDispatchToProps = {
  loginTelegram
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
