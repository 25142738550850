var telegramCurrencies = require("./telegram.json");
var cryptoCurrencies = require("./crypto.json");
var otherCurrencies = require("./other.json");

let getCurrency = (code) => {
  if (telegramCurrencies[code.toUpperCase()]) {
    return telegramCurrencies[code.toUpperCase()];
  } else if (cryptoCurrencies[code.toUpperCase()]) {
    return cryptoCurrencies[code.toUpperCase()];
  } else if (otherCurrencies[code.toUpperCase()]) {
    return otherCurrencies[code.toUpperCase()];
  } else {
    return {
      "code":code,
      "title":code,
      "symbol":code,
      "native":code,
      "thousands_sep":"",
      "decimal_sep":".",
      "symbol_left":false,
      "space_between":true,
      "exp":8,
      "min_amount":"0",
      "max_amount":"10000000000000000"
    }
  }
};

export const isFiat = (code) => {
  return !!telegramCurrencies[code.toUpperCase()] || !!otherCurrencies[code.toUpperCase()];
};

export const isCrypto = (code) => {
  return !!cryptoCurrencies[code.toUpperCase()];
};

export const getCurrencyTitle = code => {
  return getCurrency(code).title;
};

export const getCurrencyCodeTitle = code => {
  return code + " (" + getCurrency(code).title + ")";
};

export const getCurrencyDecimalSep = code => {
  return getCurrency(code).decimal_sep;
};

export const getCurrencyExp = code => {
  return getCurrency(code).exp;
};

export const toFixed = (amount, currency) => {
  let exp = 8;
  try {
    exp = getCurrencyExp(currency);
  } catch (e) {
    console.log("Failed to find proper exp for currency " + currency + ", reverting to default 8")
  }
  return amount.toFixed(exp);
}

export const priceToSmallestUnits = (price, code) => {
  let priceSmallestUnits = Math.floor(Math.pow(10, getCurrency(code).exp) * price);
  return priceSmallestUnits
};

export const priceFromSmallestUnits = (priceSmallestUnits, code) => {
  let price = priceSmallestUnits / Math.pow(10, getCurrency(code).exp);
  return price
};

export const getCurrencyPriceIntervalString = code => {
  let strInterval =
    (getCurrency(code).min_amount / Math.pow(10, getCurrency(code).exp)).toFixed(getCurrency(code).exp) +
    "-" +
    (getCurrency(code).max_amount / Math.pow(10, getCurrency(code).exp)).toFixed(getCurrency(code).exp);
  return formatPriceBase(strInterval, code)
};

export const formatPriceBase = (price, code) => {
  let space = getCurrency(code).space_between ? " " : "";
  if (getCurrency(code).symbol_left) {
    return getCurrency(code).symbol + space + price;
  } else {
    return price + space + getCurrency(code).symbol;
  }
};

export const formatPrice = (price, code) => {

  let strPrice = getCurrency(code).exp > 0 ? price.toFixed(getCurrency(code).exp) : price.toString();

  if (code === "BTC") { // special case
    strPrice = price.toString();
  }

  if (getCurrency(code).decimal_sep !== ".") {
    strPrice = strPrice.replace(".", getCurrency(code).decimal_sep);
  }
  strPrice = strPrice.replace(/\B(?=(\d{3})+(?!\d))/g, getCurrency(code).thousands_sep);
  return formatPriceBase(strPrice, code)
};

export const formatPriceOnly = (price, code) => {
  return formatPrice(price, code).replace(code, "").trim();
};

export const getCurrencyMinAmount = code => {
  return getCurrency(code).min_amount;
};

export const getCurrencyMaxAmount = code => {
  return getCurrency(code).max_amount;
};


export const getMethodBaseCurrency = method => {
  if (method == "stripe") {
    return "USD"
  } else {
    throw new Error("I don't know default currency for this method")
  }
};

export const calcAmountInCents = (amount, currency) => {
  return Math.round(amount * Math.pow(10, getCurrency(currency).exp));
};

export const convertToCurrency = async(currencyFrom, currencyTo, amount) => {
  // TODO use API
};

