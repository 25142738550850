import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition';

function Tooltip({
  children,
  className,
  bg,
  size,
  position,
}) {

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const buttonRef = useRef();
  const tooltipRef = useRef();
  const tooltipTriangleRef = useRef();

  const positionOuterClasses = (position) => {
    switch (position) {
      case 'right':
        return 'left-full top-1/2 -translate-y-1/2';
      case 'left':
        return 'right-full top-1/2 -translate-y-1/2';
      case 'bottom':
        return 'top-full left-1/2 -translate-x-1/2';
      default:
        return 'bottom-full left-1/2 -translate-x-1/2';
    }
  }

  const sizeClasses = (size) => {
    switch (size) {
      case 'lg':
        // 8px 36px 8px 16px
        return 'min-w-72 py-2 pr-9 pl-4';
      case 'md':
        return 'min-w-56 py-2 pr-9 pl-4';
      case 'sm':
        return 'min-w-44 py-2 pr-9 pl-4';
      default:
        return 'p-2';
    }
  };

  const positionInnerClasses = (position) => {
    switch (position) {
      case 'right':
        return 'ml-2';
      case 'left':
        return 'mr-2';
      case 'bottom':
        return 'mt-2';
      default:
        return 'mb-2';
    }
  };

  useEffect(() => {
    if (tooltipOpen) {
      handleTooltipPosition();
    }
  }, [tooltipOpen]);

  const handleTooltipPosition = () => { // fixing tooltip position if it goes out of screen
    const screenPadding = 15;

    const buttonRect = buttonRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();

    const tooltipRightX = tooltipRect.x + tooltipRect.width;
    const buttonRightX = buttonRect.x + buttonRect.width;

    if (tooltipRect.x < 0) {
      tooltipRef.current.style.left = '0';
      tooltipRef.current.style.right = 'auto';
      tooltipRef.current.style.transform = `translateX(${-buttonRect.x + screenPadding}px)`;
      tooltipTriangleRef.current.style.marginLeft = `${buttonRect.x - screenPadding}px`;
    } else if (tooltipRightX > window.screen.width) {
      tooltipRef.current.style.left = 'auto';
      tooltipRef.current.style.right = '0';
      tooltipRef.current.style.transform = `translateX(${(window.screen.width - buttonRightX) - screenPadding}px)`;
      tooltipTriangleRef.current.style.marginRight = `${(window.screen.width - buttonRightX) - screenPadding}px`;
    }
  };

  return (
    <div
      className={`relative ${className}`}
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
      onFocus={() => setTooltipOpen(true)}
      onBlur={() => setTooltipOpen(false)}
    >
      <button
        ref={buttonRef}
        className="w-3 h-3 md:w-4 md:h-4 flex items-center justify-center rounded-full"
        aria-haspopup="true"
        aria-expanded={tooltipOpen}
        onClick={(e) => e.preventDefault()}
        style={{ backgroundColor: 'currentColor' }}
      >
        <svg className="fill-current text-slate-400" width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.16963 7.01264C3.16663 7.01364 2.89013 7.10565 2.65013 7.10565C2.51763 7.10565 2.46413 7.07815 2.44713 7.06615C2.36313 7.00764 2.20713 6.89814 2.47413 6.36612L2.97413 5.36909C3.27063 4.77706 3.31463 4.20454 3.09663 3.75653C2.91863 3.39001 2.57713 3.13851 2.13663 3.0485C1.97812 3.016 1.81712 3 1.65762 3C0.733119 3 0.110616 3.54002 0.0846164 3.56302C-0.00488405 3.64202 -0.0258843 3.77303 0.033616 3.87603C0.0936163 3.97904 0.217117 4.02604 0.331117 3.98704C0.333617 3.98604 0.610619 3.89353 0.85062 3.89353C0.982121 3.89353 1.03512 3.92103 1.05162 3.93253C1.13612 3.99154 1.29262 4.10254 1.02612 4.63356L0.526118 5.63109C0.229117 6.22362 0.185617 6.79614 0.403618 7.24365C0.581619 7.61017 0.92262 7.86167 1.36412 7.95168C1.52112 7.98318 1.68212 8.00018 1.84112 8.00018C2.76613 8.00018 3.38913 7.46016 3.41513 7.43716C3.50463 7.35866 3.52563 7.22715 3.46613 7.12415C3.40613 7.02164 3.28163 6.97564 3.16963 7.01264Z" fill="#F7F8EF"/>
          <path d="M2.75 2.50009C3.44036 2.50009 4.00001 1.94043 4.00001 1.25004C4.00001 0.559664 3.44036 0 2.75 0C2.05965 0 1.5 0.559664 1.5 1.25004C1.5 1.94043 2.05965 2.50009 2.75 2.50009Z" fill="#F7F8EF"/>
        </svg>
      </button>
      <div ref={tooltipRef} className={`z-10 absolute ${positionOuterClasses(position)}`}>
        <Transition
          show={tooltipOpen}
          tag="div"
          className={`relative rounded-lg ${bg === 'dark' ? 'bg-slate-800' : 'bg-brand-green  text-brand-white '} ${sizeClasses(size)} ${positionInnerClasses(position)}`}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          <div ref={tooltipTriangleRef} className={`absolute z-1 left-0 right-0 bottom-[-8px] top-auto mx-auto w-0 h-0 border-l-[7px] border-l-transparent border-t-[8px] border-t-brand-green border-r-[7px] border-r-transparent`} />
          {children}
        </Transition>
      </div>
    </div>
  );
}

export default Tooltip;
