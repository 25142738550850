import React, { useCallback, useEffect, useRef, useState } from 'react';

import {fetchAPI} from "../api";
import {NavLink, useNavigate, useOutletContext} from "react-router-dom";
import {tailwindConfig, hexToRGB, formatValue} from '../utils/Utils';
import {
    Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip, Interaction, CategoryScale
} from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment';
import numeral from 'numeral';
import ChartLoadingPlaceholder from './ChartLoadingPlaceholder';
import { week } from '../utils/durations';
// import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';
Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip, CategoryScale);
// Interaction.modes.interpolate = Interpolate;

export default function BaseTimeseriesAPIChart({
   apiMethod,
   title,
   periodStart,
   periodEnd,
   prevPeriodStart,
   prevPeriodEnd,
   timeframe,
   currency,
   filter,
   periodSelected,
   comparedToSelected,
}) {
    const {auth, project} = useOutletContext();
    const navigate = useNavigate();

    const [rows, setRows] = useState(null);
    const [prevRows, setPrevRows] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // const [showPrev, setShowPrev] = useState(true);

    const formatNumber = (number) => {
        if (!currency) return number;
        if (typeof number === "undefined") return null;
        return numeral(number).format('$0.00a').toUpperCase();
        // TODO shorter version with "K" and "M" for large numbers
        return number.toLocaleString("en-EN", {style: "currency", currency: currency});
    }

    // periodStart, prevPeriodStart should be 00:00 (inclusive)
    // periodEnd, prevPeriodEnd should be 00:00 too (next day, not inclusive)
    // In UI, we display both sides inclusively. So Sep 1 - Sep 7 in UI means to Sep 1 00:00 <= and < Sep 8 00:00
    // All dates are unix timestamps with milliseconds

    if (!apiMethod) throw new Error("API method not set");

    const fetchData = useCallback(async controller => {
        let pps = prevPeriodStart;
        let ppe = prevPeriodEnd;
        let hidePrev = (periodSelected && periodSelected.title === 'All time' || comparedToSelected && comparedToSelected.title === 'No comparison') || false;

        try {
            setLoading(true);

            let params = {
                projectId: project.projectId,
                periodStart,
                periodEnd,
                timeframe,
                filter,
            };

            if (periodStart !== prevPeriodStart && periodEnd !== prevPeriodEnd) {
                params = {
                    ...params,
                    prevPeriodStart,
                    prevPeriodEnd,
                }
            }

            const response = await fetchAPI(apiMethod, auth.user.token, params, { signal: controller.signal });
            if (!response.ok) {
                throw new Error(`This is an HTTP error: The status is ${response.status}`);
            }
            let actualData = await response.json();
            // console.log('prevPeriodStart', moment(prevPeriodStart).format('Y-MM-DD'));
            // console.log('prevPeriodEnd', moment(prevPeriodEnd).format('Y-MM-DD'));
            // console.log('periodStart', moment(periodStart).format('Y-MM-DD'));
            // console.log('periodEnd', moment(periodEnd).format('Y-MM-DD'));
            if (timeframe === 'day') {
                setRows(buildDayValueTableWithMissingDays(actualData.rows, periodStart, periodEnd));
                if (!hidePrev) setPrevRows(buildDayValueTableWithMissingDays(actualData.prevRows, prevPeriodStart, prevPeriodEnd));
            } else if (timeframe === 'week') {
                // setRows(actualData.rows);
                setRows(buildWeekly(actualData.rows, periodStart, periodEnd));
                if (!hidePrev) setPrevRows(buildWeekly(actualData.prevRows, prevPeriodStart, prevPeriodEnd));
	            // setRows(actualData.rows);
	            // setPrevRows(actualData.prevRows);
            } else if (timeframe === 'month') {
                setRows(buildMonthly(actualData.rows, periodStart, periodEnd));
                if (!hidePrev) setPrevRows(buildMonthly(actualData.prevRows, prevPeriodStart, prevPeriodEnd));
            } else {
                setRows(actualData.rows);
                if (!hidePrev) setPrevRows(actualData.prevRows);
            }

            if (hidePrev) setPrevRows(null);

            setError(null);
        } catch (err) {
            if (err.name !== "AbortError") {
                setError(err.message);
            }
        } finally {
            setLoading(false);
        }
    });

    function buildDayValueTableWithMissingDays(data, periodStart, periodEnd) {
        const startDate = new Date(periodStart);
        const endDate = new Date(periodEnd);

        const start = moment(startDate);
        const end = moment(endDate).subtract(1, 'day');

        const allDates = [];
        const dataMap = new Map(data.map(obj => [moment(obj.day).format('Y-MM-DD'), obj.value]));

        for (let currentDate = start.clone(); currentDate.isSameOrBefore(end); currentDate.add(1, 'day')) {
            allDates.push(currentDate.format('YYYY-MM-DD'));
        }

        const results = allDates.map((day) => {
            const value = dataMap.get(day) || 0;
            return {
                day: day,
                value: value
            };
        });

        return results;
    }

    function buildWeekly(data, periodStart, periodEnd) {
        const startDate = new Date(periodStart);
        const endDate = new Date(periodEnd);

        const start = moment(startDate);
        const end = moment(endDate).endOf('week');

        const allWeeks = [];
        const dataMap = new Map(data.map(obj => [obj.day, obj.value]));

        for (let currentDate = start.clone(); currentDate.isSameOrBefore(end); currentDate.add(1, 'week')) {
            const week = currentDate.isoWeekYear() * 100 + currentDate.isoWeek();
            allWeeks.push(week);
        }

        return allWeeks.map((week) => {
            return {
                day: week,
                value: dataMap.get(week) || 0
            };
        });
    }

    function buildMonthly(data, periodStart, periodEnd) {
        const startDate = new Date(periodStart);
        const endDate = new Date(periodEnd);

        const start = moment(startDate);
        const end = moment(endDate).endOf('month');

        const allMonths = [];
        const dataMap = new Map(data.map(obj => [Number(obj.day), obj.value]));

        for (let currentDate = start.clone(); currentDate.isSameOrBefore(end); currentDate.add(1, 'month')) {
            const month = currentDate.format('YYYYMM');
            allMonths.push(Number(month));
        }

        return allMonths.map((month) => {
            return {
                day: month,
                value: dataMap.get(month) || 0
            };
        });
    }

    useEffect(() => {
        // console.log('periodStart', moment(periodStart).format('DD MMM YYYY'));
        // console.log('periodEnd', moment(periodEnd).format('DD MMM YYYY'));
        // console.log('prevPeriodStart', moment(prevPeriodStart).format('DD MMM YYYY'));
        // console.log('prevPeriodEnd', moment(prevPeriodEnd).format('DD MMM YYYY'));
        // console.log('prevPeriod ----------------------------');
        // console.log('periodEnd - periodStart', periodEnd - periodStart);
        // console.log('prevPeriodEnd - prevPeriodStart', prevPeriodEnd - prevPeriodStart);
        // if (prevPropPeriodStart === periodStart && prevPropPeriodEnd === periodEnd) return;
        // if ((prevPeriodStart && prevPeriodEnd) && (periodEnd - periodStart !== (prevPeriodEnd - prevPeriodStart))) return;

        const controller = new AbortController()

        fetchData(controller);

        return () => {
            controller.abort();
            setLoading(false);
        };
    }, [project, apiMethod, periodStart, periodEnd, prevPeriodStart, prevPeriodEnd, timeframe, filter]);

    const canvas = useRef(null);

    function convertToDate(data) {
        if (timeframe === 'week') {
            return data.map(item => {
                if (typeof item.day !== 'string' || !moment(moment(new Date(item.day)).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid()) {
                    const year = Math.floor(item.day / 100);
                    const week = item.day % 100;
                    const date = moment().year(year).isoWeek(week).day(1).format('YYYY-MM-DD');

                    return { ...item, day: date };
                }
                return item;
            });
        } else if (timeframe === 'month') {
            return data.map(item => {
                if (typeof item.day !== 'string' || !moment(moment(new Date(item.day)).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid()) {
                    const year = Math.floor(item.day / 100);
                    const month = item.day % 100;
                    const date = moment().year(year).month(month).toDate();

                    return { ...item, day: date };
                }
                return item;
            });
        }

        return data;
    }

    useEffect(() => {
        if (!rows) return;
        const ctx = canvas.current;
        // eslint-disable-next-line no-unused-vars

        const convertedRows = convertToDate(rows);
        const convertedPrevRows = prevRows ? convertToDate(prevRows) : [];

        let config = {
            type: 'line',
            data: {
                labels: convertedRows.map(item => item.day),
                datasets: [
                    {
                        data: convertedRows.map(row => row.value),
                        fill: true,
                        backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
                        borderColor: tailwindConfig().theme.colors.blue[500],
                        borderWidth: 2,
                        tension: 0,
                        pointRadius: 0,
                        pointHoverRadius: 3,
                        pointBackgroundColor: tailwindConfig().theme.colors.blue[500],
                        xAxisID: "x",
                        label: 'Period 1',
                        // type: "line"
                        // clip: 20,
                    }, {
                        data: convertedPrevRows.map(row => row.value),
                        // data: prevRows?.map(row => { return { x: row.day, y: row.value}}),
                        // fill: true,
                        // backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[500])}, 0.08)`,
                        borderColor: tailwindConfig().theme.colors.slate[300],
                        borderWidth: 2,
                        tension: 0,
                        pointRadius: 0,
                        pointHoverRadius: 3,
                        pointBackgroundColor: tailwindConfig().theme.colors.slate[300],
                        xAxisID: "xPrev",
                        label: 'Period 2',
                        // type: "line"
                        // clip: 20,
                    },
                ],
            },
            options: {
                animations: {
                    y: {
                        duration: 300,
                        easing: 'ease-out',
                    },
                    // tension: {
                    //     duration: 300,
                    //     easing: 'ease-out',
                    //     from: 0.5,
                    //     to: 0,
                    // },
                },
                layout: {
                    padding: {
                        top: 16,
                        bottom: 16,
                        left: 20,
                        right: 20,
                    },
                },
                scales: {
                    y: {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Total'
                        },
                        beginAtZero: true,
                        // suggestedMax: 3,
                        ticks: {
                            stepSize: 1,
                            maxTicksLimit: 5,
                            callback: function (value, index, values) {
                                return formatNumber(value);
                            }

                            // callback: function (value, index, values) {
                            //     // return value.toLocaleString("de-DE", {style: "currency", currency: "EUR"});
                            //     return parseInt(value);
                            //
                            // }
                        }
                    },
                    x: {
                        type: 'time',
                        grid: {
                            drawBorder: false,
                        },
                        time: {
                            parser: 'YYYY-MM-DD',
                            unit: 'day',
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Period 1'
                        },
                        // time: {
                        //     displayFormats: {
                        //         'day': 'MMM DD',
                        //         'week': 'MMM DD',
                        //         'month': 'MMM DD',
                        //         'quarter': 'MMM DD',
                        //         'year': 'MMM DD',
                        //     }
                        // },
                        ticks: {
                            maxTicksLimit: 0,
                            display: false,
                            callback: function (value, index, ticks) {
                                let label = this.getLabelForValue(value);
                                label = label.split("#")[0];
                                return label;
                            }
                        }
                    },
                    xPrev: {
                        type: 'time',
                        grid: {
                            drawBorder: false,
                        },
                        time: {
                            parser: 'YYYY-MM-DD',
                            unit: 'day',
                        },
                        ticks: {
                            maxTicksLimit: 0,
                            display: false,
                            callback: function (value, index, ticks) {
                                let label = this.getLabelForValue(value);
                                label = label.split("#")[1];
                                return label;
                            }
                        }
                    }
                },
                interaction: {
                    intersect: false,
                    mode: 'index',
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: (context) => { return null; },
                            label: function(context) {
                                // let label = context.dataset.label || '';
                                let day = (context.dataset.xAxisID === "x" ? rows : prevRows)[context.dataIndex].day;
                                let value = formatNumber(context.parsed.y);
                                let ps = context.dataset.xAxisID === "x" ? periodStart : prevPeriodStart;
                                let pe = context.dataset.xAxisID === "x" ? periodEnd : prevPeriodEnd;

                                let year = Math.floor(day / 100) || moment(day).year();
                                let weekMonth = day % 100;

                                let start = null;
                                let end = null;
                                let yearFormat = year !== moment().year() ? year : '';

                                if (timeframe === 'week') {
                                    start = moment().year(year).isoWeek(weekMonth).startOf('isoWeek').format('MMM D');
                                    end = moment().year(year).isoWeek(weekMonth).endOf('isoWeek').format('MMM D');

                                    // if (context.dataIndex === rows.length - 1) {
                                    //     if (context.dataset.xAxisID === "x") {
                                    //         end = moment(pe).subtract(1, 'day').format('MMM D');
                                    //     }
                                    // }

                                    return `${start} - ${end} ${yearFormat}: ${value}`;
                                } else if (timeframe === 'month') {
                                    start = moment().year(year).month(weekMonth).format('MMM');
                                    yearFormat = year;

                                    return `${start} ${yearFormat}: ${value}`;
                                }

                                let showYear = periodSelected ? periodSelected.title === 'All time' : false;
                                return `${moment(day).format(`MMM D ${showYear || yearFormat ? 'YYYY' : ''}`)}: ${value}`;
                            }

                        }
                    }

                },
                // hover: {
                //     mode: 'index',
                //     intersect: false,
                //     animationDuration: 0
                // },
                responsive: true,
                maintainAspectRatio: false,
            },
        }
        // console.log("config", config);
        const chart = new Chart(ctx, config);
        // const chart = new Chart(ctx, sampleConfig);
        return () => chart.destroy();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows, prevRows]);

    let totalValue, prevTotalValue, relativeChange, relativeChangeString;

    if (rows) {
        totalValue = rows.map(r => r.value).reduce((a, b) => a + b, 0);

        if (prevRows && prevRows.length) {
            prevTotalValue = prevRows.map(r => r.value).reduce((a, b) => a + b, 0);
            if ( prevTotalValue > 0 || prevTotalValue < 0 ) {
                relativeChange = (totalValue - prevTotalValue) / prevTotalValue;
            } else if ( prevTotalValue === 0 && totalValue === prevTotalValue ) {
                relativeChange = 0;
            } else if ( prevTotalValue === 0 ) {
                relativeChange = totalValue > 0 ? 1 : -1;
            }

            relativeChangeString = (relativeChange >= 0 ? '+' : '') + (relativeChange * 100).toFixed(1) + '%';
        }
    }

    return (
        <div
            className="flex flex-col col-span-full sm:col-span-6 xl:col-span-3 bg-white shadow-lg rounded-sm border border-slate-200">
            <div className="px-5 pt-5">
                <header>
                    <h3 className="text-base font-medium text-slate-500 mb-1">
                        <div className={`${isLoading ? 'text-slate-400' : 'text-slate-800'} transition ease-in`}>
                            {title && <span>{title}</span>}
                            {isLoading && <span className={"animate-pulse bg-slate-200 w-16 h-6 ml-2 inline-block align-bottom"}></span>}
                            {!isLoading && relativeChangeString && <span className={"ml-2 text-sm text-white px-1.5 py-1 rounded-full whitespace-nowrap " + (relativeChange >= 0 ? "bg-emerald-500" : "bg-amber-500")}>{relativeChangeString}</span>}
                        </div>
                    </h3>
                    {isLoading && (
                        <div className="flex flex-row justify-between">
                            <div className={"animate-pulse bg-slate-200 w-20 h-8 mb-1 inline-block"}></div>
                            <div className={"animate-pulse bg-slate-200 w-20 h-8 mb-1 inline-block"}></div>
                        </div>
                    )}
                    {!isLoading && (
                        <div className="flex flex-row justify-between">
                            <div className="text-2xl font-bold text-slate-800 mb-1">{formatNumber(totalValue)}</div>
                            <div className="text-2xl font-bold text-slate-400 mb-1">{formatNumber(prevTotalValue)}</div>
                        </div>
                    )}
                    {/*<div className="text-sm">*/}
                    {/*    <span className="font-medium text-red-500">{prevTotalValue} (4,7%)</span> - Today*/}
                    {/*</div>*/}
                </header>
            </div>
            {/* Chart built with Chart.js 3 */}
            <ChartLoadingPlaceholder chart={<canvas ref={canvas}></canvas>} isLoading={isLoading} />
            <div className={`flex flex-row px-5 text-xs pb-3 ${isLoading ? 'text-slate-400' : 'text-slate-800'} transition ease-in`}>
                <div>{moment(periodStart).format("MMM D")}</div>
                <div className={"flex-1"}></div>
                <div>{moment(periodEnd).subtract(1, 'day').format("MMM D")}</div>
            </div>
        </div>
    );
}
