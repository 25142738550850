import React from 'react';
import moment from "moment";
import BaseDynamicApiTable from "./BaseDynamicAPITable";

export default function SubscriptionsTable({ pageSize, filters, delayLoading }) {
    return (
        <BaseDynamicApiTable
            apiMethod="/project/subscriptions"
            filters={filters}
            pageSize={pageSize}
            delayLoading={delayLoading}
            dataModel={{
                columns: [
                    {
                        header: "Customer",
                        align: "left",
                        render: row => row.fullName
                    }, {
                        header: "Status",
                        align: "left",
                        render: row => row.isActive ? <span className="px-2 py-1 rounded-full text-xs text-green-600 bg-green-100">Active</span> : <span className="px-2 py-1 rounded-full text-xs text-slate-600 bg-slate-100">Cancelled</span>
                        // }, {
                        //     header: "Amount",
                        //     align: "right",
                        //     render: row => <span className="font-medium">{row.formattedPrice}</span>
                        // }, {
                        //     header: "",
                        //     align: "left",
                        //     render: row => <span className="text-slate-400">{row.currency}</span>
                    }, {
                        header: "Plan",
                        align: "left",
                        render: row => row.planName
                    }, {
                        header: "Method",
                        align: "left",
                        render: row => <span className="uppercase text-xs">{row.method}</span>
                    }, {
                        header: "Created",
                        align: "left",
                        render: row => moment(row.receivedAt).format("MMM D, LT")
                    }
                ]
            }}
            rowLinkTo={(row) => '/sales/subscription/' + row.subscriptionId}
            rowLinkState={(row) => row}
        />
    );

}