import React, {Component} from 'react';
import moment from "moment";
import Badge from "./Badge";

class IMInvoicesTable extends Component {
  render() {

    let rows = this.props.invoices.map((invoice, index) => {

      let badgeColors = {
        draft: "gray",
        open: "yellow",
        paid: "green",
        uncollectible: "red",
        void: "blue"
      };

      return <tr className="border-b" key={index}>
        <td className="text-left pl-4">{moment(invoice.createdAt).format("ll")}</td>
        <td><a className="hover:underline text-blue-700" href={invoice.url}>{invoice.number}</a></td>
        <td className="text-left py-4"><span className="">$</span>{invoice.amount}</td>
        {/*<td>{invoice.number}</td>*/}
        <td className="text-right pr-4"><Badge color={badgeColors[invoice.status]}>{invoice.status}</Badge></td>
      </tr>;
    }

    );

    return (
      <div className="bg-white ">
        <table className="w-full">
          <thead className="uppercase bg-gray-200 text-gray-600 text-sm">
          <tr className="hidden md:table-row">
            <th className="text-left p-4">Date</th>
            <th className="text-left">Number</th>
            <th className="text-left">Amount</th>
            <th className="text-right pr-4">Status</th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </table>
      </div>
    );
  }
}

export default IMInvoicesTable;