import {

  LOGIN_TELEGRAM_REQUEST,
  LOGIN_TELEGRAM_COMMIT,
  LOGIN_TELEGRAM_ROLLBACK,

  COMPLETE_SIGNUP_REQUEST,
  COMPLETE_SIGNUP_COMMIT,
  COMPLETE_SIGNUP_ROLLBACK

} from "../actions/index";

export function auth (state = {}, action) {
  switch (action.type) {

    case LOGIN_TELEGRAM_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        isLoginFailed: false
      };
    case LOGIN_TELEGRAM_COMMIT:
      return {
        ...state,
        isLoggingIn: false,
        isLoginFailed: false,
        user: action.payload,
      };
    case LOGIN_TELEGRAM_ROLLBACK:
      return {
        ...state,
        isLoggingIn: false,
        isLoginFailed: true
      };

    case COMPLETE_SIGNUP_REQUEST:
      return {
        ...state,
        isSigningUp: true,
        isSignUpFailed: false
      };
    case COMPLETE_SIGNUP_COMMIT:
      return {
        ...state,
        isSigningUp: false,
        isSignUpFailed: false,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    case COMPLETE_SIGNUP_ROLLBACK:
      return {
        ...state,
        isSigningUp: false,
        isSignUpFailed: true
      };
    default:
      return state
  }
}

