import React, { Component } from 'react';
import { connect } from 'react-redux'

import DashboardLayout from "../Layouts/DashboardLayout";
import DashboardBlockWithTitle from "../Bits/DashboardBlockWithTitle";

class HomePage extends Component {
  render() {
    return (
      <DashboardLayout auth={this.props.auth}>
        <DashboardBlockWithTitle title={"Home"}>
        </DashboardBlockWithTitle>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
