import React, { useCallback, useEffect, useState } from 'react';

import {fetchAPI} from "../../api";
import {NavLink, useNavigate, useOutletContext} from "react-router-dom";
import moment from "moment";

export default function BaseDynamicApiTable({ apiMethod, dataModel, handleRowClick, pageSize, filters, delayLoading, rowLinkTo, rowLinkState }) {

    const {auth, project} = useOutletContext();
    const navigate = useNavigate();

    const [rows, setRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [count, setCount] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    if (!apiMethod) throw new Error("API method not set");
    if (!dataModel) throw new Error("Data model not set");

    const fetchData = useCallback(async controller => {
        try {
            let filtersMode = undefined;
            let filtersParam = undefined;

            setLoading(true);

            if (filters) {
                const data = JSON.parse(filters);

                if (data.filters) {
                    filtersParam = data.filters;
                }
                if (data.filtersMode) {
                    filtersMode = data.filtersMode;
                }
            }

            const response = await fetchAPI(apiMethod, auth.user.token, {
                projectId: project.projectId,
                filters: filtersParam,
                filtersMode,
                limit: typeof pageSize === "undefined" ? 20 : pageSize,
                offset: pageNumber * pageSize
            }, { signal: controller.signal });
            if (!response.ok) {
                throw new Error(`This is an HTTP error: The status is ${response.status}`);
            }
            let actualData = await response.json();
            setRows(actualData.rows);
            setCount(actualData.count);
            setError(null);
        } catch (err) {
            if (err.name !== "AbortError") {
                setError(err.message);
            }
        } finally {
            if (!controller.signal.aborted) {
                setLoading(false);
            }
        }
    });

    useEffect(() => {
        if (delayLoading) return;
        const controller = new AbortController();
        fetchData(controller);

        return () => {
            controller.abort();
        };
    }, [pageNumber, filters, delayLoading])

    const renderHeader = () => {
        return (
            <tr className="table-row">
                {dataModel.columns.map((column, i) =>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap" key={i}>
                        <div className={"font-semibold text-" + column.align}>{column.header}</div>
                    </th>)
                }
            </tr>
        );
    }

    const renderRow = (row, key) => {
        return (
            <tr className={"table-row " + (rowLinkTo ? "cursor-pointer" : "")} key={key} onClick={(event) => {
                if (rowLinkTo) {
                    navigate(rowLinkTo(row), { state: rowLinkState ? rowLinkState(row) : null })
                }
            }}>
                {dataModel.columns.map((column, i) =>
                    <td className={"px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"} key={i}>
                        <div className={"text-" + column.align}>{column.render(row)}</div>
                    </td>)
                }
            </tr>
        );
    }

    const renderPlaceholderRow = (key) => {
        return (
            <tr className="" key={key}>
                {dataModel.columns.map((column, i) =>
                    <td className={"px-2 first:pl-5 last:pr-5 py-3 text-" + column.align} key={i}>
                        <div className={"animate-pulse bg-slate-200 w-1/2 h-2 inline-block"}></div>
                    </td>)
                }
            </tr>
        )
    }


    let rowElements;

    if (isLoading) {
        rowElements = [];
        let renderCount = pageSize;
        if (count > 0 && (pageNumber + 1) * pageSize > count) {
            renderCount = count - pageNumber * pageSize;
        }
        for (let i = 0; i < renderCount; i++) {
            rowElements.push(renderPlaceholderRow(i));
        }
    } else {
        rowElements = rows.map((row, i) => renderRow(row, i));
    }

    let totalPages = Math.ceil(count / pageSize);

    let isPrevDisabled = pageNumber === 0;
    let isNextDisabled = pageNumber === totalPages - 1;

    return (
        <div>
            {(isLoading || count > 0) &&
            <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative">
                <div>
                    <div className="overflow-x-auto">

                        <table className="table-auto w-full">
                            <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
                            {renderHeader()}
                            </thead>
                            <tbody className={"text-sm divide-y divide-slate-200"}>
                            {rowElements}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            }

            {!isLoading && count === 0 &&
            <div className="text-slate-500 text-center sm:text-left">
                No records.
            </div>}

            {!isLoading && error &&
                <div className="text-slate-500 text-center sm:text-left">
                    {error}
                </div>}

            {!isLoading && count > 0 &&
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mt-8">
                {(!isLoading && count > pageSize) &&
                    <nav className="mb-4 sm:mb-0 sm:order-1" role="navigation" aria-label="Navigation">
                        <ul className="flex justify-center">
                            <li className="ml-3 first:ml-0">
                                <a className={"btn bg-white border-slate-200 " + (isPrevDisabled ? "text-slate-300 cursor-not-allowed" : "hover:border-slate-300 text-indigo-500 cursor-pointer")}
                                   disabled={isPrevDisabled}
                                   onClick={() => (pageNumber > 0 ? setPageNumber(pageNumber - 1) : {})}>Prev</a>
                            </li>
                            <li className="ml-3 first:ml-0">
                                <a className={"btn bg-white border-slate-200 " + (isNextDisabled ? "text-slate-300 cursor-not-allowed" : "hover:border-slate-300 text-indigo-500 cursor-pointer")}
                                   disabled={isNextDisabled}
                                   onClick={() => (pageNumber < totalPages - 1 ? setPageNumber(pageNumber + 1) : {})}>Next</a>
                            </li>
                        </ul>
                    </nav>
                }
                <div className="text-sm text-slate-500 text-center sm:text-left">
                    Showing <span
                    className="font-medium text-slate-600">{pageNumber * pageSize + 1}</span> to <span
                    className="font-medium text-slate-600">{Math.min(count, (pageNumber + 1) * pageSize)}</span> of <span
                    className="font-medium text-slate-600">{count}</span> results
                </div>
            </div>
            }
        </div>
    );
}
