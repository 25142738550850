import React, {Component} from 'react';
import Badge from "./Badge";

class PaymentMethod extends Component {
  render() {

    let method = this.props.method;

    if (!method) return null;

    let methodIcon = "", methodName = "";

    if (method.toLowerCase() === "stripe") {
      methodIcon = "💳";
      methodName = "Stripe";
    } else if (method.toLowerCase() === "stripe.multibanco") {
      methodIcon = "🏧";
      methodName = "Multibanco";
    } else if (method.toLowerCase() === "stripe.ideal") {
      methodIcon = "🏦";
      methodName = "iDEAL";
    } else if (method.toLowerCase() === "paypal") {
      methodIcon = "🅿";
      methodName = "PayPal";
    } else if (method.toLowerCase() === "razorpay") {
      methodIcon = "🇮🇳";
      methodName = "Razorpay";
    } else if (method.toLowerCase() === "coinpayments") {
      methodIcon = "⚡";
      methodName = "CoinPayments";
    } else if (method.toLowerCase() === "free trial") {
      methodIcon = "🆓";
      methodName = "Free trial";
    } else if (method.toLowerCase().startsWith("access code")) {
      methodIcon = "🔑";
      methodName = "Access Code";
    } else if (method.toLowerCase() === "skrill") {
      methodIcon = "🌐";
      methodName = "Skrill";
    } else if (method.toLowerCase() === "paystack") {
      methodIcon = "🇳🇬🇬🇭🇿🇦";
      methodName = "Paystack";
    }

    return (
      <div className={"inline-block " + (this.props.className ? this.props.className : "") }>
        { this.props.showIcon && <div className="inline-block w-8 text-center">{methodIcon}</div> }
        <div className="inline-block">{methodName}</div>
        { (this.props.showMode && !this.props.liveMode) && <Badge color={"yellow"} className={"ml-2"}>test</Badge>}
      </div>
    );
  }
}

export default PaymentMethod;