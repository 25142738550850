import React from 'react';

export default function DetailsTable ({ details, isLoading }) {
  let detailsRows = details.map((details, i) => {
    let valueElem = <div className="">{details.none}</div>;

    if (typeof details.value !== "undefined" && details.value !== null) {
      valueElem = <div className="">{details.value}</div>;
    } else if (isLoading) {
      valueElem = <div className={"animate-pulse bg-slate-200 w-32 h-3 inline-block"}></div>
    }
    return (
      <div key={i} className="text-sm">
        <h3 className="font-medium text-slate-800">{details.label}</h3>
        {valueElem}
      </div>
    )
  });

  return (
    <div className="xl:min-w-56 xl:w-56 space-y-3">
      {detailsRows}
    </div>
  );
}
