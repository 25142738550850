import React from 'react';
import StaticLayout from '../Layouts/StaticLayout';
import { NavLink } from 'react-router-dom';

export default function StripeSuccess() {
    return (
        <StaticLayout size={'big'} layoutCenter layoutFixed>
            <div className="max-w-[600px] mx-auto block flex-grow flex-shrink w-full p-3 text-center">
                <h1 className="text-6xl font-semibold text-[#363636] mt-2 mb-6 font-founders leading-none">Stripe connection successful!</h1>
                <p>Now you can accept subscription payments with Stripe. Please return to the bot to complete the installation.</p>
                <p>&nbsp;</p>
                <NavLink to="https://t.me/InviteMemberBot" className="inline-block min-w-[190px] min-h-[40px] text-brand-green font-bold pt-[7px] pb-[9px] px-4 rounded-3xl bg-brand-yellow hover:bg-brand-green hover:text-brand-yellow transition font-founders text-2xl font-normal leading-none tracking-wide">Return to the bot</NavLink>
            </div>
        </StaticLayout>
    );
}

