import React, { useEffect, useState, useCallback } from 'react';
import { NavLink, useLocation, useMatch, useOutletContext } from "react-router-dom";
import moment from "moment";

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import { fetchAPI } from "../api";
import DetailsTable from "../components/DetailsTable";
import PaymentsTable from "../components/Tables/PaymentsTable";
import SubscriptionsTable from "../components/Tables/SubscriptionsTable";
import RecurringPaymentsTable from "../components/Tables/RecurringPaymentsTable";
import { getUserStatus } from '../utils/Utils';

function User(props) {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [user, setUser] = useState(location.state);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const {auth, project} = useOutletContext();
  let match = useMatch("/user/:userId");
  let userId = match.params.userId;

  const fetchData = useCallback(async controller => {
    try {
      setLoading(true);
      const response = await fetchAPI(
          "/project/user",
          auth.user.token,
          {
            projectId: project.projectId,
            userId: userId
          },
          { signal: controller.signal },
      );
      if (!response.ok) {
        throw new Error(`This is an HTTP error: The status is ${response.status}`);
      }
      let actualData = await response.json();
      setUser(actualData);
      setError(null);
    } catch (err) {
      if (err.name !== "AbortError") {
        setError(err.message);
      }
    } finally {
      if (!controller.signal.aborted) {
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    const controller = new AbortController()

    fetchData(controller);

    return () => {
      controller.abort();
    };
  }, [match]);

  let fullName = user?.firstName;
  if (user?.lastName) fullName += " " + user?.lastName;

  let details = [];
  details.push({ label: "Registered", value: !user ? null : moment(user?.createdAt).format("MMM D, LT"), none: "Unknown" });
  details.push({ label: "Language", value: user?.languageCode, none: "—" });
  details.push({ label: "Email", value: user?.email, none: "—" });
  details.push({ label: "Telegram ID", value: user?.telegramUserId, none: "—" });
  if (user?.teamRoles) {
    details.push({ label: "Team roles", value: user?.teamRoles?.join(", "), none: "—" });
  }

  if (user?.affiliateId) {
    details.push({ label: "Affiliate ID", value: user?.affiliateId, none: "—" });
  }
  // if (user?.isBanned) {
  // }

  if (user?.referredByUserId && user?.linkAffiliateId) {
    details.push({ label: "Referred by", value: <NavLink to={"/user/" + user?.referredByUserId} className="text-blue-700 hover:underline">Affiliate #{user.linkAffiliateId}</NavLink>, none: "—" });
  }

  return (
    <div className="flex h-screen overflow-hidden bg-white">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} project={project} user={auth?.user} />

        <main>

          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">

              <div>
                <div className="mb-4 sm:mb-0">
                  {/*<div className="flex items-center mb-1">*/}
                  {/*  /!*<svg className="w-4 h-4 fill-current text-slate-400 shrink-0 mr-2" viewBox="0 0 16 16">*!/*/}
                  {/*  /!*  <path d="M11.4 0L10 1.4l2 2H8.4c-2.8 0-5 2.2-5 5V12l-2-2L0 11.4l3.7 3.7c.2.2.4.3.7.3.3 0 .5-.1.7-.3l3.7-3.7L7.4 10l-2 2V8.4c0-1.7 1.3-3 3-3H12l-2 2 1.4 1.4 3.7-3.7c.4-.4.4-1 0-1.4L11.4 0z" />*!/*/}
                  {/*  /!*</svg>*!/*/}
                  {/*  <h2 className="text-slate-500">User</h2>*/}
                  {/*</div>*/}

                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold flex items-center space-x-2">
                    { !user && <div className={"animate-pulse bg-slate-200 w-32 h-10 inline-block"}></div> }
                    { !user && <div className={"animate-pulse bg-slate-200 w-32 h-10 inline-block"}></div> }
                    { user && <div>{fullName}</div> }
                  </h1>

                  <div className="mt-2 flex flex-row space-x-2">
                    {getUserStatus({ user, project }, 'badges')}
                  </div>

                </div>

              </div>

            </div>

            <div className="mt-8">
              <h2 className="text-xl leading-snug text-slate-800 font-bold mb-5">User details</h2>

              <DetailsTable details={details} isLoading={isLoading} />

            </div>


            <div className="mt-8">
              <h2 className="text-xl leading-snug text-slate-800 font-bold mb-5">Subscriptions</h2>
              <SubscriptionsTable pageSize={5} filters={JSON.stringify({
                filters: [
                  { key: "userId", condition: { eq: userId } }
                ]
              })} />
            </div>

            <div className="mt-8">
              <h2 className="text-xl leading-snug text-slate-800 font-bold mb-5">Recurring payments</h2>
              <RecurringPaymentsTable pageSize={5} filters={JSON.stringify({
                filters: [
                  { key: "userId", condition: { eq: userId } }
                ]
              })} />
            </div>

            <div className="mt-8">
              <h2 className="text-xl leading-snug text-slate-800 font-bold mb-5">One-time payments</h2>
              <PaymentsTable pageSize={5} filters={JSON.stringify({
                filters: [
                  { key: "userId", condition: { eq: userId } }
                ]
              })} />
            </div>


            {/*<div className="mt-8">*/}
            {/*  <h2 className="text-xl leading-snug text-slate-800 font-bold mb-5">Raw data</h2>*/}
            {/*  <pre className="text-xs">*/}
            {/*        { JSON.stringify(payment, null, 4)}*/}
            {/*        </pre>*/}
            {/*</div>*/}

          </div>
        </main>

      </div>

    </div>
  );
}

export default User;
