import {
  FETCH_PROJECT_COMMIT,
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_ROLLBACK,
  FETCH_MY_PROJECTS_COMMIT,
  FETCH_MY_PROJECTS_REQUEST,
  FETCH_MY_PROJECTS_ROLLBACK,
  CLEAR_PROJECT_USERS,
  FETCH_PROJECT_USERS_COMMIT,
  FETCH_PROJECT_USERS_REQUEST,
  FETCH_PROJECT_USERS_ROLLBACK,
  FETCH_PROJECT_PAYMENTS_COMMIT,
  FETCH_PROJECT_PAYMENTS_REQUEST,
  FETCH_PROJECT_PAYMENTS_ROLLBACK,
  FETCH_PROJECT_SUBSCRIPTIONS_COMMIT,
  FETCH_PROJECT_SUBSCRIPTIONS_REQUEST,
  FETCH_PROJECT_SUBSCRIPTIONS_ROLLBACK,
  FETCH_PROJECT_PLANS_COMMIT,
  FETCH_PROJECT_PLANS_REQUEST,
  FETCH_PROJECT_PLANS_ROLLBACK,
  FETCH_PROJECT_RESOURCES_COMMIT,
  FETCH_PROJECT_RESOURCES_REQUEST,
  FETCH_PROJECT_RESOURCES_ROLLBACK,
  SET_CURRENT_PROJECT,
  SET_CURRENT_PROJECT_ID, SET_PROJECT_USERS_PAGINATION_FILTER
} from "../actions/index";

export function project(state = {
  myIds: {
    isFetching: false,
    isFailed: false,
    list: []
  },
  currentId: null,
  byId: {},
  usersById: {},
  paymentsById: {},
  subscriptionsById: {},
  resourcesById: {},
  plansById: {}
}, action) {
  console.log(action.type, action);
  switch (action.type) {
    case FETCH_PROJECT_COMMIT:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.project.projectId]: action.payload.project
        }
      };
    case FETCH_MY_PROJECTS_REQUEST:
      return {
        ...state,
        myIds: {
          ...state.myIds,
          isFetching: true,
          isFailed: false,
        },
      };
    case FETCH_MY_PROJECTS_COMMIT:
      let byId = {
        ...state.byId
      };
      for (let i = 0; i < action.payload.myProjects.length; i++) {
        let project = action.payload.myProjects[i];
        byId[project.projectId] = project;
      }
      return {
        ...state,
        myIds: {
          isFetching: false,
          isFailed: false,
          list: action.payload.myIds
        },
        byId: byId
      };
    case FETCH_MY_PROJECTS_ROLLBACK:
      return {
        ...state,
        myIds: {
          ...state.myIds,
          isFetching: false,
          isFailed: true
        },
      };

    case SET_CURRENT_PROJECT_ID:
      return {
        ...state,
        currentId: action.projectId,
      };

    case SET_CURRENT_PROJECT:
      return {
        ...state,
        currentId: action.projectId,
        byId: {
          ...state.byId,
          [action.projectId]: action.project
        }
      };

    case CLEAR_PROJECT_USERS:
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [action.meta.projectId]: {
            ...state.usersById[action.meta.projectId],
            isFetching: true,
            isFailed: false,
            byId: {},
            count: 0
          }
        }
      };

    case SET_PROJECT_USERS_PAGINATION_FILTER:
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [action.meta.projectId]: {
            ...state.usersById[action.meta.projectId],
            pageSize: action.meta.pageSize,
            pageNumber: action.meta.pageNumber
          }
        }
      };

    case FETCH_PROJECT_USERS_REQUEST:
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [action.meta.projectId]: {
            ...state.usersById[action.meta.projectId],
            isFetching: true,
            isFailed: false,
            // byId: {},
            // count: 0
          }
        }
      };
    case FETCH_PROJECT_USERS_COMMIT:

      let newById = {
        ...state.usersById[action.meta.projectId].byId,
        ...action.payload.byId
      };

      let sortedByCreatedAt = Object.keys(newById).sort((userId1, userId2) => newById[userId1].createdAt - newById[userId2].createdAt);
      let sortedByUpdatedAt = Object.keys(newById).sort((userId1, userId2) => newById[userId1].updatedAt - newById[userId2].updatedAt);

      return {
        ...state,
        usersById: {
          ...state.usersById,
          [action.meta.projectId]: {
            ...state.usersById[action.meta.projectId],
            isFetching: false,
            isFailed: false,
            byId: newById,
            sortedByCreatedAt: sortedByCreatedAt,
            sortedByUpdatedAt: sortedByUpdatedAt,
            totalCount: action.payload.count,
            loadedCount: Object.keys(newById).length,
            lastKey: action.payload.lastKey
          }
        }
      };
    case FETCH_PROJECT_USERS_ROLLBACK:
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [action.meta.projectId]: {
            ...state.usersById[action.meta.projectId],
            isFetching: false,
            isFailed: true,
          }
        }
      };

    case FETCH_PROJECT_PAYMENTS_REQUEST:
      return {
        ...state,
        paymentsById: {
          ...state.paymentsById,
          [action.meta.projectId]: {
            ...state.paymentsById[action.meta.projectId],
            isFetching: true,
            isFailed: false
          }
        }
      };
    case FETCH_PROJECT_PAYMENTS_COMMIT:

      return {
        ...state,
        paymentsById: {
          ...state.paymentsById,
          [action.meta.projectId]: {
            ...state.paymentsById[action.meta.projectId],
            isFetching: false,
            isFailed: false,
            byId: action.payload.byId
          }
        }
      };
    case FETCH_PROJECT_PAYMENTS_ROLLBACK:
      return {
        ...state,
        paymentsById: {
          ...state.paymentsById,
          [action.meta.projectId]: {
            ...state.paymentsById[action.meta.projectId],
            isFetching: false,
            isFailed: true,
          }
        }
      };
    case FETCH_PROJECT_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        subscriptionsById: {
          ...state.subscriptionsById,
          [action.meta.projectId]: {
            ...state.subscriptionsById[action.meta.projectId],
            isFetching: true,
            isFailed: false,
          }
        }
      };
    case FETCH_PROJECT_SUBSCRIPTIONS_COMMIT:

      return {
        ...state,
        subscriptionsById: {
          ...state.subscriptionsById,
          [action.meta.projectId]: {
            ...state.subscriptionsById[action.meta.projectId],
            isFetching: false,
            isFailed: false,
            byId: action.payload.byId
          }
        }
      };
    case FETCH_PROJECT_SUBSCRIPTIONS_ROLLBACK:
      return {
        ...state,
        subscriptionsById: {
          ...state.subscriptionsById,
          [action.meta.projectId]: {
            ...state.subscriptionsById[action.meta.projectId],
            isFetching: false,
            isFailed: true
          }
        }
      };

    case FETCH_PROJECT_PLANS_REQUEST:
      return {
        ...state,
        plansById: {
          ...state.plansById,
          [action.meta.projectId]: {
            ...state.plansById[action.meta.projectId],
            isFetching: true,
            isFailed: false
          }
        }
      };
    case FETCH_PROJECT_PLANS_COMMIT:
      return {
        ...state,
        plansById: {
          ...state.plansById,
          [action.meta.projectId]: {
            isFetching: false,
            isFailed: false,
            byId: action.payload.byId
          }
        }
      };
    case FETCH_PROJECT_PLANS_ROLLBACK:
      return {
        ...state,
        plansById: {
          ...state.plansById,
          [action.meta.projectId]: {
            ...state.plansById[action.meta.projectId],
            isFetching: false,
            isFailed: true,
          }
        }
      };

    case FETCH_PROJECT_RESOURCES_REQUEST:
      return {
        ...state,
        resourcesById: {
          ...state.resourcesById,
          [action.meta.projectId]: {
            ...state.resourcesById[action.meta.projectId],
            isFetching: true,
            isFailed: false
          }
        }
      };
    case FETCH_PROJECT_RESOURCES_COMMIT:
      return {
        ...state,
        resourcesById: {
          ...state.resourcesById,
          [action.meta.projectId]: {
            isFetching: false,
            isFailed: false,
            byId: action.payload.byId
          }
        }
      };
    case FETCH_PROJECT_RESOURCES_ROLLBACK:
      return {
        ...state,
        resourcesById: {
          ...state.resourcesById,
          [action.meta.projectId]: {
            ...state.resourcesById[action.meta.projectId],
            isFetching: false,
            isFailed: true,
          }
        }
      };

    default:
      return state
  }
}

