import {
  SUBSCRIPTION_STATUS_REQUEST,
  SUBSCRIPTION_STATUS_COMMIT,
  SUBSCRIPTION_STATUS_ROLLBACK
} from "../actions/index";

export function billing(state = {
  isFetching: false,
  isFailed: false
}, action) {
  // console.log("in reducer", action);
  switch (action.type) {
    case SUBSCRIPTION_STATUS_REQUEST:
      return {
        ...state,
        isFetching: true,
        isFailed: false
      };
    case SUBSCRIPTION_STATUS_COMMIT:
      return {
        ...state,
        isFetching: false,
        isFailed: false,
        subscription: action.payload.subscription,
        invoices: action.payload.invoices,
        card: action.payload.card
      };
    case SUBSCRIPTION_STATUS_ROLLBACK:
      return {
        ...state,
        isFetching: false,
        isFailed: true
      };
    default:
      return state
  }
}

