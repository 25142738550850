import React, {Component} from 'react';

class DashboardBlockWithTitle extends Component {
  render() {
    return (
      <div className="dashboard-block">
        <div className="px-8 mb-2">
          <h1 className="text-xl">{this.props.title}</h1>
        </div>
        <hr className="border-t mb-5"/>
        <div className="px-8">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default DashboardBlockWithTitle;