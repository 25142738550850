import React, {Component} from 'react';
import { connect } from 'react-redux'

import { Formik, Field, Form, ErrorMessage } from 'formik';
import OnboardingLayout from "../Layouts/OnboardingLayout";
import {completeSignUp} from "../actions";

import {FormattedMessage} from 'react-intl';

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    auth: state.auth
  }
};

const mapDispatchToProps = {
  completeSignUp
};

class CompleteSignUpPage extends Component {
  constructor(props) {
    super(props);
    this.checkboxRef = React.createRef();
  }

  componentWillMount() {
    if (this.props.auth.user) {
      if (this.props.auth.user.onboardingCompleted) {
        this.props.history.push("/");
      } else if (this.props.auth.user.signupCompleted) {
        this.props.history.push("/onboarding");
      }
    } else {
      this.props.history.push("/login");
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.auth.onboardingCompleted && this.props.auth.user.onboardingCompleted) {
      this.props.history.push("/");
    } else if (!prevProps.auth.signupCompleted && this.props.auth.user.signupCompleted) {
      this.props.history.push("/onboarding");
    }
  }

  render() {
    return (
      <OnboardingLayout>
        <div className="block max-w-xs px-2">
          <h1 className="text-lg font-bold"><FormattedMessage id="auth.header_complete_signup" defaultMessage="Almost there..."/></h1>
          <p className="text-gray-700 text-sm mb-6"><FormattedMessage id="auth.complete_signup" defaultMessage="Please complete the form to access InviteMember Dashboard."/></p>
          <Formik

            initialValues={{
              email: "yury@invitemember.com",
              acceptedTerms: false
            }}

            validate={(values) => {
              let errors = {};

              if (!values.email) {
                errors.email = <FormattedMessage id="errors.email_required" defaultMessage="Email is required"/>;
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = <FormattedMessage id="errors.invalid_email" defaultMessage="Invalid email address"/>;
              }

              if (!values.acceptedTerms) {
                errors.acceptedTerms = <FormattedMessage id="auth.please_accept_terms" defaultMessage="Please accept Terms of Service and Privacy Policy"/>;
              }

              return errors;
            }}

            onSubmit={(values, actions) => {
              console.log("onSubmit", values, actions);

              this.props.completeSignUp(values, this.props.auth.user.token);
            }}
            render={({ errors, status, touched, values, isSubmitting, isValid }) => (
              <Form className="w-full">
                <div className="flex flex-wrap mb-3">
                  <div className="w-full">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                      <FormattedMessage id="auth.email" defaultMessage="Email"/>
                    </label>
                    <Field type="email" name="email" placeholder="name@example.com" className={"appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 " + (errors.email ? "border-red-500" : "") }/>
                    <ErrorMessage name="email" component="div" className="text-sm text-red-500 pt-3" />
                    {status && status.email && <div className="text-sm text-red-500 pt-3">{status.email}</div>}
                  </div>
                </div>
                <div className="mb-3">
                  <label className={"block text-gray-700 " + (touched.acceptedTerms && !values.acceptedTerms ? "shaked" : "")}>
                    <Field type="checkbox" name="acceptedTerms" className="mr-2 leading-tight" />
                    <span className="text-sm select-none">
                      <FormattedMessage id="auth.i_accept_terms" defaultMessage="I accept {tos_link} and {pp_link}."
                      values={{
                        tos_link: <a href="/terms" className="underline"><FormattedMessage id="auth.tos" defaultMessage="Terms of Service"/></a>,
                        pp_link: <a href="/privacy" className="underline"><FormattedMessage id="auth.pp" defaultMessage="Privacy Policy"/></a>
                      }}/>
                    </span>
                  </label>
                </div>
                {status && status.acceptedTerms && <div className="text-sm text-red-500 pb-3">{status.acceptedTerms}</div>}
                <div className="mb-6 mt-3">
                  <button type="submit" disabled={isSubmitting || !isValid} className={"shadow focus:shadow-outline focus:outline-none text-white py-2 px-4 rounded " + (isSubmitting || !isValid ? "bg-gray-500 hover:bg-gray-500 cursor-default" : "bg-gray-700 hover:bg-gray-800")}>

                    {!isSubmitting ?
                      <FormattedMessage id={"common.btn_submit"} defaultMessage={"Submit"} />
                      :
                      <FormattedMessage id={"common.btn_please_wait"} defaultMessage={"Please wait..."} />
                    }

                  </button>
                </div>
              </Form>
            )}
          />
          <div className="w-full">
            <a href="/logout" className="underline text-xs"><FormattedMessage id="auth.use_different_account" defaultMessage="Use a different Telegram account"/></a>
          </div>
        </div>
      </OnboardingLayout>
    );
  }

  // componentWillMount() {
    // if (!Auth.loggedIn()) {
    //   this.props.history.replace('/login');
    // }
  // }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompleteSignUpPage);
