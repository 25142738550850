import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class ButtonPrimary extends Component {

  constructor(props) {
    super(props);
    // this.handleClick = this.handleClick.bind(this);
  }

  // handleClick() {
  //   if (this.props.href) {
  //     this.props.history.push(this.props.href);
  //   }
  // }

  render() {

    let className = "text-white font-bold py-2 px-4 rounded "
      + (this.props.disabled ? "bg-blue-300 cursor-default " : "bg-blue-500 hover:bg-blue-700 ")
      + this.props.className;

    if (this.props.href) {
      return (
        <a href={this.props.href} onClick={this.props.onClick} className={className}>
          {this.props.children}
        </a>
      )
    } else {
      return (
        <button onClick={this.props.onClick} className={className}>
          {this.props.children}
        </button>
      );
    }


  }
}

export default ButtonPrimary;