import React, { useState } from 'react';

import ModalSearch from '../components/ModalSearch';
import Notifications from '../components/DropdownNotifications';
import Help from '../components/DropdownHelp';
import DropdownProfile from '../components/DropdownProfile';
import DropdownHelp from '../components/DropdownHelp';
import Search from '../components/Search'

function Header({
  sidebarOpen,
  setSidebarOpen,
  user,
  project
}) {
  return (
    <header className="sticky top-0 bg-white border-b border-b-warm-grey z-30 lg:py-2">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">

          {/* Header: Left side */}
          <div className="flex">

            {/* Hamburger button */}
            <button
              className="text-slate-500 hover:text-slate-600 lg:hidden mr-1 sm:mr-2"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <span className="sr-only">Open sidebar</span>
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>

          </div>

          {/* Header: Right side */}
          <div className="flex items-center">
            <DropdownHelp align="right" />
            <Search />
            {/*<Notifications align="right" />*/}
            {/*  Divider */}
            <hr className="w-px h-9 bg-warm-grey mx-3 sm:mx-4 relative -z-20" />
            <DropdownProfile align="right" user={user} project={project} />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
