import React, {useEffect, useState} from 'react';
import {useParams, Navigate} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import {logout} from "../actions";
import TelegramLoginButton from "../Bits/TelegramLoginButton";


function Logout(props) {
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth)

    useEffect(() => {
        dispatch(logout());
    })

    if (auth?.user) {
        return <div>Logging you out...</div>
    } else {
        return <Navigate to={"/login"} />
    }

}

export default Logout;