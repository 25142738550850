import React, {Component} from 'react';
import IMLogo from "../Bits/IMLogo";

class OnboardingLayout extends Component {
  render() {
    return (
      <div className="flex flex-col min-h-screen">
        <div className="flex flex-grow-0 pt-3 pl-3">
          <IMLogo/>
        </div>
        <div className="flex flex-grow justify-center items-center">
          {this.props.children}
        </div>
      </div>
    );
    // TODO add Intercom
  }
}

export default OnboardingLayout;