import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import IMLogoWhiteMinimal from "../Bits/IMLogoWhiteMinimal";
import BurgerIcon from "../Bits/BurgerIcon";
import {fetchProjectPayments} from "../actions";
import {connect} from "react-redux";
import {getProject} from "../selectors";

class DashboardLayout extends Component {

  constructor(props) {
    super(props);
    this.toggleSidenav = this.toggleSidenav.bind(this);
    this.showJustLoadedTimeout = false;
    this.state = {
      showJustLoaded: false
    };
  }

  toggleSidenav() {
    let target = document.getElementById('main-nav');
    target.classList.toggle('hidden');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.offline.busy && !this.props.offline.busy) {

      this.setState({ showJustLoaded: true });

      let that = this;

      if (this.showJustLoadedTimeout) clearTimeout(this.showJustLoadedTimeout);

      this.showJustLoadedTimeout = setTimeout(function() {
        console.log("Timeout");
        if (!that.props.offline.busy) {
          that.setState({ showJustLoaded: false });
        }
      }, 2000);
    }
  }

  componentWillUnmount() {
    if (this.showJustLoadedTimeout) clearTimeout(this.showJustLoadedTimeout);
    this.setState({ showJustLoaded: true });
  }

  render() {

    let navLinkDefaultClass = `flex px-6 py-4 items-center`;
    let navLinkActiveClass = `font-semibold bg-primary-700 `;

    let sectionHeaderClassName = `font-bold uppercase text-white pt-8 pb-4 px-5 text-xs`;

    let offline = this.props.offline;


    return (
      <div className=" md:h-screen md:flex min-h-screen bg-primary-900">

        <div className="flex items-center md:hidden bg-primary-600 text-white">

          <div className="w-full p-6">
            {this.props.project.name}
            {/*<IMLogoWhiteMinimal />*/}
          </div>

          {/*<div className="w-full"></div>*/}

          {/*{ offline.busy && <div className="inline-block">🔄</div> }*/}
          {/*{ !offline.busy && this.state.showJustLoaded && <div className="inline-block">✅</div> }*/}

          <button ref="btn" className="flex items-center w-auto" onClick={this.toggleSidenav}>
            <BurgerIcon/>
          </button>

        </div>

        <div id="main-nav"
             className={`absolute md:relative flex-none w-full hidden bg-primary-600 text-white md:max-w-xs md:block box-border md:pt-0`}>

          <div className={`hidden md:flex bg-primary-900 text-white py-3 pr-3 md:items-center md:border-gray-300`}>
            <div className="font-bold text-lg flex-1 py-5 ml-7">
              {/*<div className="uppercase text-gray-600 text-xs">Membership project</div>*/}
              <div>{this.props.project.name}</div>
            </div>
          </div>

          {/*<p className={sectionHeaderClassName}>Project</p>*/}

          <NavLink to={`/`} exact activeClassName={navLinkActiveClass} onClick={this.toggleSidenav} className={navLinkDefaultClass}>
            Dashboard
          </NavLink>

          {/*<NavLink to={`/resources`} activeClassName={navLinkActiveClass} onClick={this.toggleSidenav} className={navLinkDefaultClass}>*/}
          {/*  Resources*/}
          {/*</NavLink>*/}

          {/*<NavLink to={`/plans`} activeClassName={navLinkActiveClass} onClick={this.toggleSidenav} className={navLinkDefaultClass}>*/}
          {/*  Plans*/}
          {/*</NavLink>*/}

          {/*<p className={sectionHeaderClassName}>Reports</p>*/}

          {/*<NavLink to={`/metrics`} activeClassName={navLinkActiveClass} onClick={this.toggleSidenav} className={navLinkDefaultClass}>*/}
            {/*Home*/}
          {/*</NavLink>*/}

          <NavLink to={`/users`} activeClassName={navLinkActiveClass} onClick={this.toggleSidenav} className={navLinkDefaultClass}>
            Users
          </NavLink>

          <NavLink to={`/payments`} activeClassName={navLinkActiveClass} onClick={this.toggleSidenav} className={navLinkDefaultClass}>
            Payments
          </NavLink>

          <NavLink to={`/subscriptions`} activeClassName={navLinkActiveClass} onClick={this.toggleSidenav} className={navLinkDefaultClass}>
            Subscriptions
          </NavLink>


          {/*<NavLink to={`/payments`} activeClassName{navLinkActiveClass} onClick={this.toggleSidenav} className={navLinkDefaultClass}>*/}
            {/*Payments*/}
          {/*</NavLink>*/}


          {/*<div className="mb-10"></div>*/}

          {/*<NavLink to={`/ui`} activeClassName={navLinkActiveClass} onClick={this.toggleSidenav} className={navLinkDefaultClass}>UI Kit*/}
          {/*</NavLink>*/}

          {/*<div className="border-b-2 border-gray-500"></div>*/}

          {/*<p className={sectionHeaderClassName}>Account</p>*/}

          {/*<NavLink to={`/billing`} activeClassName={navLinkActiveClass} onClick={this.toggleSidenav} className={navLinkDefaultClass}>*/}
          {/*  Billing*/}
          {/*</NavLink>*/}

          <NavLink to={`/select_project`} className={navLinkDefaultClass}>
            Switch project...
          </NavLink>

          <NavLink to={`/logout`} activeClassName={navLinkActiveClass} className={navLinkDefaultClass}>
            Logout
          </NavLink>

          <div className="flex-1"></div>

          {/*<div className="text-white md:text-gray-600 text-xs pt-5 pb-5 px-6 ">*/}
            {/*<div className="inline-block mr-2">*/}
            {/*  Status: { offline.online ? <div className="inline-block">🟢online</div> : <div className="inline-block">🔴offline</div> }.*/}
            {/*</div>*/}

            {/*<div className="inline-block">/!**!/*/}
            {/*  { offline.busy && <div className="inline-block">🔄Sync in progress...</div> }*/}
              {/*{ !offline.busy && this.state.showJustLoaded && <div className="inline-block">✅Up-to-date.</div> }*/}
              {/*{ !offline.online && offline.outbox && offline.outbox.length > 0 && <div className="inline-block">⚠️ Data might be outdated.</div> }*/}
            {/*</div>*/}
          {/*</div>*/}


        </div>

        <div className="md:flex-1 md:flex md:overflow-hidden bg-gray-100">

          <div className="md:flex-1 md:overflow-y-scroll">

            {this.props.children}

          </div>

        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    offline: state.offline,
    project: getProject(state)
  }
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
