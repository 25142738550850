import React, {Component} from 'react';

class SubscriptionStatus extends Component {

  formatDate(ts) {
    let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let d = new Date(ts);
    return d.getDay() + " " + monthNames[d.getMonth()] + " " + d.getFullYear();
  }

  render() {
    if (this.props.subscription) {
      return (
        <div className="">
          <div className="flex text-2xl items-center">{this.props.subscription.planNickname} <span className="text-xs ml-3 font-bold bg-green-300 text-green-800 uppercase rounded p-1">{this.props.subscription.status}</span></div>
          <div className="flex max-w-lg mt-2">
            <div className="w-1/3">
              <div className="text-xs pt-2 font-bold text-gray-700 uppercase block">Billing</div>
              <div className="text-lg">${(this.props.subscription.planAmount / 100)}/{this.props.subscription.planInterval}</div>
            </div>
            {/*<div className="w-1/3">*/}
              {/*<div className="text-xs pt-2 font-bold text-gray-700 uppercase block">Your card</div>*/}
              {/*<div className="text-lg"></div>*/}
            {/*</div>*/}
            <div className="w-1/3 pl-2">
              <div className="text-xs pt-2 font-bold text-gray-700 uppercase block">Next payment</div>
              <div className="text-lg">{this.formatDate(this.props.subscription.currentPeriodEnd)}</div>
            </div>
          </div>
          <div className="flex max-w-lg items-center mt-3">
            {/*<button className="w-1/3 mr-2 btn-primary" type="button">Change plan</button>*/}
            {/*<button className="w-1/3 mx-2 btn-secondary">Update billing info</button>*/}
            {/*<button className="w-1/3 ml-2 btn-tertiary">Cancel subscription</button>*/}
          </div>
        </div>
      );
    } else {
      return (
        <div></div>
      );
    }

  }
}

export default SubscriptionStatus;

// cancelAtPeriodEnd: false
// canceledAt: 0
// created: 1554149420
// currentPeriodEnd: 1560024620000
// planAmount: 4900
// planInterval: "month"
// planNickname: "Premium, monthly"
// status: "active"
// trialEnd: 1554754220000