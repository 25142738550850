import React, { Component } from 'react';
import { connect } from 'react-redux'
import { fetchSubscriptionStatus} from "../actions";

import DashboardLayout from "../Layouts/DashboardLayout";
import SubscriptionStatus from "../Bits/SubscriptionStatus";
import DashboardBlockWithTitle from "../Bits/DashboardBlockWithTitle";
import IMPlanCard from "../OLDComponents/IMPlanCard";
import ButtonSecondary from "../OLDComponents/ButtonSecondary";
import ButtonTertiary from "../OLDComponents/ButtonTertiary";
import ButtonPrimary from "../OLDComponents/ButtonPrimary";
import IMInvoicesTable from "../OLDComponents/IMInvoicesTable";

class BillingPage extends Component {
  // constructor() {
  //   super();
  // }

  componentDidMount() {
    this.props.fetchSubscriptionStatus(this.props.auth.user.token);
  }

  render() {

    let startFreeTrialSection = "";
    if (this.props.billing && !this.props.billing.subscription) {
      startFreeTrialSection =
        <div>
          <p className={"mt-10 mb-5 ml-5"}>Join InviteMember to unlock Live payment integrations and Access Codes.</p>
          <ButtonPrimary href={"https://invitemember.com/join"} className={"ml-5"}>Start Free 7-Day Trial</ButtonPrimary>
        </div>
    }

    let startNewSubscriptionSection = "";
    if (this.props.billing && this.props.billing.subscription && this.props.billing.subscription.status !== "canceled") {
      startNewSubscriptionSection =
        <div>
          <p className={"mt-10 mb-5 ml-5"}>Join InviteMember to unlock Live payment integrations and Access Codes.</p>
          <ButtonPrimary href={"https://invitemember.com/join"} className={"ml-5"}>Subscribe</ButtonPrimary>
        </div>
    }


    let currentCardSection = "";
    if (this.props.billing && this.props.billing.card) {
      let card = this.props.billing.card;
      currentCardSection =
        <div>
          <h1 className={"text-2xl mt-10 mb-5 ml-5"}>Credit card</h1>
          <p className="ml-5">
            **** **** **** {card.last4} exp. {card.exp_month < 10 ? "0" + card.exp_month : card.exp_month}/{card.exp_year}
            &nbsp;&nbsp;
            <a href="https://invitemember.com/billing/update" className="text-blue-700 hover:underline">Update billing info</a>
          </p>
          {/*<div className="ml-5 mt-5">*/}
            {/*<ButtonPrimary className="">Update billing info</ButtonPrimary>*/}

          {/*</div>*/}
        </div>
    }

    let activePlanSection = "";

    if (this.props.billing && this.props.billing.subscription && this.props.billing.subscription.status === "active") {
      let subscription = this.props.billing.subscription;
      activePlanSection =
        <div>
          <h1 className={"text-2xl mt-10 mb-5 ml-5"}>Subscription</h1>

          <IMPlanCard name={subscription.planNickname} cancelAtPeriodEnd={subscription.cancelAtPeriodEnd} status={subscription.status} amount={Math.round(subscription.planAmount / 100)} period={subscription.planInterval} currentPeriodEnd={subscription.currentPeriodEnd} benefits={[
            "Live payment integrations", "Access Codes"
          ]}/>
        </div>
    }

    let invoicesSection = "";
    if (this.props.billing && this.props.billing.invoices) {
      invoicesSection =
        <div>
          <h2 className={"text-2xl mt-10 mb-5 ml-5"}>Invoices</h2>

          {/*<p className="ml-5">Your InviteMember subscription will automatically renew on ...</p>*/}

          <IMInvoicesTable invoices={this.props.billing.invoices}/>

        </div>
    }

    return (
      <DashboardLayout auth={this.props.auth}>

        <div className={"md:px-10 py-10"}>

          <h1 className={"text-3xl ml-5 mb-5"}>Billing</h1>

          {startFreeTrialSection}

          {startNewSubscriptionSection}

          {currentCardSection}

          {activePlanSection}

          {invoicesSection}


        </div>

      </DashboardLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    billing: state.billing
  }
};

const mapDispatchToProps = {
  fetchSubscriptionStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingPage);
