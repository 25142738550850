import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Routes, Navigate, Outlet, useLocation, useSearchParams} from "react-router-dom";
import LoginPage from './OLD Pages/LoginPage';
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import HomePage from './OLD Pages/HomePage';
import BillingPage from './OLD Pages/BillingPage';
import * as serviceWorker from './serviceWorker';
import "./index.css";
import {Provider, useDispatch} from 'react-redux'
import { useSelector } from 'react-redux'
import vhCheck from 'vh-check'
import {logout, setCurrentProjectId} from "./actions";
import CompleteSignUpPage from "./OLD Pages/CompleteSignUpPage";
// import withAuth from "./withAuth";
import logger from 'redux-logger'

import { applyMiddleware, createStore, compose } from 'redux';
// noinspection ES6CheckImport
import { offline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import index from './reducers'

import {IntlProvider} from 'react-intl';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';

import messages_ru from "./locales/ru.json";
import messages_en from "./locales/en.json";

import SelectProjectPage from "./OLD Pages/SelectProjectPage";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import UsersLeads from "./pages/UsersLeads";
import UsersCustomers from "./pages/UsersCustomers";
import UsersChurned from "./pages/UsersChurned";
import Payments from "./pages/Payments";
import Payment from "./pages/Payment";
import User from "./pages/User";
import RecurringPayment from "./pages/RecurringPayment";
import Subscription from "./pages/Subscription";
import RecurringPayments from "./pages/RecurringPayments";
import Subscriptions from "./pages/Subscriptions";
import GoToProject from "./pages/GoToProject";
import Join from "./pages/Join";
import PaySuccess from './pages/PaySuccess';
import PayCancel from './pages/PayCancel';
import StripeSuccess from './pages/StripeSuccess';
import StripeExpiredOrNotFound from './pages/StripeExpiredOrNotFound';

addLocaleData([...en, ...ru]);

const messages = {
  'ru': messages_ru,
  'en': messages_en
};

const lang = "ru";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;

// let nextWorker;

// const refresh = () => {
//   console.log("Posting message...");
//   nextWorker.postMessage({ type: 'SKIP_WAITING' });
// };

function PrivateRoute(props) {

  const auth = useSelector((state) => state.auth)
  const project = useSelector((state) => {
    if (state.project.currentId && state.project.byId && state.project.byId[state.project.currentId]) {
      return state.project.byId[state.project.currentId];
    } else {
      return undefined;
    }
  });
  // let dispatch = useDispatch();

  // const [searchParams, updateSearchParams] = useSearchParams();
  const location = useLocation();

  if (!auth?.user) {
    return <Navigate to="/login" />
  }

  if (project || location.pathname === "/select_project" || location.pathname === "/goto_project") {
    return <Outlet context={{auth, project}}/>
  } else {
    return <Navigate to="/select_project" />;
  }

}

const isNeededVhFallback = vhCheck().isNeeded;

if (isNeededVhFallback) {
  const screenHeight = () => {
    // Get the viewport height and multiply it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  window.addEventListener('resize', () => {
    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(function() {
      screenHeight();
    }, 250);
  });
  screenHeight();
}

const persistCallback = () => {
  ReactDOM.render(
    <IntlProvider locale={lang} defaultLocale={"en"} messages={messages[lang]}>
      <Provider store={store}>
        <BrowserRouter>
          <div>
            <Routes>
              {/*<Route path="/complete_signup" component={CompleteSignUpPage} />*/}
              <Route path="/logout" element={<Logout />} />
              <Route exact path="/login" element={<Login/>} />
              <Route exact path="/join" element={<Join />} />
              <Route exact path="/pay/success" element={<PaySuccess />} />
              <Route exact path="/pay/cancel" element={<PayCancel />} />
              <Route exact path="/stripe/success" element={<StripeSuccess />} />
              <Route exact path="/stripe/expired_or_not_found" element={<StripeExpiredOrNotFound />} />

              <Route path="/" element={<PrivateRoute />}>
                <Route exact path="/" element={<Dashboard/>} />
                <Route exact path="/select_project" element={<SelectProjectPage />} />
                <Route exact path="/goto_project" element={<GoToProject />} />
                <Route exact path="/users" element={<Users />} />
                <Route exact path="/users/leads" element={<UsersLeads />} />
                <Route exact path="/users/customers" element={<UsersCustomers />} />
                <Route exact path="/users/churned" element={<UsersChurned />} />
                <Route exact path="/user/:userId" element={<User />} />
                <Route exact path="/sales/payments" element={<Payments />} />
                <Route path="/sales/payments/:paymentId" element={<Payment />} />
                <Route exact path="/sales/recurringPayments" element={<RecurringPayments />} />
                <Route path="/sales/recurringPayment/:recurringPaymentId" element={<RecurringPayment />} />
                <Route exact path="/sales/subscriptions" element={<Subscriptions />} />
                <Route exact path="/sales/subscription/:subscriptionId" element={<Subscription />} />
              </Route>



              {/*<Route path="*" element={<NotFound />} />*/}

              {/*<Route path="/" element={withAuth(<Dashboard/>)} />*/}

                {/*{withAuth(withProject(Dashboard))}*/}

              {/*<Route path="/" component={withAuth(withProject(ProjectOverviewPage))} />*/}

              {/*<Route path="/onboarding" component={withAuth(SelectIntentPage)} />*/}
              {/*<Route path="/onboarding/paid_channel" component={withAuth(IntentPaidChannelPage)} />*/}
              {/*<Route path="/onboarding/forward_a_message" component={withAuth(ForwardMessagePage)} />*/}
              {/*<Route path="/onboarding/check_channel_admin" component={withAuth(CheckChannelAdminPage)} />*/}
              {/*<Route path="/onboarding/channel_added" component={withAuth(ChannelAddedPage)} />*/}
              {/*<Route path="/onboarding/completed" component={withAuth(OnboardingCompletedPage)} />*/}
              {/**/}
              {/*<Route path="/onboarding/add_plan" component={withAuth(AddPlanPage)} />*/}
              {/*<Route path="/onboarding/add_token" component={withAuth(AddBotTokenPage)} />*/}
              {/**/}
              {/**/}
              {/*<Route path="/users" component={withAuth(withProject(UsersPage))} />*/}
              {/*<Route path="/users/leads" component={withAuth(withProject(UsersPage))} />*/}
              {/*<Route path="/users/trialing" component={withAuth(withProject(UsersPage))} />*/}
              {/*<Route path="/users/active" component={withAuth(withProject(UsersPage))} />*/}
              {/*<Route path="/users/churned" component={withAuth(withProject(UsersPage))} />*/}
              {/*<Route path="/user/:userId" component={withAuth(withProject(UserPage))} />*/}
              {/**/}
              {/*<Route path="/plans" component={withAuth(withProject(PlansPage))} />*/}
              {/**/}
              {/*<Route path="/resources" component={withAuth(withProject(ResourcesPage))} />*/}
              {/**/}
              {/*<Route path="/metrics" component={withAuth(withProject(KeyMetricsPage))} />*/}
              {/**/}
              {/**/}
              {/*<Route path="/sales/payment/:paymentId" component={withAuth(withProject(PaymentPage))} />*/}
              {/*<Route path="/sales/subscription/:subscriptionId" component={withAuth(withProject(SubscriptionPage))} />*/}
              {/**/}
              {/*<Route path="/ui" component={withAuth(ComponentsShowcasePage)} />*/}

              {/*<Route path="/billing" component={withAuth(BillingPage)} />*/}
            </Routes>
          </div>
        </BrowserRouter>
      </Provider>
    </IntlProvider>, document.getElementById('root')
  );
};



// TODO More Fancy loader?
ReactDOM.render(
  (
    <div className="h-screen flex items-center justify-center py-1 mt-10 mb-10">
      <svg className="animate-rotation w-10 h-10" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
        <path d="M12 2C11.4467 2 10.9039 2.04494 10.375 2.13136" stroke="white" strokeWidth="3" strokeLinecap="round"/>
        <path d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2" stroke="#8A9698" strokeWidth="3" strokeLinecap="round"/>
      </svg>
    </div>
  ), document.getElementById('root')
);

const store = createStore(index,
  composeEnhancers(
    offline({
      ...offlineConfig,
      persistCallback
      // effect,
      // discard
    }),
    applyMiddleware(
      // logger // neat middleware that logs actions
    ),
  )
);

serviceWorker.register({
  onSuccess(registration) {
    console.log("success", registration);
  },
  onUpdateFound(installingWorker) {
    console.log("new:", installingWorker);
  },
  onUpdateInstalled(installingWorker) {
    console.log("installed:", installingWorker);
    window["nextWorker"] = installingWorker;

    let divNotification = document.createElement("div");
    divNotification.classList.add("absolute", "z-50", "top-0", "left-0", "right-0", "flex", "flex-grow", "justify-center", "items-center");
    divNotification.innerHTML = '<div class="mt-5 bg-white border-black rounded p-3">A newer version of InviteMember is available <button id="reloadButton" class="uppercase font-bold text-gray-700 mx-2" onClick="window[\'nextWorker\'].postMessage({ type: \'SKIP_WAITING\' })">Refresh</button></div>';
    document.body.appendChild(divNotification);
  }
});
