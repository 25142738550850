import React, { useEffect, useRef, useState } from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import {useOutletContext} from "react-router-dom";
import BaseTimeseriesAPIChart from "../charts/BaseTimeseriesAPIChart";
import moment from 'moment';
import DropdownScrollGroup from '../components/DropdownScrollGroup';
import { useDispatch, useSelector } from 'react-redux';
import withProject from '../withProject';
import { fetchProject } from '../actions';

function Dashboard(props) {
    const periodOptions = [
        { id: 0, title: "Last 7 days", date: { number: 7, period: 'days' } },
        { id: 1, title: "Last 4 weeks", date: { number: 4, period: 'weeks' } },
        { id: 2, title: "Last 3 months", date: { number: 3, period: 'months' } },
        { id: 3, title: "Last 12 months", date: { number: 12, period: 'months' } },
        { id: 4, title: "Month to date", date: { number: moment().diff(moment().startOf('month'), 'days'), period: 'days', } },
        { id: 5, title: "Quarter to date", date: { number: moment().diff(moment().startOf('quarter'), 'days'), period: 'days', } },
        { id: 6, title: "Year to date", date: { number: moment().diff(moment().startOf('year'), 'days'), period: 'days', } },
        { id: 7, title: "All time", date: { number: 0, period: 'days' } },
        // { id: 8, title: "Custom" },
    ];

    const comparedToOptionsDefault = [
        { id: 0, title: "Previous period" },
        { id: 1, title: "Previous month" },
        { id: 2, title: "Previous quarter" },
        { id: 3, title: "Previous year" },
        { id: 4, title: "No comparison" },
    ];
    const unitOptionsDefault = [
        { id: 0, title: "Daily", value: "day" },
        { id: 1, title: "Weekly", value: "week" },
        { id: 2, title: "Monthly", value: "month" },
    ];

    const buildPeriods = ({ id, date }) => {
        // let tomorrow = moment().utc().startOf('day').add(1, 'day');
        // let start, end = tomorrow;
        let tomorrow = moment().utc().startOf('day');
        let start = tomorrow;
        let end = tomorrow.clone().add(1, 'day');

        if (id === 4) {
            if (timeframe === 'week') {
                start = tomorrow.clone().startOf('month').startOf('isoWeek');
            } else {
                start = tomorrow.clone().startOf('month');
            }
        } else if (id === 5) {
            if (timeframe === 'week') {
                start = tomorrow.clone().startOf('quarter').startOf('isoWeek');
            } else {
                start = tomorrow.clone().startOf('quarter');
            }
        } else if (id === 6) {
            if (timeframe === 'week') {
                // Round date if there is less than 4 days in week from the beginning of the year
                start = tomorrow.clone().startOf('year');
                const jan1 = start.clone();
                const jan1DayOfWeek = jan1.isoWeekday();

                if (jan1DayOfWeek > 3) {
                    start = jan1.startOf('isoWeek');
                }
            } else {
                start = tomorrow.clone().startOf('year');
            }
        } else if (id === 7) {
            start = moment(project.createdAt, 'YYYY-MM-DD') || new Date();
        } else {
            start = tomorrow.clone().subtract(date.number, date.period);
            if (timeframe === 'week') {
                start = start.clone().startOf('isoWeek').add(1, 'week');
            } else if (timeframe === 'month') {
                start = start.clone().startOf('month').add(1, 'month');
            }
        }

        let newPeriod = { start: start.valueOf(), end: end.valueOf() };
        let newPrevPeriod = { start:  start - (end - start), end: start.valueOf() };

        return [newPeriod, newPrevPeriod];
    }


    const buildPrevPeriods = (item) => {
        let start = moment(period.start);
        let end = moment(period.end);

        if (item.id === 0) {
            let diff = end.diff(start, 'day');

            if (timeframe === 'week') {
                start = moment(period.start).subtract(diff, 'day').startOf('isoWeek');
                end = moment(period.start).subtract(1, 'day');
            } else if (timeframe === 'month') {
                start = moment(period.start).subtract(diff, 'day').startOf('month');
                end = moment(period.start).subtract(1, 'day');
            } else {
                start = moment(start.valueOf() - (end.valueOf() - start.valueOf()));
                end = moment(period.start);
            }
        } else if (item.id === 1) {
            if (timeframe === 'week') {
                start = moment(period.start).subtract(4, 'week').startOf('isoWeek');
                end = moment(period.end).subtract(4, 'week').endOf('isoWeek');
            } else if (timeframe === 'month') {
                start = moment(period.start).subtract(1, 'month').startOf('month');
                end = moment(period.end).subtract(1, 'month').endOf('month');
            } else {
                start = moment(moment(period.end).subtract(1, 'month').valueOf() - (period.end - period.start));
                end = moment(period.end).subtract(1, 'month');
            }
        } else if (item.id === 2) {
            if (timeframe === 'week') {
                start = moment(start).subtract(12, 'week').startOf('isoWeek');
                end = moment(end).subtract(12, 'week').endOf('isoWeek');
            } else if (timeframe === 'month') {
                start = moment(period.start).subtract(3, 'month').startOf('month');
                end = moment(period.end).subtract(3, 'month').endOf('month');
            } else {
                start = moment(moment(period.end).subtract(3, 'month').valueOf() - (period.end - period.start));
                end = moment(period.end).subtract(3, 'month');
            }
        } else if (item.id === 3) {
            if (timeframe === 'week') {
                let weeksInYear = moment(start).isoWeeksInYear();

                start = moment(start).subtract(weeksInYear, 'week').startOf('isoWeek');
                end = moment(end).subtract(weeksInYear, 'week').endOf('isoWeek');
            } else if (timeframe === 'month') {
                start = moment(period.start).subtract(1, 'year').startOf('month');
                end = moment(period.end).subtract(1, 'year').endOf('month');
            } else {
                start = moment(moment(period.end).subtract(1, 'year').valueOf() - (period.end - period.start));
                end = moment(period.end).subtract(1, 'year');
            }
        // } else if (item.id === 4) {
        //     start = moment(comparedCustom.start);
        //     end = moment(comparedCustom.end);
        }

        start = start.valueOf();
        end = end.valueOf();

        return {
            ...item,
            date: {
                start,
                end,
            },
        }
    }

    const buildUnitOptions = (item) => {
        const daysDiff = moment.utc(period.end).diff(moment.utc(period.start), 'days');
        const maxPoints = 100;

        const visibilityRules = {
            day: daysDiff >= 2 && daysDiff <= maxPoints,
            week: daysDiff / 7 >= 2 && daysDiff / 7 <= maxPoints,
            month: daysDiff / 30 >= 2 && daysDiff / 30 <= maxPoints,
        };

        return {
            ...item,
            visible: visibilityRules[item.value],
        };
    }

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { auth, project } = useOutletContext();

    const [filter, setFilter] = useState({});
    const [timeframe, setTimeframe] = useState("day"); // hour/day/week/month/year
    let [defaultPeriod, defaultPrevPeriod] = buildPeriods(periodOptions[1]);
    const [period, setPeriod] = useState(defaultPeriod);
    const [prevPeriod, setPrevPeriod] = useState(defaultPrevPeriod);

    const [comparedToOptions, setComparedToOptions] = useState(comparedToOptionsDefault);
    const [comparedToVisible, setComparedToVisible] = useState(true);
    const [comparedToDatepickerVisible, setComparedToDatepickerVisible] = useState(false);
    // const [comparedCustom, setComparedCustom] = useState(prevPeriod);
    const [unitOptions, setUnitOptions] = useState(unitOptionsDefault);

    const [periodState, setPeriodState] = useState({
        timeframe: "day",
        period: defaultPeriod,
        prevPeriod: defaultPrevPeriod
    });

    const dropdownsRef = useRef();

    const getTimeframe = (newPeriod) => {
        const updatedUnitOptions = unitOptions.map((item) => buildUnitOptions({ ...item, period: newPeriod }));
        const currentOption = updatedUnitOptions.find((item) => item.value === timeframe);

        if (currentOption && currentOption.visible) {
            return timeframe;
        } else {
            const firstVisibleOption = updatedUnitOptions.find((item) => item.visible);
            return firstVisibleOption ? firstVisibleOption.value : timeframe;
        }
    }

    const onPeriodChange = (value) => {
        let [newPeriod, newPrevPeriod] = buildPeriods(value);

        if (value.date.period === "days" && value.date.number === 7) {
            setTimeframe("day");
        }

        // const newTimeframe = getTimeframe(newPeriod);
        // setTimeframe(newTimeframe);
        setPeriod(newPeriod);

        const selected = periodOptions.filter((item) => item.id === value.id)[0];

        if (selected && selected.title === 'All time') {
            setComparedToVisible(false);
        } else {
            setComparedToVisible(true);
        }
    }

    const onComparedToChange = (value) => {
        const selected = comparedToOptions.filter((item) => item.id === value.id)[0];

        if (selected && selected.title === 'Custom') {
            setComparedToDatepickerVisible(true);
        } else {
            setComparedToDatepickerVisible(false);
        }
        if (value.date) {
            setPrevPeriod(value.date);
            // setPeriodState((prevState) => ({
            //     ...prevState,
            //     prevPeriod: value.date,
            // }));
        }
    }

    const onUnitChange = ({ value }) => {
        setTimeframe(value);
    }

    const onFilterChange = (value) => {
        setFilter(value);
    }

    useEffect(() => {
        setComparedToOptions(comparedToOptions.map((item) => buildPrevPeriods(item)));

        if (JSON.stringify(unitOptions.map((item) => buildUnitOptions(item))) !== JSON.stringify(unitOptions)) {
            setUnitOptions(unitOptions.map((item) => buildUnitOptions(item)));
        }
    }, [period]);

    useEffect(() => {
        let comparedToSelected = dropdownsRef.current.getSelectedCompared();
        const periodSelected = dropdownsRef.current.getSelectedPeriod();
        const unitSelected = dropdownsRef.current.getSelectedUnit();
        if (periodSelected && periodSelected.title === "All time") {
            // comparedToSelected = comparedToOptions.filter((item) => item.title === "No comparison")[0];
        }

        if ((comparedToSelected && comparedToSelected.date) && ((comparedToSelected.date.start !== prevPeriod.start) || (comparedToSelected.date.end !== prevPeriod.end))) {
            setPrevPeriod(comparedToSelected.date);
            setTimeframe(unitSelected.value);
        }
    }, [comparedToOptions]);

    useEffect(() => {
        const periodSelected = dropdownsRef.current.getSelectedPeriod();
        let [newPeriod, newPrevPeriod] = buildPeriods(periodSelected);

        if (period.start !== newPeriod.start || period.end !== newPeriod.end) {
            setPeriod(newPeriod);
        }

    }, [timeframe]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setPeriodState({
                timeframe,
                period,
                prevPeriod,
            });
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    }, [timeframe, period, prevPeriod]);

    // useEffect(() => {
    //     // console.log('comparedCustom', comparedCustom);
    //     if (comparedCustom && ((comparedCustom.start !== prevPeriod.start) || (comparedCustom.end !== prevPeriod.end))) {
    //         // console.log('comparedCustom new period', comparedCustom);
    //         setPrevPeriod(comparedCustom);
    //     }
    // }, [comparedCustom]);

    // console.log('period', moment(period.start).format('DD MMM YYYY'), moment(period.end).format('DD MMM YYYY'));
    // console.log('prevPeriod', moment(prevPeriod.start).format('DD MMM YYYY'), moment(prevPeriod.end).format('DD MMM YYYY'));
    // console.log('days diff', moment('2018-07-01', 'YYYY-MM-DD').diff(moment('2023-03-21', 'YYYY-MM-DD'), 'days'));

    return (
        <div className="flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} user={auth?.user} project={project}/>

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                        {/* Welcome banner */}
                        {/*<WelcomeBanner />*/}

                        {/* Dashboard actions */}
                        <div className="sm:flex sm:justify-between sm:items-center">

                            {/* Left: Avatars */}
                            {/*<DashboardAvatars />*/}
                            <DropdownScrollGroup ref={dropdownsRef}
                                                 periodOptions={periodOptions}
                                                 comparedToOptions={comparedToOptions}
                                                 unitOptions={unitOptions}
                                                 onPeriodChange={onPeriodChange}
                                                 onComparedToChange={onComparedToChange}
                                                 onUnitChange={onUnitChange}
                                                 onFilterChange={onFilterChange}
                                                 period={periodState.period}
                                                 // comparedCustom={comparedCustom}
                                                 // setComparedCustom={setComparedCustom}
                                                 comparedToVisible={comparedToVisible}
                                                 comparedToDatepickerVisible={comparedToDatepickerVisible} />

                            {/* Right: Actions */}
                            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

                            </div>

                        </div>

                        {/*<div className="mb-8">*/}
                        {/*    <b>{moment(periodState.period.start).format('DD MMM YYYY')}</b> - <b>{moment(periodState.period.end).format('DD MMM YYYY')}</b> compared to <b>{moment(periodState.prevPeriod.start).format('DD MMM YYYY')}</b> - <b>{moment(periodState.prevPeriod.end).format('DD MMM YYYY')}</b>*/}
                        {/*    <div><b>{moment(periodState.period.end).diff(moment(periodState.period.start), 'days')}</b> compared to <b>{moment(periodState.prevPeriod.end).diff(moment(periodState.prevPeriod.start), 'days')}</b></div>*/}
                        {/*</div>*/}

                        {/* Cards */}
                        <div className="grid grid-cols-12 gap-6">

                            <BaseTimeseriesAPIChart
                                title="Bot users" apiMethod="/project/charts/newBotUsers"
                                periodStart={periodState.period.start} periodEnd={period.end} prevPeriodStart={periodState.prevPeriod.start}
                                prevPeriodEnd={periodState.prevPeriod.end} timeframe={periodState.timeframe}
                                filter={filter}
                                periodSelected={dropdownsRef.current && dropdownsRef.current.getSelectedPeriod()}
                                comparedToSelected={dropdownsRef.current && dropdownsRef.current.getSelectedCompared()}
                            />

                            <BaseTimeseriesAPIChart
                                title="Volume from sales" apiMethod="/project/charts/usdVolume"
                                periodStart={periodState.period.start} periodEnd={periodState.period.end} prevPeriodStart={periodState.prevPeriod.start}
                                prevPeriodEnd={periodState.prevPeriod.end} timeframe={periodState.timeframe}
                                currency={"USD"}
                                filter={filter}
                                periodSelected={dropdownsRef.current && dropdownsRef.current.getSelectedPeriod()}
                                comparedToSelected={dropdownsRef.current && dropdownsRef.current.getSelectedCompared()}
                            />

                            <BaseTimeseriesAPIChart
                                title="One-time payments" apiMethod="/project/charts/payments"
                                periodStart={periodState.period.start} periodEnd={periodState.period.end} prevPeriodStart={periodState.prevPeriod.start}
                                prevPeriodEnd={periodState.prevPeriod.end} timeframe={periodState.timeframe}
                                filter={filter}
                                periodSelected={dropdownsRef.current && dropdownsRef.current.getSelectedPeriod()}
                                comparedToSelected={dropdownsRef.current && dropdownsRef.current.getSelectedCompared()}
                            />

                            <BaseTimeseriesAPIChart
                                title="Recurring payments" apiMethod="/project/charts/recurringPayments"
                                periodStart={periodState.period.start} periodEnd={periodState.period.end} prevPeriodStart={periodState.prevPeriod.start}
                                prevPeriodEnd={periodState.prevPeriod.end} timeframe={periodState.timeframe}
                                filter={filter}
                                periodSelected={dropdownsRef.current && dropdownsRef.current.getSelectedPeriod()}
                                comparedToSelected={dropdownsRef.current && dropdownsRef.current.getSelectedCompared()}
                            />



                            {/*<BaseTimeseriesAPIChart*/}
                            {/*    title="One-time payments USD Volume" apiMethod="/project/charts/paymentsUSDVolume"*/}
                            {/*    periodStart={period.start} periodEnd={period.end} prevPeriodStart={prevPeriod.start}*/}
                            {/*    prevPeriodEnd={prevPeriod.end} timeframe={timeframe}*/}
                            {/*/>*/}

                            {/*<BaseTimeseriesAPIChart*/}
                            {/*    title="Recurring payments USD Volume" apiMethod="/project/charts/recurringPaymentsUSDVolume"*/}
                            {/*    periodStart={period.start} periodEnd={period.end} prevPeriodStart={prevPeriod.start}*/}
                            {/*    prevPeriodEnd={prevPeriod.end} timeframe={timeframe}*/}
                            {/*/>*/}


                        </div>

                    </div>
                </main>

            </div>

        </div>
    );
}

export default withProject(Dashboard);
