import React, { Component } from 'react';

class IMLogoWhiteMinimal extends Component {
  render() {
    return (
      <div>
        <svg className="w-12 h-12 ml-5 py-3" viewBox="0 0 33 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Logos" transform="translate(-1654.000000, -369.000000)" fill="#FFFFFF">
              <g id="InviteMember-eps" transform="translate(1311.000000, 336.000000)">
                <path
                  d="M364.379,37.993 C365.422,37.449 366.545,37.189 367.722,37.189 C368.713,37.189 369.679,37.37 370.59,37.764 C371.464,38.141 372.247,38.664 372.925,39.331 C373.605,40 374.135,40.78 374.513,41.656 C374.907,42.567 375.088,43.533 375.088,44.524 L375.088,51.89 L371.419,51.89 L371.419,44.524 C371.419,44.032 371.329,43.554 371.135,43.101 C370.946,42.661 370.685,42.269 370.346,41.931 C370.005,41.59 369.608,41.33 369.164,41.143 C368.703,40.95 368.221,40.858 367.722,40.858 C367.229,40.858 366.752,40.947 366.299,41.142 C365.859,41.33 365.467,41.592 365.129,41.931 C364.79,42.269 364.529,42.661 364.34,43.101 C364.146,43.554 364.056,44.032 364.056,44.524 L364.056,51.89 L360.387,51.89 L360.387,44.524 C360.387,44.032 360.298,43.554 360.103,43.101 C359.915,42.661 359.653,42.269 359.314,41.93 C358.974,41.59 358.576,41.33 358.132,41.143 C357.672,40.95 357.189,40.858 356.69,40.858 C356.198,40.858 355.72,40.947 355.267,41.142 C354.827,41.33 354.435,41.592 354.097,41.931 C353.759,42.269 353.497,42.661 353.308,43.101 C353.114,43.554 353.024,44.032 353.024,44.524 L353.024,51.89 L349.355,51.89 L349.355,44.524 C349.355,43.533 349.536,42.567 349.93,41.656 C350.306,40.785 350.829,40.005 351.5,39.334 C352.175,38.66 352.962,38.137 353.839,37.763 C354.747,37.375 355.703,37.189 356.69,37.189 C357.872,37.189 359.004,37.442 360.051,37.994 C360.879,38.431 361.6,38.999 362.206,39.696 C362.816,38.996 363.544,38.428 364.379,37.993 Z"
                  id="Fill-24"></path>
                <polygon id="Fill-25" points="343.244 51.89 346.883 51.89 346.883 37.572 343.244 37.572"></polygon>
                <polyline id="Fill-26"
                          points="343.244 33.151 346.883 33.151 346.883 36.413 343.244 33.151"></polyline>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

export default IMLogoWhiteMinimal;