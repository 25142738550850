import {
  PENDING_TELEGRAM_CHANNELS_REQUEST,
  PENDING_TELEGRAM_CHANNELS_COMMIT,
  PENDING_TELEGRAM_CHANNELS_ROLLBACK
} from "../actions/index";

export function resource(state = {
  isFetching: false,
  isFailed: false
}, action) {
  // console.log("in reducer", action);
  switch (action.type) {
    case PENDING_TELEGRAM_CHANNELS_REQUEST:
      return {
        ...state,
        isFetching: true,
        isFailed: false
      };
    case PENDING_TELEGRAM_CHANNELS_COMMIT:
      return {
        ...state,
        isFetching: false,
        isFailed: false,
        pendingChannels: action.payload.pendingChannels
      };
    case PENDING_TELEGRAM_CHANNELS_ROLLBACK:
      return {
        ...state,
        isFetching: false,
        isFailed: true
      };
    default:
      return state
  }
}

