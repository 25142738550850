import React, {useEffect, useState} from 'react';
import moment from "moment";

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import {NavLink, useLocation, useMatch, useOutletContext} from "react-router-dom";
import {fetchAPI} from "../api";
import ShopCards01 from "../partials/ecommerce/ShopCards01";
import PaymentMethod from "../OLDComponents/PaymentMethod";
import Badge from "../OLDComponents/Badge";
import DetailsTable from "../components/DetailsTable";
import PaymentsTable from "../components/Tables/PaymentsTable";
import { formatPrice, formatPriceOnly, isFiat } from "../utils/currencies/currencies";
import {humanizeDuration} from "../utils/durations";

function Payment(props) {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [payment, setPayment] = useState(location.state);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const {auth, project} = useOutletContext();
  let match = useMatch("/sales/payments/:paymentId");

  useEffect(() => {
    const fetchData = async() => {
      try {
        setLoading(true);
        const response = await fetchAPI("/project/payment", auth.user.token, {
          projectId: project.projectId,
          paymentId: match.params.paymentId
        });
        if (!response.ok) {
          throw new Error(`This is an HTTP error: The status is ${response.status}`);
        }
        let actualData = await response.json();
        setPayment(actualData);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [match]);

  // let imCommission = <div>&mdash;</div>;
  let imCommission = <div>$0</div>;
  if (payment && payment.imCommission) {
    imCommission = <div>{payment.imCommission.formattedAmount} {payment.imCommission.currency} converted to ${payment.imCommission.amountUSD}</div>
  }

  let details = [];
  details.push({ label: "Customer", value: !payment ? null : <NavLink to={"/user/" + payment?.userId} className="text-blue-700 hover:underline">{payment?.fullName} (id{payment?.telegramUserId})</NavLink>, none: "—" });
  details.push({ label: "Received", value:  !payment ? null : moment(payment?.receivedAt).format("MMM D, LT"), none: "Unknown" });

  details.push({ label: "Plan", value: payment?.planName, none: "—" });
  details.push({ label: "Period", value: !payment ? null : (`${humanizeDuration(payment.period)} / ${moment(payment?.effectiveFrom).format("ll")} → ${moment(payment?.effectiveUntil).format("ll")}`), none: "—" });

  let methodWithDetails = null;
  if (payment?.stripeChargeId) {
    methodWithDetails = <div>Stripe / <a className="text-blue-700 hover:underline" target="_blank" href={"https://dashboard.stripe.com/" + (payment?.liveMode ? "" : "test/") + "payments/" + payment?.stripeChargeId}>{payment?.stripeChargeId}</a></div>;
  } else if (payment?.coinPaymentsTxn) {
    methodWithDetails = <div>CoinPayments / {payment?.coinPaymentsTxn.txn_id}</div>;
  } else if (payment?.paypalCaptureId) {
    methodWithDetails = <div>PayPal / {payment?.paypalCaptureId}</div>;
  } else if (payment?.paypalOrderId) {
    methodWithDetails = <div>PayPal / {payment?.paypalOrderId}</div>;
  } else if (payment?.accessCodeId) {
    methodWithDetails = <div>Access Code / {payment?.accessCodeId}</div>;
  } else if (payment?.razorpayPaymentId) {
    methodWithDetails = <div>Razorpay / {payment?.razorpayPaymentId}</div>;
  } else {
    methodWithDetails = <PaymentMethod method={payment?.method} showIcon={false} showMode={false} />;
  }

  details.push({ label: "Method", value: !payment ? null : methodWithDetails, none: "Unknown" });

  let feeInfo;
  if (payment?.imCommission?.amountUSD) {
    feeInfo = `$${payment.imCommission?.amountUSD.toFixed(2)} USD (${payment.imCommission?.percent}%)`;
  } else if (payment?.stripeApplicationFeeAmount) {
    feeInfo = `${(payment.stripeApplicationFeeAmount / 100).toFixed(2)} (${Math.round(payment.stripeApplicationFeeAmount / payment.amountUSD).toFixed(0)}%) via Stripe Connect`;
  }
  if (feeInfo) details.push({ label: "InviteMember Fee", value: !feeInfo ? null : feeInfo, none: "$0" });

  const ignoreLiveMode = payment && (payment.method.includes('coinpayments') || payment.method.includes('access code'));
  const testLabel = payment && (ignoreLiveMode ? false : !payment.liveMode);

  return (
    <div className="flex h-screen overflow-hidden bg-white">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} project={project} user={auth?.user} />

        <main>

          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">

              <div>
                <div className="mb-4 sm:mb-0">
                  <div className="flex items-center mb-1">
                    <svg className="w-4 h-4 fill-current text-slate-400 shrink-0 mr-2" viewBox="0 0 16 16">
                      <path d="M11.4 0L10 1.4l2 2H8.4c-2.8 0-5 2.2-5 5V12l-2-2L0 11.4l3.7 3.7c.2.2.4.3.7.3.3 0 .5-.1.7-.3l3.7-3.7L7.4 10l-2 2V8.4c0-1.7 1.3-3 3-3H12l-2 2 1.4 1.4 3.7-3.7c.4-.4.4-1 0-1.4L11.4 0z" />
                    </svg>
                    <h2 className="text-slate-500">One-time payment</h2>
                  </div>

                  <h1 className="text-2xl md:text-3xl text-slate-800 font-bold flex items-center space-x-2">
                    { !payment && <div className={"animate-pulse bg-slate-200 w-32 h-10 inline-block"}></div> }
                    { !payment && <div className={"animate-pulse bg-slate-200 w-16 h-10 inline-block"}></div> }
                    { payment && <span>{isFiat(payment.currency) ? formatPrice(payment.amount, payment.currency) : formatPriceOnly(payment.amount, payment.currency)}</span> }
                    { payment && <span className={"text-slate-500 font-light uppercase"}>{payment.currency}</span> }
                    { payment && testLabel && <div className="text-sm inline-flex font-medium bg-amber-100 text-amber-600 rounded-full text-center px-2.5 py-1">Test Mode</div> }
                  </h1>

                </div>

              </div>

            </div>

            <div className="mt-8">
              <h2 className="text-xl leading-snug text-slate-800 font-bold mb-5">Payment details</h2>

              <DetailsTable details={details} isLoading={isLoading} />

            </div>

            <div className="mt-8">
              <h2 className="text-xl leading-snug text-slate-800 font-bold mb-5">Relevant one-time payments</h2>
              <PaymentsTable pageSize={10} delayLoading={!payment?.userId || !payment?.paymentId} filters={JSON.stringify({
                filters: [
                  { key: "userId", condition: { eq: payment?.userId } },
                  { key: "paymentId", condition: { ne: payment?.paymentId} }
                ]
              })} />
            </div>

            {/*<div className="mt-8">*/}
            {/*  <h2 className="text-xl leading-snug text-slate-800 font-bold mb-5">Raw data</h2>*/}
            {/*  <pre className="text-xs">*/}
            {/*        { JSON.stringify(payment, null, 4)}*/}
            {/*        </pre>*/}
            {/*</div>*/}

          </div>
        </main>

      </div>

    </div>
  );
}

export default Payment;
