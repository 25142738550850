import {createSelector} from 'reselect'

// ----------------------------------------------- PROPS.MATCH.PARAMS -----------------------------------------------

const getPropsMatchParamsUserId = (state, props) => {
    try {
        return props.match.params.userId;
    } catch (e) {
        return undefined;
    }
};

const getPropsMatchParamsPaymentId = (state, props) => {
    try {
        return props.match.params.paymentId;
    } catch (e) {
        return undefined;
    }
};

const getPropsMatchParamsSubscriptionId = (state, props) => {
    try {
        return props.match.params.subscriptionId;
    } catch (e) {
        return undefined;
    }
};

// ----------------------------------------------- CORE -----------------------------------------------

export const getProject = (state) => {
    try {
        return state.project.byId[state.project.currentId];
    } catch (e) {
        return {}
    }
};

export const getProjectUsersById = (state) => {
    try {
        let projectUsers = state.project.usersById[state.project.currentId];
        return projectUsers.byId ? projectUsers.byId : {};
    } catch (e) {
        return {}
    }
};

export const getProjectUsers = createSelector(
    [getProjectUsersById],
    (usersById) => {
        return Object.keys(usersById).map(id => usersById[id]);
    }
);

export const getResources = (state) => {
    try {
        let byId = state.project.resourcesById[state.project.currentId].byId;
        return Object.keys(byId).map(id => byId[id]);
    } catch (e) {
        return []
    }
};


export const getProjectPlansById = (state) => {
    try {
        return state.project.plansById[state.project.currentId].byId;
    } catch (e) {
        return {}
    }
};

export const getProjectPlans = createSelector(
    [getProjectPlansById],
    (plansById) => {
        return Object.keys(plansById).map(id => plansById[id]);
    }
);

export const getProjectPaymentsById = (state) => {
    try {
        let payments = state.project.paymentsById[state.project.currentId];
        return payments.byId ? payments.byId : {};
    } catch (e) {
        return {}
    }
};

export const getProjectSubscriptionsById = (state) => {
    try {
        let subscriptions = state.project.subscriptionsById[state.project.currentId];
        return subscriptions.byId ? subscriptions.byId : {}
    } catch (e) {
        return {}
    }
};

// ----------------------------------------------- FETCHING STATUS -----------------------------------------------

export const isProjectUsersFetching = (state) => {
    try {
        return state.project.usersById[state.project.currentId].isFetching;
    } catch (e) {
        return {}
    }
};

// ----------------------------------------------- CORE WITH QUERY PARAMS -----------------------------------------------

export const getProjectUserFromPropsMatchByUserId = createSelector(
    [getProjectUsersById, getPropsMatchParamsUserId],
    (projectUsers, userId) => {
        return typeof userId !== 'undefined' ? projectUsers[userId] : undefined;
    }
);

export const getProjectUserPaymentsFromPropsMatchByUserId = createSelector(
    [getProjectPaymentsById, getPropsMatchParamsUserId],
    (projectPayments, userId) => {
        return typeof userId !== 'undefined' ? Object.keys(projectPayments).map(id => projectPayments[id]).filter(p => p.userId === userId) : [];
    }
);

export const getProjectUserSubscriptionsFromPropsMatchByUserId = createSelector(
    [getProjectSubscriptionsById, getPropsMatchParamsUserId],
    (projectSubscriptions, userId) => {
        return typeof userId !== 'undefined' ? Object.keys(projectSubscriptions).map(id => projectSubscriptions[id]).filter(p => p.userId === userId) : [];
    }
);

export const getProjectPaymentFromPropsMatchByPaymentId = createSelector(
    [getProjectPaymentsById, getPropsMatchParamsPaymentId],
    (projectPayments, paymentId) => {
        return projectPayments[paymentId];
    }
);

export const getProjectUserFromPropsMatchByPaymentId = createSelector(
    [getProjectPaymentsById, getProjectUsersById, getPropsMatchParamsPaymentId],
    (projectPayments, projectUsers, paymentId) => {
        let payment = projectPayments[paymentId];
        return payment ? projectUsers[payment.userId] : undefined
    }
);

export const getProjectSubscriptionFromPropsMatchBySubscriptionId = createSelector(
    [getProjectSubscriptionsById, getPropsMatchParamsSubscriptionId],
    (projectSubscriptions, subscriptionId) => {
        return projectSubscriptions[subscriptionId];
    }
);

export const getProjectUserFromPropsMatchBySubscriptionId = createSelector(
    [getProjectSubscriptionsById, getProjectUsersById, getPropsMatchParamsSubscriptionId],
    (projectSubscriptions, projectUsers, subscriptionId) => {
        let subscription = projectSubscriptions[subscriptionId];
        return subscription ? projectUsers[subscription.userId] : undefined
    }
);


// ----------------------------------------------- PAGINATION FILTERS -----------------------------------------------

export const getProjectUsersPaginationFilter = (state) => {
    let pageSize, pageNumber, lastKey;

    try {
        pageSize = state.project.usersById[state.project.currentId].pageSize;
    } catch (e) {
    }

    try {
        pageNumber = state.project.usersById[state.project.currentId].pageNumber;
    } catch (e) {
    }

    try {
        lastKey = state.project.usersById[state.project.currentId].lastKey;
    } catch (e) {
    }

    if (typeof pageSize === 'undefined') pageSize = 100;
    if (typeof pageNumber === 'undefined') pageNumber = 0;

    return [pageSize, pageNumber, lastKey];
};

// ----------------------------------------------- COUNTERS -----------------------------------------------

export const getProjectUsersCount = (state) => {

    let totalCount, loadedCount;

    try {
        totalCount = state.project.usersById[state.project.currentId].totalCount;
    } catch (e) {
    }

    try {
        loadedCount = state.project.usersById[state.project.currentId].loadedCount;
    } catch (e) {
    }

    if (typeof totalCount === 'undefined') totalCount = 0;
    if (typeof loadedCount === 'undefined') loadedCount = 0;

    return [totalCount, loadedCount];
};

// ----------------------------------------------- FILTERS -----------------------------------------------

export const getVisibleProjectUsers = createSelector(
    [getProjectUsersById, getProjectUsersPaginationFilter],
    (projectUsers, paginationFilter) => {
        console.log("paginationFilter", paginationFilter);
        let [pageSize, pageNumber, lastKey] = paginationFilter;
        let begin = pageNumber * pageSize;
        let end = (pageNumber + 1) * pageSize;

        let ids = Object.keys(projectUsers);
        ids = ids ? ids.slice(begin, end) : [];

        return ids.map(id => projectUsers[id]);
    }
);

export const getVisibleProjectPayments = createSelector(
    [getProjectPaymentsById],
    (projectPayments) => {
        return Object.keys(projectPayments).map(id => projectPayments[id]);
    }
);


export const getVisibleProjectSubscriptions = createSelector(
    [getProjectSubscriptionsById],
    (projectSubscriptions) => {
        console.log("projectSubscriptions", projectSubscriptions);
        return Object.keys(projectSubscriptions).map(id => projectSubscriptions[id]);
    }
);


export const getTelegramChannels = createSelector(
    [getResources],
    (resources) => {
        return resources.filter(r => typeof r.telegramChannelId !== 'undefined');
    }
);

export const getTelegramGroups = createSelector(
    [getResources],
    (resources) => {
        return resources.filter(r => typeof r.telegramGroupId !== 'undefined');
    }
);

export const getManualResources = createSelector(
    [getResources],
    (resources) => {
        return resources.filter(r => typeof r.telegramChannelId === 'undefined' && typeof r.telegramGroupId === 'undefined');
    }
);


// ----------------------------------------------- COMPOSED -----------------------------------------------

export const getProjectPlansWithResources = createSelector(
    [getProjectPlansById, getResources],
    (plans, allResources) => {
        return Object.keys(plans).map(id => plans[id]).map(p => {
            return {
                ...p,
                resources: allResources.filter(r => p.resourceIds.indexOf(r.resourceId) > -1)
            }
        })
    }
);




