import React, { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import ModalSearch from './ModalSearch';

const Search = () => {
	const [searchModalOpen, setSearchModalOpen] = useState(false);

	return (
		<>
			<button
				className={`min-w-[2rem] w-8 h-8 flex items-center justify-center bg-inst-warm-grey text-brand-green transition duration-150 rounded-full ml-2 sm:ml-4 ${searchModalOpen ? '' : ''}`}
				onClick={(e) => { e.stopPropagation(); setSearchModalOpen(true); }}
				aria-controls="search-modal"
			>
				<span className="sr-only">Search</span>
				<svg className="w-3.5 h-3.5" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M5.95488 10.9098C8.69138 10.9098 10.9098 8.69138 10.9098 5.95488C10.9098 3.21837 8.69138 1 5.95488 1C3.21837 1 1 3.21837 1 5.95488C1 8.69138 3.21837 10.9098 5.95488 10.9098Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
					<path d="M10.1661 8.7509C9.77557 8.36038 9.1424 8.36038 8.75188 8.7509C8.36135 9.14143 8.36135 9.77459 8.75188 10.1651L10.1661 8.7509ZM13.7085 12.2933L10.1661 8.7509L8.75188 10.1651L12.2943 13.7075L13.7085 12.2933Z" fill="currentColor"/>
				</svg>
			</button>
			<ModalSearch id="search-modal" searchId="search" modalOpen={searchModalOpen} setModalOpen={setSearchModalOpen} placeholder="Search Users…" />
		</>
	)
}

export default Search;
