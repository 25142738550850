import {
  ADD_PENDING_PLAN,
  CLEAR_PENDING_PLAN
} from "../actions/index";

export function plan(state = {}, action) {
  switch (action.type) {
    case ADD_PENDING_PLAN:
      return {
        ...state,
        pendingPlan: {
          price: action.price,
          currency: action.currency,
          period: action.period
        }
      };
    case CLEAR_PENDING_PLAN:
      return {
        ...state,
        pendingPlan: undefined
      };
    default:
      return state
  }
}