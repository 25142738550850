import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as ReactLogoColored } from '../img/logo.svg';
import { ReactComponent as ReactLogoWhite } from '../img/logo-white.svg';
import { ReactComponent as ReactLogoMiniWhite } from '../img/logo-mini-white.svg';
import { ReactComponent as ReactLogoMiniColored } from '../img/logo-mini.svg';
import { useWindowWidth } from '../hooks/useWindowWidth';

const Logo = ({logoWhite, logoMinMobile}) => {
	if (logoMinMobile) {
		return window.innerWidth >= 768
				? (logoWhite ? <ReactLogoWhite /> : <ReactLogoColored />)
				: (logoWhite ? <ReactLogoMiniWhite className="w-full" /> : <ReactLogoMiniColored className="w-full" />);
	}
	return logoWhite ? <ReactLogoWhite /> : <ReactLogoColored />;
}

const StaticLayout = ({ children, layoutCenter = false, layoutFixed = false, logoPosition, logoWhite = false, logoMinMobile = false, size }) => {
	const windowWidth = useWindowWidth();

	const positionClasses = classNames({
		'mr-auto': logoPosition === 'left' || !logoPosition,
		'ml-auto mr-auto': logoPosition === 'center',
	});

	const sizeClasses = classNames({
		'p-4 md:my-4 md:py-0.5': size === 'medium',
		'p-4 md:px-3 md:py-3 md:my-6': size === 'big',
		'md:my-4 py-0.5': !size,
	});

	const sizeSectionClasses = classNames({
		'min-h-[calc(100%_-_81px)]': size !== 'big',
		'min-h-[calc(100%_-_7.5rem)]': size === 'big',
	});

	const navClasses = classNames({
		'bg-inst-warm-grey md:bg-white text-brand-green md:border-b md:border-warm-grey': logoWhite,
		'bg-brand-white text-[#fff]': !logoWhite,
		'z-30': true,
	});

	const sectionClasses = classNames({
		'bg-inst-warm-grey': logoWhite,
		'bg-brand-white': !logoWhite,
		'flex items-stretch flex-col justify-between text-brand-green': true,
		[sizeSectionClasses]: true,
	});

	let navLinkExtraStyles = `w-60`;

	if (windowWidth < 768 && logoMinMobile) {
		navLinkExtraStyles = `w-[5.5rem]`;
	}

	return (
			<div className="h-screen text-[#4a4a4a]">
				<nav className={navClasses}>
					<div className={`flex md:justify-center`}>
						{/* Logo */}
						<NavLink end="true" to="https://invitemember.com/" className={`${navLinkExtraStyles} md:w-auto inline-flex ${sizeClasses} hover:opacity-80 transition duration-300 ease-out ${positionClasses}`}>
							<Logo logoWhite={logoWhite} logoMinMobile={logoMinMobile} />
						</NavLink>
					</div>
				</nav>

				<section className={sectionClasses}>
					<div className={`${layoutCenter ? 'flex md:items-center' : ''} ${layoutFixed ? 'grow shrink-0 py-1.5 md:py-3 px-4 md:px-1.5' : ''}`}>
						<div className={`${layoutFixed ? 'max-w-[960px] mx-auto grow shrink' : 'py-6 px-4 md:py-14 md:px-8'}`}>
							<div className={`${layoutCenter ? 'flex item-center' : ''}`}>{children}</div>
						</div>
					</div>
				</section>
			</div>
	)
}

export default StaticLayout;
