import React from 'react';
import moment from "moment";
import BaseDynamicApiTable from "./BaseDynamicAPITable";
import {formatPrice} from "../../utils/currencies/currencies";

export default function RecurringPaymentsTable({ pageSize, filters, delayLoading }) {
    return (
        <BaseDynamicApiTable
            apiMethod="/project/recurringPayments"
            filters={filters}
            pageSize={pageSize}
            delayLoading={delayLoading}
            dataModel={{
                columns: [
                    {
                        header: "Amount",
                        align: "right",
                        render: row => <span className="font-medium">{formatPrice(row.amount, row.currency)}</span>
                    }, {
                        header: "",
                        align: "left",
                        render: row => <span className="text-slate-400 uppercase">{row.currency}</span>
                    }, {
                        header: "Plan",
                        align: "left",
                        render: row => row.planName
                    }, {
                        header: "Customer",
                        align: "left",
                        render: row => row.fullName
                    }, {
                        header: "Method",
                        align: "left",
                        render: row => <span className="uppercase text-xs">{row.method}</span>
                    }, {
                        header: "Received",
                        align: "left",
                        render: row => moment(row.receivedAt).format("MMM D, LT")
                    }
                ]
            }}
            rowLinkTo={(row) => '/sales/recurringPayment/' + row.recurringPaymentId}
            rowLinkState={(row) => row}
        />
    );

}