import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {fetchMyProjects, setCurrentProjectId} from "../actions";
import {useNavigate, useSearchParams} from 'react-router-dom';
import StaticLayout from '../Layouts/StaticLayout';
import { sanitizeSeed } from '../utils/Utils';

export default function SelectProjectPage() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let auth = useSelector(state => state.auth);
  let myIds = useSelector(state => state.project.myIds);
  let byId = useSelector(state => state.project.byId);
  const [searchParams, updateSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(fetchMyProjects(auth.user.token));
    // Safe to add dispatch to the dependencies array
  }, [dispatch]);

  useEffect(() => {
    let projectId = searchParams.get("projectId");
    if (myIds?.list && myIds?.list.indexOf(projectId) > -1) {
      dispatch(setCurrentProjectId(projectId, null));
      navigate("/");
    }
  }, [myIds])

  let projects = myIds.list.map((projectId, i) =>
    <button key={i} data-project-id={projectId} onClick={(e) => {
      dispatch(setCurrentProjectId(e.currentTarget.dataset.projectId, null));
      navigate("/");
    }} className={"flex items-center mb-2 md:mb-6 rounded-2xl bg-white p-4 md:p-6 w-full hover:shadow-[0_0_0_2px] hover:shadow-white transition duration-300 ease-out"}>

      <img src={`https://api.dicebear.com/7.x/initials/svg?scale=70&seed=${sanitizeSeed(byId[projectId]?.name)}`} alt={"project avatar"} className="w-12 h-12 md:h-[4.5rem] md:w-[4.5rem] mr-6 md:mr-4 rounded-full"/>

      <div className={"text-left"}>
        <h4 className="text-base md:text-xl font-medium mb-2">{byId[projectId].name}</h4>
        <div className="inline-block text-sm md:text-base font-medium px-2 py-[3px] bg-light-blue rounded-full">{ byId[projectId].creatorUserId === auth.user.userId ? "Project owner" : "Team member" }</div>
      </div>

    </button>
  );

  return (
    <StaticLayout logoPosition={"left"} logoWhite logoMinMobile size={'medium'}>
      <div>
        {myIds.isFetching && (
          <div className="flex items-center justify-center py-1 mt-10 mb-10">
            <svg className="animate-rotation w-10 h-10" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              <path d="M12 2C11.4467 2 10.9039 2.04494 10.375 2.13136" stroke="white" strokeWidth="3" strokeLinecap="round"/>
              <path d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2" stroke="#8A9698" strokeWidth="3" strokeLinecap="round"/>
            </svg>
          </div>
        )}
        {(!myIds.isFetching && myIds.list.length > 0) && <h2 className="text-4xl md:text-5xl font-founders font-semibold mb-6 md:mb-8">Your projects</h2> }
        {!myIds.isFetching && projects}
      </div>
    </StaticLayout>
  );
}

